import styled from "styled-components";
import {useEffect, useState} from "react";

interface Props {
    total: number;
    current: number;
}

export const Scale = ({total, current}: Props) => {
    const scoreHeight = 125 / total; // Calculate height based on total
    const [currentScore, setCurrentScore] = useState(0);

    useEffect(() => {
        setCurrentScore(current)
    }, [current]);


    return (
        <Absolute>
            <Relative>
                <Circle>
                    <Text>{current}</Text>
                </Circle>
                <Container>
                    <Container2>
                        {[...Array(total)].map((_, index) => (
                            <Score
                                key={index}
                                isFirst={index === 0}
                                isActive={index < currentScore}
                                height={scoreHeight}
                            />
                        ))}
                    </Container2>
                </Container>
            </Relative>
        </Absolute>
    );
};

const Absolute = styled.div`
    position: absolute;
    top: 40%;
    left: 10px;
`;

const Relative = styled.div`
    position: relative;
`;

const Container = styled.div`
    border: 1px solid #FFA300;
    background: #000;
    width: 21.407px;
    height: 177px;
    border-radius: 50px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container2 = styled.div`
    border: 1px solid #e87d18;
    background: #000;
    width: 12px;
    top: 0;
    left: 0;
    height: 169px;
    border-radius: 50px;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
`;

const Score = styled.div<{ isFirst?: boolean; isActive?: boolean; height: number }>`
    border: ${props => (props.isActive ? "1px solid #000" : "0px")};
    border-bottom-width: ${props => (props.isFirst ? "1px" : "0px")};
    border-bottom-color: ${props => (props.isFirst ? "#000" : "#000")};
    width: 11px;
    height: ${props => props.height}px;
    flex-shrink: 0;
    background-color: ${props => (props.isActive ? "#FFA300" : "transparent")};
    will-change: background-color, border; /* Optimize for rendering performance */
    transition: none; /* Completely disable transitions for instant style application */
`;


const Circle = styled.div`
    width: 37.406px;
    height: 37.406px;
    border-radius: 50%;
    flex-shrink: 0;
    position: absolute;
    top: -5px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -6px;
    background-position: center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"><path d="M37.7135 19.131C37.7135 29.4515 29.3308 37.8342 19.0102 37.8342C8.68966 37.8342 0.307007 29.4515 0.307007 19.131C0.307007 8.81039 8.68966 0.427734 19.0102 0.427734C29.3308 0.427734 37.7135 8.81039 37.7135 19.131Z" fill="%23FFA300"/><path d="M36.5868 19.1311C36.5868 28.8207 28.7449 36.6625 19.0553 36.6625C9.36565 36.6625 1.5238 28.8207 1.5238 19.1311C1.5238 9.44143 9.36565 1.59961 19.0553 1.59961C28.7449 1.59961 36.5868 9.44143 36.5868 19.1311Z" fill="%23FFE600"/><path d="M19.0103 2.45605C9.81638 2.45605 2.33508 9.93735 2.33508 19.1312C2.33508 28.3251 9.81638 35.8064 19.0103 35.8064C28.2041 35.8064 35.7305 28.3251 35.7305 19.1312C35.7305 9.93735 28.2041 2.45605 19.0103 2.45605ZM19.0103 33.9586C10.8529 33.9586 4.18286 27.2885 4.18286 19.1312C4.18286 10.9739 10.8529 4.30386 19.0103 4.30386C27.1676 4.30386 33.8376 10.9739 33.8376 19.1312C33.8376 27.2885 27.1676 33.9586 19.0103 33.9586Z" fill="%231F140F"/><path d="M17.7483 1.64465C19.0553 1.46438 20.3622 1.55452 21.6692 1.73479C22.9762 1.91506 24.2381 2.27561 25.5 2.72629C26.7168 3.22204 27.9337 3.85297 28.9702 4.61913L29.3759 4.93461L29.5561 5.06981L29.7364 5.20503L30.5026 5.83597C30.7279 6.01624 30.9532 6.28665 31.2236 6.51199L31.8997 7.23309C30.9532 6.33173 29.9617 5.47544 28.8801 4.75435L28.4745 4.48393L28.2942 4.34873L28.0689 4.21353L27.2126 3.76285C26.8971 3.62765 26.6267 3.49243 26.3112 3.35723L25.8605 3.17697L25.4099 2.99669C25.0944 2.86149 24.824 2.77135 24.5085 2.63615L23.5621 2.32067L22.6157 2.09533C22.3002 2.00519 21.9847 1.96011 21.6692 1.91505C21.3537 1.82491 21.0383 1.82492 20.7228 1.77985C20.4073 1.73478 20.0918 1.68971 19.7313 1.68971C19.1003 1.59957 18.4243 1.55451 17.7483 1.64465Z" fill="white"/></svg>');
`;

const Text = styled.div`
    color: #fff;
    text-align: center;
     font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 4px;
`;
