import styled from "styled-components";


export interface ColorButtonProps {
      onClick: () => void,
    text: string;
    variant?: 'primary' | 'secondary';
}

export const ColorButton = ({onClick, text,variant}: ColorButtonProps) => {



    let color = variant === 'primary' ? '#5EAA31' : 'linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%)';
    let borderColor = variant === 'primary' ? '#FFA300' : '#9B0000';

    return (
        <Border
            color={color}
            borderColor={borderColor}>
            <Button borderColor={borderColor} color={color} onClick={onClick}>
                {text}
            </Button>
        </Border>
    )
}


const Button = styled.div<{ color: string; borderColor: string; }>`
    display: flex;
    width: 100%;
    height: 44px;
    min-width: 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 2px solid #000;
    background: ${props => props.color};
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
    padding-bottom: 5px;
    box-sizing: border-box;
`


const Border = styled.div<{ borderColor: string; color: string; }>`
    border-radius: 8px;
    border: 3px solid ${props => props.borderColor};
    box-sizing: border-box;
    width: 100%;
    background: ${props => props.color};
    padding: 2px;
    cursor: pointer;
`