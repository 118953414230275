import {IconProps} from "../../model";
import styled from "styled-components";

export const Field = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="340" height="357" viewBox="0 0 340 357" fill="none">
            <path d="M229.75 99.5417H151.551V148.155H229.75V99.5417Z" fill="#89CE1D"/>
            <path d="M229.008 211.929H150.809V260.542H229.008V211.929Z" fill="#89CE1D"/>
            <path
                d="M188.64 57.0054L180.67 74.3996L150.594 79.0555L128.489 66.3293L98.4132 70.9852L76.3083 58.259L46.2326 62.9149L24.1276 50.1886L32.0983 32.7945L62.1621 28.1386H62.174L70.1447 10.7444L100.208 6.0885L122.325 18.8147L152.389 14.1588L174.506 26.8851L166.535 44.2792H166.523L188.64 57.0054Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round"
                stroke-linejoin="round"/>

            <path
                d="M164.74 109.176L156.769 126.57L126.694 131.226L104.589 118.5L74.513 123.156L52.4081 110.429L22.3324 115.085L0.227417 102.359L8.19813 84.9651L38.2618 80.3091H38.2738L46.2445 62.915L76.3082 58.259L98.4252 70.9853L128.489 66.3293L150.606 79.0556L142.635 96.4497L142.623 96.4617L164.74 109.176Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M193.009 169.417L185.038 186.811L154.962 191.467L132.857 178.741L102.782 183.396L80.6766 170.67L50.6009 175.326L28.4959 162.6L36.4666 145.206L66.5303 140.55H66.5423L74.513 123.156L104.577 118.5L126.694 131.226L156.757 126.57L178.874 139.296L170.904 156.69L170.892 156.702L193.009 169.417Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M169.096 221.587L161.126 238.981L131.05 243.637L108.945 230.911L78.8694 235.567L56.7645 222.841L26.6888 227.497L4.5838 214.77L12.5545 197.376L42.6182 192.72H42.6302L50.6009 175.326L80.6646 170.67L102.782 183.396L132.845 178.74L154.962 191.467L146.991 208.861L146.98 208.873L169.096 221.587Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M197.365 281.828L189.394 299.222L159.319 303.878L137.214 291.152L107.138 295.808L85.0329 283.081L54.9573 287.737L32.8523 275.011L40.823 257.617L70.8867 252.961H70.8987L78.8694 235.567L108.933 230.911L131.05 243.637L161.114 238.981L183.231 251.707L175.26 269.102L175.248 269.114L197.365 281.828Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M173.453 333.998L165.482 351.392L135.406 356.048L113.301 343.322L83.2258 347.978L61.1208 335.252L31.0452 339.908L8.94019 327.182L16.9109 309.787L46.9746 305.131H46.9866L54.9573 287.737L85.021 283.081L107.138 295.808L137.202 291.152L159.319 303.878L151.348 321.272L151.336 321.284L173.453 333.998Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M331.048 51.096L323.077 68.4901L293.002 73.1461L270.897 60.4198L240.821 65.0758L218.716 52.3495L188.64 57.0055L166.535 44.2792L174.506 26.8851L204.57 22.2292H204.582L212.552 4.83502L242.616 0.179077L264.733 12.9053L294.797 8.24938L316.914 20.9756L308.943 38.3698H308.931L331.048 51.096Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M307.148 103.266L299.177 120.661L269.101 125.316L246.996 112.59L216.921 117.246L194.816 104.52L164.74 109.176L142.635 96.4496L150.606 79.0555L180.67 74.3996H180.682L188.652 57.0054L218.716 52.3495L240.833 65.0757L270.897 60.4198L293.014 73.146L285.043 90.5402H285.031L307.148 103.266Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M335.416 163.507L327.446 180.901L297.37 185.557L275.265 172.831L245.189 177.487L223.084 164.761L193.009 169.417L170.904 156.69L178.874 139.296L208.938 134.64H208.95L216.921 117.246L246.984 112.59L269.101 125.317L299.165 120.661L321.282 133.387L313.311 150.781H313.299L335.416 163.507Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M311.504 215.678L303.533 233.072L273.458 237.728L251.353 225.001L221.277 229.657L199.172 216.931L169.096 221.587L146.991 208.861L154.962 191.467L185.026 186.811H185.038L193.009 169.417L223.072 164.761L245.189 177.487L275.253 172.831L297.37 185.557L289.399 202.951H289.387L311.504 215.678Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M339.773 275.918L331.802 293.313L301.726 297.968L279.621 285.242L249.546 289.898L227.441 277.172L197.365 281.828L175.26 269.102L183.231 251.707L213.294 247.052H213.306L221.277 229.657L251.341 225.001L273.458 237.728L303.521 233.072L325.638 245.798L317.668 263.192H317.656L339.773 275.918Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M315.861 328.089L307.89 345.483L277.814 350.139L255.709 337.413L225.633 342.069L203.528 329.342L173.453 333.998L151.348 321.272L159.319 303.878L189.382 299.222H189.394L197.365 281.828L227.429 277.172L249.546 289.898L279.609 285.242L301.726 297.968L293.756 315.363H293.744L315.861 328.089Z"
                fill="#89CE1D" stroke="#26FF00" stroke-width="0.359041" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>)
}


const Relative = styled.div`
    background: #61dafb;
    width: 100px;
`