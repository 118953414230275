interface Props extends React.SVGProps<SVGSVGElement> {
    type: string
}

export const TreeIcon = (props: Props) => {
    if (props.type === '1') {
        return (
            <svg
                {...props}
                xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
                <path opacity="0.17"
                      d="M4.42702 25.9218C1.86156 24.4387 1.86156 22.0202 4.42702 20.537C6.99248 19.0539 11.1881 19.0539 13.7535 20.537C16.319 22.0202 16.319 24.4387 13.7535 25.9218C11.1881 27.405 6.99248 27.405 4.42702 25.9218Z"
                      fill="black"/>
                <path d="M0.25819 23.5836V23.7306C0.244829 23.6772 0.244829 23.6371 0.25819 23.5836Z" fill="#A6E036"/>
                <path
                    d="M7.94134 7.17535C7.94134 6.98828 7.82109 6.80122 7.58058 6.6676C7.09955 6.387 6.31121 6.387 5.81682 6.6676C5.57631 6.80122 5.45605 6.98828 5.45605 7.17535V23.9043C5.45605 24.0913 5.57631 24.2784 5.81682 24.412C6.29785 24.6926 7.08619 24.6926 7.58058 24.412C7.82109 24.2784 7.94134 24.0913 7.94134 23.9043V7.17535Z"
                    fill="#945603"/>
                <path
                    d="M6.712 0.374146C-0.503357 0.374146 0.498776 13.9898 0.498776 14.0165C0.498776 14.9385 1.10006 15.8604 2.31598 16.5552C4.73446 17.9582 8.68954 17.9582 11.1214 16.5552C12.3373 15.8604 12.9386 14.9385 12.9386 14.0165C12.9252 14.0165 13.9274 0.374146 6.712 0.374146Z"
                    fill="#87DB2B"/>
                <path
                    d="M9.62492 1.34961C14.9964 20.3634 0.485474 14.0166 0.485474 14.0166C0.485474 14.9385 1.08675 15.8605 2.30267 16.5553C4.72115 17.9583 8.67624 17.9583 11.1081 16.5553C12.324 15.8605 12.9253 14.9385 12.9253 14.0166C12.9253 14.0166 13.6201 4.4629 9.62492 1.34961Z"
                    fill="#7EC11E"/>
                <path opacity="0.2"
                      d="M8.40898 4.60989C8.40898 5.94606 7.32668 7.02837 5.9905 7.02837C4.65432 7.02837 3.57202 5.94606 3.57202 4.60989C3.57202 3.27371 4.65432 2.19141 5.9905 2.19141C7.32668 2.19141 8.40898 3.27371 8.40898 4.60989Z"
                      fill="white"/>
            </svg>
        )
    }
    if (props.type == '2') {
        return (
            <svg
                {...props}
                xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                <path opacity="0.17"
                      d="M5.41775 16.7481C1.71654 14.8641 1.71654 11.7909 5.41775 9.9069C9.11896 8.02289 15.1585 8.02289 18.8597 9.9069C22.5609 11.7909 22.5609 14.8641 18.8597 16.7481C15.1585 18.6188 9.11896 18.6188 5.41775 16.7481Z"
                      fill="black"/>
                <path d="M19.3808 13.8218V13.6348H19.3674C19.3674 13.7016 19.3808 13.755 19.3808 13.8218Z"
                      fill="#A6E036"/>
                <path d="M0.393734 13.6348L0.380371 13.8218C0.393733 13.755 0.393734 13.7016 0.393734 13.6348Z"
                      fill="#A6E036"/>
                <path
                    d="M17.8308 12.2052C17.8442 12.0984 17.8442 12.0048 17.8442 11.8979C17.8442 8.94498 14.3434 6.55322 10.0276 6.55322C5.71172 6.55322 2.21094 8.94498 2.21094 11.8979C2.21094 12.0048 2.21094 12.0984 2.2243 12.2052C2.10404 13.0604 2.8523 13.9289 4.4958 14.5703C7.54228 15.7728 12.5129 15.7728 15.5593 14.5703C17.2028 13.9156 17.9511 13.047 17.8308 12.2052Z"
                    fill="#7EC11E"/>
                <path
                    d="M13.221 7.00746C12.2456 6.7135 11.1633 6.53979 10.0276 6.53979C5.71174 6.53979 2.21095 8.93155 2.21095 11.8845C2.21095 11.9914 2.21095 12.0849 2.22431 12.1918C2.11742 12.8733 2.59844 13.5814 3.64066 14.1694C6.70051 15.1047 11.1633 15.0245 13.9693 13.9155C15.6128 13.2741 16.3611 12.3922 16.2408 11.5505C16.2542 11.4436 16.2542 11.35 16.2542 11.2431C16.2542 9.51947 15.065 7.99623 13.221 7.00746Z"
                    fill="#87DB2B"/>
                <path
                    d="M17.2429 9.57284C17.2563 9.47931 17.2563 9.38577 17.2563 9.29224C17.2563 6.56644 14.0227 4.34839 10.0275 4.34839C6.03238 4.34839 2.79883 6.56644 2.79883 9.29224C2.79883 9.38577 2.79883 9.47931 2.81219 9.57284C2.69193 10.3612 3.38675 11.1629 4.90999 11.7642C7.72932 12.8732 12.3258 12.8732 15.1451 11.7642C16.655 11.1629 17.3632 10.3612 17.2429 9.57284Z"
                    fill="#91C423"/>
                <path
                    d="M12.9805 4.77596C12.0719 4.49537 11.0831 4.34839 10.0275 4.34839C6.03238 4.34839 2.79883 6.56644 2.79883 9.29224C2.79883 9.38577 2.79883 9.47931 2.81219 9.57284C2.71866 10.2142 3.1596 10.8556 4.12164 11.39C6.95434 12.2586 11.0831 12.1784 13.6753 11.1495C15.1852 10.5482 15.8934 9.74654 15.7731 8.9582C15.7865 8.86467 15.7865 8.77113 15.7865 8.6776C15.7865 7.10091 14.6908 5.68457 12.9805 4.77596Z"
                    fill="#96CC2E"/>
                <path
                    d="M16.2811 6.61997C16.2944 6.5398 16.2944 6.45963 16.2944 6.37946C16.2944 4.01443 13.4885 2.09033 10.0278 2.09033C6.56708 2.09033 3.76111 4.01443 3.76111 6.37946C3.76111 6.45963 3.76111 6.5398 3.77447 6.61997C3.66758 7.30142 4.28222 7.99623 5.59167 8.51734C8.02351 9.47939 12.0187 9.47939 14.4639 8.51734C15.7733 7.99623 16.3746 7.30142 16.2811 6.61997Z"
                    fill="#90C628"/>
                <path
                    d="M12.5797 2.46446C11.7913 2.22395 10.9362 2.09033 10.0142 2.09033C6.55353 2.09033 3.74756 4.01443 3.74756 6.37946C3.74756 6.45963 3.74756 6.5398 3.76092 6.61997C3.68075 7.1678 4.05488 7.729 4.89667 8.19666C7.35523 8.94492 10.9228 8.87811 13.1676 7.99623C14.4771 7.47512 15.0917 6.78031 14.9848 6.09886C14.9982 6.01869 14.9982 5.93852 14.9982 5.85835C15.0115 4.48209 14.0628 3.25281 12.5797 2.46446Z"
                    fill="#99D335"/>
                <path
                    d="M15.2253 3.76057C15.2253 3.69376 15.2387 3.62695 15.2387 3.56015C15.2387 1.59597 12.9004 -0.00744629 10.0276 -0.00744629C7.15484 -0.00744629 4.81653 1.5826 4.81653 3.56015C4.81653 3.62695 4.81653 3.69376 4.82989 3.76057C4.74972 4.33513 5.2441 4.90968 6.33977 5.33726C8.37076 6.13897 11.6845 6.13897 13.7155 5.33726C14.8111 4.90968 15.3055 4.33513 15.2253 3.76057Z"
                    fill="#92CC2E"/>
                <path
                    d="M12.1521 0.299874C11.4974 0.0994472 10.7759 -0.00744629 10.0276 -0.00744629C7.15484 -0.00744629 4.81653 1.5826 4.81653 3.56015C4.81653 3.62695 4.81653 3.69376 4.82989 3.76057C4.76308 4.21487 5.0704 4.68253 5.77858 5.07002C7.82293 5.69803 10.7892 5.64458 12.6599 4.89632C13.7555 4.46875 14.2499 3.88083 14.1698 3.31963C14.1698 3.25282 14.1831 3.18602 14.1831 3.11921C14.1831 1.98346 13.3814 0.9546 12.1521 0.299874Z"
                    fill="#99DB3A"/>
            </svg>
        )
    }
    if (props.type === '3') {
        return (
            <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17"
                 fill="none">
                <path opacity="0.17"
                      d="M5.56619 14.7481C1.86498 12.8641 1.86498 9.79091 5.56619 7.9069C9.2674 6.02289 15.3069 6.02289 19.0081 7.9069C22.7093 9.79091 22.7093 12.8641 19.0081 14.7481C15.3203 16.6188 9.2674 16.6188 5.56619 14.7481Z"
                      fill="black"/>
                <path d="M19.5292 11.8218V11.6348H19.5159C19.5292 11.7016 19.5292 11.755 19.5292 11.8218Z"
                      fill="#A6E036"/>
                <path d="M0.555599 11.6348L0.542236 11.8218C0.542236 11.755 0.555599 11.7016 0.555599 11.6348Z"
                      fill="#A6E036"/>
                <path
                    d="M14.679 7.07853L14.6255 6.99836C14.5854 6.94491 14.5587 6.90483 14.5186 6.85138L10.0424 0.905396L5.55288 6.85138C5.51279 6.89147 5.48607 6.94491 5.44598 6.985L5.39253 7.06517C4.52402 8.36126 4.85806 9.93795 6.42139 10.9935C8.4123 12.3564 11.6726 12.3564 13.6635 10.9935C15.2134 9.95131 15.5475 8.37462 14.679 7.07853Z"
                    fill="#70AA15"/>
                <path
                    d="M13.3026 10.3655C14.6522 9.44351 15.0797 8.13406 14.5987 6.95822C14.572 6.91813 14.5453 6.89141 14.5185 6.86469L10.0424 0.918701L5.5528 6.86469C5.51272 6.90477 5.48599 6.95822 5.44591 6.99831L5.39246 7.07848C4.88471 7.8401 4.79118 8.69525 5.11186 9.4836C5.35237 9.80428 5.65969 10.0982 6.04719 10.3655C8.05145 11.7284 11.2984 11.7284 13.3026 10.3655Z"
                    fill="#7CBF1D"/>
                <path opacity="0.2"
                      d="M8.31875 8.48146C8.31875 9.18963 7.7442 9.76419 7.03603 9.76419C6.32785 9.76419 5.7533 9.18963 5.7533 8.48146C5.7533 7.77329 6.32785 7.19873 7.03603 7.19873C7.7442 7.21209 8.31875 7.78665 8.31875 8.48146Z"
                      fill="white"/>
                <path
                    d="M9.42777 9.48368L9.38769 9.41687C9.36096 9.37678 9.33424 9.3367 9.30752 9.30998L5.76665 4.62L2.22578 9.30998C2.19906 9.35006 2.17233 9.37678 2.14561 9.41687L2.10552 9.48368C1.42407 10.4992 1.69131 11.7418 2.90723 12.5836C4.48392 13.6526 7.04938 13.6526 8.61271 12.5836C9.84199 11.7552 10.1092 10.5125 9.42777 9.48368Z"
                    fill="#79BC19"/>
                <path
                    d="M8.33223 12.0759C9.38781 11.3543 9.73522 10.3121 9.36109 9.39015C9.34772 9.36342 9.32101 9.3367 9.30764 9.30998L5.76678 4.62L2.22591 9.30998C2.19918 9.35006 2.17246 9.37678 2.14574 9.41687L2.10565 9.48368C1.7048 10.085 1.63799 10.753 1.89186 11.381C2.07893 11.6349 2.31944 11.8621 2.62676 12.0759C4.19009 13.1448 6.75554 13.1448 8.33223 12.0759Z"
                    fill="#87DB2B"/>
                <path opacity="0.2"
                      d="M4.40368 10.5927C4.40368 11.1539 3.94938 11.5948 3.40155 11.5948C2.85371 11.5948 2.39941 11.1405 2.39941 10.5927C2.39941 10.0449 2.85371 9.59058 3.40155 9.59058C3.96274 9.59058 4.40368 10.0449 4.40368 10.5927Z"
                      fill="white"/>
                <path
                    d="M17.632 9.41683L17.5785 9.33666C17.5518 9.29658 17.5117 9.24313 17.4716 9.20304L13.1959 3.52429L8.92009 9.20304C8.88 9.24313 8.85328 9.28321 8.82655 9.33666L8.77311 9.41683C7.94468 10.6461 8.26536 12.156 9.74852 13.1715C11.6459 14.4676 14.7592 14.4676 16.6566 13.1715C18.1397 12.156 18.4604 10.6461 17.632 9.41683Z"
                    fill="#91CE25"/>
                <path
                    d="M16.3091 12.5435C17.5918 11.6616 18.006 10.419 17.5517 9.29658C17.525 9.26985 17.5116 9.22977 17.4849 9.20304L13.2091 3.52429L8.93336 9.20304C8.89328 9.24313 8.86655 9.28321 8.83983 9.33666L8.78638 9.41683C8.30536 10.1384 8.21182 10.9534 8.51914 11.7151C8.74629 12.0224 9.04025 12.303 9.41438 12.5568C11.2984 13.8396 14.3983 13.8396 16.3091 12.5435Z"
                    fill="#95DB31"/>
                <path opacity="0.2"
                      d="M11.5656 10.753C11.5656 11.4211 11.0178 11.969 10.3497 11.969C9.68162 11.969 9.13379 11.4211 9.13379 10.753C9.13379 10.0849 9.68162 9.53711 10.3497 9.53711C11.0178 9.53711 11.5656 10.0849 11.5656 10.753Z"
                      fill="white"/>
            </svg>
        )
    }


    if (props.type === '4') {
        return (
            <svg
                {...props}

                xmlns="http://www.w3.org/2000/svg" width="18" height="33" viewBox="0 0 18 33" fill="none">
                <path opacity="0.17"
                      d="M6.03469 31.0213C3.46923 29.5382 3.46923 27.1197 6.03469 25.6365C8.60015 24.1534 12.7957 24.1534 15.3612 25.6365C17.9267 27.1197 17.9267 29.5382 15.3612 31.0213C12.7824 32.4911 8.58679 32.4911 6.03469 31.0213Z"
                      fill="black"/>
                <path
                    d="M10.0565 12.9028C10.0565 12.7157 9.9362 12.5286 9.69569 12.395C9.21466 12.1144 8.42632 12.1144 7.93193 12.395C7.69142 12.5286 7.57117 12.7157 7.57117 12.9028V29.6317C7.57117 29.8188 7.69142 30.0058 7.93193 30.1395C8.41296 30.42 9.2013 30.42 9.69569 30.1395C9.9362 30.0058 10.0565 29.8188 10.0565 29.6317V12.9028Z"
                    fill="#945603"/>
                <path
                    d="M13.584 11.58C13.584 17.7798 11.4461 22.8038 8.81385 22.8038C6.18158 22.8038 4.0437 17.7798 4.0437 11.58C4.0437 5.3801 6.18158 0.356079 8.81385 0.356079C11.4461 0.356079 13.584 5.3801 13.584 11.58Z"
                    fill="#7EC11E"/>
                <path
                    d="M8.81385 0.356079C6.18158 0.356079 4.0437 5.3801 4.0437 11.58C4.0437 13.7847 4.31094 15.829 4.7786 17.566C5.63375 19.5035 6.79622 20.6927 8.07895 20.6927C10.7112 20.6927 12.8491 15.6687 12.8491 9.46881C12.8491 7.26411 12.5819 5.21977 12.1142 3.48274C11.2591 1.54528 10.0966 0.356079 8.81385 0.356079Z"
                    fill="#87DB2B"/>
                <path opacity="0.2"
                      d="M10.1902 4.53831C10.1902 5.56717 9.36173 6.3956 8.33287 6.3956C7.30401 6.3956 6.47559 5.56717 6.47559 4.53831C6.47559 3.50945 7.30401 2.68103 8.33287 2.68103C9.36173 2.68103 10.1902 3.52282 10.1902 4.53831Z"
                      fill="white"/>
                <path
                    d="M2.85449 20.3988C2.85449 23.3785 5.27297 25.797 8.25265 25.797V24.1935C6.15485 24.1935 4.4579 22.4966 4.4579 20.3988H2.85449Z"
                    fill="#945603"/>
                <path
                    d="M13.1697 20.7061C13.1697 22.8039 11.4728 24.5008 9.375 24.5008V26.1042C12.3547 26.1042 14.7732 23.6857 14.7732 20.7061H13.1697Z"
                    fill="#945603"/>
                <path
                    d="M17.3787 14.8268C17.3787 19.2095 15.8688 22.7771 13.9982 22.7771C12.1276 22.7771 10.6177 19.2229 10.6177 14.8268C10.6177 10.4308 12.1276 6.87659 13.9982 6.87659C15.8688 6.88995 17.3787 10.4442 17.3787 14.8268Z"
                    fill="#91CE30"/>
                <path
                    d="M14.0115 6.89001C12.1409 6.89001 10.631 10.4442 10.631 14.8403C10.631 16.4036 10.818 17.8467 11.1521 19.0759C11.7534 20.4522 12.5818 21.294 13.4904 21.294C15.361 21.294 16.8709 17.7398 16.8709 13.3437C16.8709 11.7804 16.6839 10.3373 16.3498 9.10806C15.7352 7.7318 14.9067 6.89001 14.0115 6.89001Z"
                    fill="#9CE03B"/>
                <path opacity="0.2"
                      d="M14.9869 9.85633C14.9869 10.5779 14.399 11.1658 13.6775 11.1658C12.956 11.1658 12.368 10.5779 12.368 9.85633C12.368 9.13479 12.956 8.54688 13.6775 8.54688C14.399 8.54688 14.9869 9.13479 14.9869 9.85633Z"
                      fill="white"/>
                <path
                    d="M7.62468 14.0787C7.62468 18.7286 6.02126 22.4966 4.04372 22.4966C2.06618 22.4966 0.462769 18.7286 0.462769 14.0787C0.462769 9.42879 2.06618 5.66077 4.04372 5.66077C6.02126 5.66077 7.62468 9.42879 7.62468 14.0787Z"
                    fill="#91CE30"/>
                <path
                    d="M4.04372 5.66077C2.06618 5.66077 0.462769 9.42879 0.462769 14.0787C0.462769 15.7355 0.663196 17.2721 1.0106 18.5682C1.65197 20.0247 2.52048 20.9199 3.48253 20.9199C5.46007 20.9199 7.06348 17.1519 7.06348 12.502C7.06348 10.8451 6.86305 9.30853 6.51565 8.01243C5.87428 6.556 5.00577 5.66077 4.04372 5.66077Z"
                    fill="#9CE03B"/>
                <path opacity="0.2"
                      d="M5.07258 8.80076C5.07258 9.57574 4.44458 10.1904 3.68296 10.1904C2.92134 10.1904 2.29333 9.56238 2.29333 8.80076C2.29333 8.03914 2.92134 7.41113 3.68296 7.41113C4.45794 7.41113 5.07258 8.03914 5.07258 8.80076Z"
                      fill="white"/>
            </svg>
        )
    }


    if (props.type === '5') {
        return (
            <svg
                {...props}

                xmlns="http://www.w3.org/2000/svg" width="18" height="33" viewBox="0 0 18 33" fill="none">
                <path opacity="0.17"
                      d="M6.03469 31.0213C3.46923 29.5382 3.46923 27.1197 6.03469 25.6365C8.60015 24.1534 12.7957 24.1534 15.3612 25.6365C17.9267 27.1197 17.9267 29.5382 15.3612 31.0213C12.7824 32.4911 8.58679 32.4911 6.03469 31.0213Z"
                      fill="black"/>
                <path
                    d="M10.0565 12.9028C10.0565 12.7157 9.9362 12.5286 9.69569 12.395C9.21466 12.1144 8.42632 12.1144 7.93193 12.395C7.69142 12.5286 7.57117 12.7157 7.57117 12.9028V29.6317C7.57117 29.8188 7.69142 30.0058 7.93193 30.1395C8.41296 30.42 9.2013 30.42 9.69569 30.1395C9.9362 30.0058 10.0565 29.8188 10.0565 29.6317V12.9028Z"
                    fill="#945603"/>
                <path
                    d="M13.584 11.58C13.584 17.7798 11.4461 22.8038 8.81385 22.8038C6.18158 22.8038 4.0437 17.7798 4.0437 11.58C4.0437 5.3801 6.18158 0.356079 8.81385 0.356079C11.4461 0.356079 13.584 5.3801 13.584 11.58Z"
                    fill="#7EC11E"/>
                <path
                    d="M8.81385 0.356079C6.18158 0.356079 4.0437 5.3801 4.0437 11.58C4.0437 13.7847 4.31094 15.829 4.7786 17.566C5.63375 19.5035 6.79622 20.6927 8.07895 20.6927C10.7112 20.6927 12.8491 15.6687 12.8491 9.46881C12.8491 7.26411 12.5819 5.21977 12.1142 3.48274C11.2591 1.54528 10.0966 0.356079 8.81385 0.356079Z"
                    fill="#87DB2B"/>
                <path opacity="0.2"
                      d="M10.1902 4.53831C10.1902 5.56717 9.36173 6.3956 8.33287 6.3956C7.30401 6.3956 6.47559 5.56717 6.47559 4.53831C6.47559 3.50945 7.30401 2.68103 8.33287 2.68103C9.36173 2.68103 10.1902 3.52282 10.1902 4.53831Z"
                      fill="white"/>
                <path
                    d="M2.85449 20.3988C2.85449 23.3785 5.27297 25.797 8.25265 25.797V24.1935C6.15485 24.1935 4.4579 22.4966 4.4579 20.3988H2.85449Z"
                    fill="#945603"/>
                <path
                    d="M13.1697 20.7061C13.1697 22.8039 11.4728 24.5008 9.375 24.5008V26.1042C12.3547 26.1042 14.7732 23.6857 14.7732 20.7061H13.1697Z"
                    fill="#945603"/>
                <path
                    d="M17.3787 14.8268C17.3787 19.2095 15.8688 22.7771 13.9982 22.7771C12.1276 22.7771 10.6177 19.2229 10.6177 14.8268C10.6177 10.4308 12.1276 6.87659 13.9982 6.87659C15.8688 6.88995 17.3787 10.4442 17.3787 14.8268Z"
                    fill="#91CE30"/>
                <path
                    d="M14.0115 6.89001C12.1409 6.89001 10.631 10.4442 10.631 14.8403C10.631 16.4036 10.818 17.8467 11.1521 19.0759C11.7534 20.4522 12.5818 21.294 13.4904 21.294C15.361 21.294 16.8709 17.7398 16.8709 13.3437C16.8709 11.7804 16.6839 10.3373 16.3498 9.10806C15.7352 7.7318 14.9067 6.89001 14.0115 6.89001Z"
                    fill="#9CE03B"/>
                <path opacity="0.2"
                      d="M14.9869 9.85633C14.9869 10.5779 14.399 11.1658 13.6775 11.1658C12.956 11.1658 12.368 10.5779 12.368 9.85633C12.368 9.13479 12.956 8.54688 13.6775 8.54688C14.399 8.54688 14.9869 9.13479 14.9869 9.85633Z"
                      fill="white"/>
                <path
                    d="M7.62468 14.0787C7.62468 18.7286 6.02126 22.4966 4.04372 22.4966C2.06618 22.4966 0.462769 18.7286 0.462769 14.0787C0.462769 9.42879 2.06618 5.66077 4.04372 5.66077C6.02126 5.66077 7.62468 9.42879 7.62468 14.0787Z"
                    fill="#91CE30"/>
                <path
                    d="M4.04372 5.66077C2.06618 5.66077 0.462769 9.42879 0.462769 14.0787C0.462769 15.7355 0.663196 17.2721 1.0106 18.5682C1.65197 20.0247 2.52048 20.9199 3.48253 20.9199C5.46007 20.9199 7.06348 17.1519 7.06348 12.502C7.06348 10.8451 6.86305 9.30853 6.51565 8.01243C5.87428 6.556 5.00577 5.66077 4.04372 5.66077Z"
                    fill="#9CE03B"/>
                <path opacity="0.2"
                      d="M5.07258 8.80076C5.07258 9.57574 4.44458 10.1904 3.68296 10.1904C2.92134 10.1904 2.29333 9.56238 2.29333 8.80076C2.29333 8.03914 2.92134 7.41113 3.68296 7.41113C4.45794 7.41113 5.07258 8.03914 5.07258 8.80076Z"
                      fill="white"/>
            </svg>
        )
    }

    return <></>
}

