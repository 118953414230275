import React from "react";

export const CoinIcon = (props: React.SVGProps<SVGSVGElement>) =>
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
                                   fill="none">
    <g clip-path="url(#clip0_4086_53180)">
        <path opacity="0.08"
              d="M15.7387 8.68494C15.7387 12.9225 12.3212 16.35 8.09597 16.35C3.87072 16.35 0.453247 12.9225 0.453247 8.68494C0.453247 4.44736 3.87072 1.0199 8.09597 1.0199C12.3057 1.0199 15.7387 4.44736 15.7387 8.68494Z"
              fill="black"/>
        <path
            d="M16.4533 8.01502C16.4533 12.2526 13.0358 15.6801 8.81057 15.6801C4.58532 15.6801 1.16785 12.2526 1.16785 8.01502C1.16785 3.77743 4.58532 0.349976 8.81057 0.349976C13.0203 0.349976 16.4533 3.77743 16.4533 8.01502Z"
            fill="#FFDC0A"/>
        <path
            d="M8.79499 14.4804C12.3554 14.4804 15.2416 11.5858 15.2416 8.01499C15.2416 4.44423 12.3554 1.54956 8.79499 1.54956C5.23463 1.54956 2.34839 4.44423 2.34839 8.01499C2.34839 11.5858 5.23463 14.4804 8.79499 14.4804Z"
            fill="url(#paint0_linear_4086_53180)"/>
        <path
            d="M10.3174 7.59443C10.364 7.59443 10.7523 7.59443 11.2028 7.45421C11.529 7.34516 11.8863 7.17378 12.1504 6.8622C12.3834 6.58177 12.5543 6.20786 12.5543 5.67817C12.5543 4.40066 11.6999 3.79306 10.2708 3.69959V3.45032H9.27659V3.68401H7.94067V3.45032H6.94649V3.68401H4.64746V4.30718H5.16008C5.50183 4.30718 5.79698 4.58761 5.79698 4.94594V11.0375C5.79698 11.1621 5.76591 11.2712 5.70377 11.3646C5.59503 11.5516 5.39309 11.6762 5.16008 11.6762H4.64746V12.2994H10.5193C11.0164 12.2994 11.9795 12.2215 12.5698 11.536C12.8649 11.1933 13.0513 10.7103 13.0513 10.0092C13.0513 7.6879 10.4572 7.59443 10.3174 7.59443ZM7.94067 4.32276H9.19892C9.72707 4.32276 10.2086 5.03941 10.2086 5.84954C10.2086 6.31692 10.0688 6.76872 9.85135 7.04915C9.69601 7.25168 9.5096 7.37632 9.32319 7.37632H7.9562V4.32276H7.94067ZM10.1465 11.2556C9.96008 11.4893 9.72707 11.6295 9.47853 11.6295H7.94067V8.07739H9.41639C10.0844 8.07739 10.6125 9.04331 10.6125 9.85343C10.6125 10.352 10.4261 10.8973 10.1465 11.2556Z"
            fill="#F09C00"/>
        <path
            d="M11.2028 7.43859C10.861 7.36069 10.597 7.36069 10.5504 7.36069C10.628 7.36069 11.5135 7.34511 12.1504 6.84657C12.5076 6.56614 12.7873 6.12992 12.7873 5.42885C12.7873 4.15134 11.9329 3.54375 10.5038 3.45027V3.43469H4.896V4.05787H5.40862C5.75037 4.05787 6.04551 4.3383 6.04551 4.69662V10.7881C6.04551 11.0218 5.90571 11.24 5.7193 11.349C5.62609 11.3957 5.53289 11.4269 5.42415 11.4269H4.91153V12.0501H10.6591C11.063 12.0501 11.9329 12.0189 12.5698 11.5204C12.9892 11.1776 13.2999 10.6324 13.2999 9.75991C13.2999 8.13966 12.0106 7.60996 11.2028 7.43859ZM8.17367 4.08903H9.43192C9.96007 4.08903 10.4416 4.80568 10.4416 5.6158C10.4416 6.25456 10.1776 6.83099 9.8358 7.03352C9.7426 7.09584 9.64939 7.127 9.54066 7.127H8.17367V4.08903ZM10.1465 11.2555C10.0067 11.349 9.86687 11.4113 9.71153 11.4113H8.17367V7.85923H9.66493C10.3329 7.85923 10.861 8.82515 10.861 9.63528C10.861 10.2429 10.5659 10.9439 10.1465 11.2555Z"
            fill="#FFDB00"/>
        <path
            d="M8.79499 14.4804C12.3554 14.4804 15.2416 11.5858 15.2416 8.01499C15.2416 4.44423 12.3554 1.54956 8.79499 1.54956C5.23463 1.54956 2.34839 4.44423 2.34839 8.01499C2.34839 11.5858 5.23463 14.4804 8.79499 14.4804Z"
            stroke="#E89900" stroke-width="0.688608" stroke-miterlimit="10"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_4086_53180" x1="2.34839" y1="8.01499" x2="15.2416" y2="8.01499"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFDC0A"/>
            <stop offset="0.65" stop-color="#FFB503"/>
            <stop offset="1" stop-color="#FFA500"/>
        </linearGradient>
        <clipPath id="clip0_4086_53180">
            <rect width="16" height="16" fill="white" transform="translate(0.453247 0.349976)"/>
        </clipPath>
    </defs>
</svg>