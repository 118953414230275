import {IconProps} from "../../model";

export const Head = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
            <path
                d="M0.540336 13.0543C0.350005 13.3208 0.197731 13.5872 0.197731 13.8918C0.159665 14.5389 0.882942 14.9957 1.45394 15.2241C4.1947 16.328 7.31611 16.6706 10.0949 15.6809C11.3892 15.2622 12.5693 14.5389 13.7112 13.8156C15.272 12.864 16.7566 11.7981 18.2031 10.6942C18.6599 10.3516 19.1547 9.93288 19.4592 9.43802C19.7638 8.90509 19.7638 8.18184 19.3831 7.76311C19.0405 7.38245 18.4315 7.30632 17.8985 7.23018C15.3862 7.03985 12.8357 7.19211 10.3995 7.68697C7.92519 8.18183 5.48894 9.01929 3.24303 10.1613C2.63397 10.4658 2.29138 10.6942 1.87265 11.2271C1.37779 11.8362 0.959065 12.4453 0.540336 13.0543Z"
                fill="#B29518"/>
            <path
                d="M0.578463 13.2061C0.464264 13.4726 0.350084 13.8533 0.502349 14.0817C0.578481 14.2339 0.730746 14.3481 0.883011 14.4242C2.97665 15.7566 5.56514 16.2514 8.00138 15.8708C11.3131 15.3378 14.13 13.2442 16.7566 11.1505C17.2895 10.7318 17.8225 10.3131 18.2793 9.81823C18.5457 9.5137 18.8503 9.1711 18.9645 8.79044C19.0787 8.40977 19.0406 7.91491 18.7361 7.64845C18.4696 7.38199 18.0889 7.30585 17.7463 7.26779C17.2134 7.19166 16.6805 7.15359 16.1476 7.11553C14.7391 7.07746 13.2926 7.30586 11.8841 7.49619C8.9911 7.91492 6.06 8.44785 3.50957 9.8563C2.13918 10.6176 1.18752 11.7977 0.578463 13.2061Z"
                fill="#FFE600"/>
            <path
                d="M18.4314 7.61027C18.3934 8.06706 18.1269 8.48579 17.8224 8.82838C17.0611 9.66584 16.0333 10.1607 14.9674 10.6175C13.1402 11.3788 11.275 12.0259 9.33359 12.5208C8.6484 12.7111 7.92515 12.8634 7.20189 12.8634C6.44057 12.8634 5.71731 12.635 4.99406 12.4447C4.46113 12.2924 3.96625 12.1782 3.43333 11.9879C3.20493 11.9118 3.01461 11.7975 2.82428 11.6833C2.48168 11.3788 2.36748 10.884 2.25328 10.4272C2.06295 9.05678 2.17714 7.61027 2.672 6.27795C2.7862 5.93536 2.93848 5.63083 3.09074 5.3263C3.73787 4.03204 4.72758 2.92811 5.90763 2.12872C6.47862 1.74806 7.04962 1.51966 7.69675 1.3674C8.57227 1.17707 9.4478 0.986737 10.3233 0.796405C10.704 0.720273 11.0846 0.644141 11.5034 0.606074C13.1402 0.491876 14.6629 1.55773 15.8048 2.69972C16.0332 2.92811 16.2617 3.15652 16.452 3.42298C17.2514 4.41271 17.8604 5.59276 18.203 6.81088C18.203 6.83625 18.2157 6.84894 18.2411 6.84894C18.3934 7.03928 18.4314 7.34381 18.4314 7.61027Z"
                fill="#BC9B0F"/>
            <path
                d="M16.5661 3.38492C16.6422 3.80364 16.7183 4.26044 16.7183 4.71723C16.7564 6.35408 16.0712 7.9148 14.9673 9.09485C13.8634 10.2749 12.3788 11.0743 10.8181 11.5311C9.6761 11.8737 8.45797 12.0259 7.23985 11.8356C6.0598 11.6453 4.91781 11.1124 4.08036 10.2368C3.66163 9.78004 3.31903 9.17097 3.1287 8.59998C2.9003 7.95286 2.70997 7.03928 2.74804 6.27795C2.86224 5.93536 3.01452 5.63083 3.16678 5.3263C3.81391 4.03204 4.80361 2.92811 5.98367 2.12872C6.55466 1.74806 7.12566 1.51966 7.77278 1.3674C8.64831 1.17707 9.52384 0.986737 10.3994 0.796405C10.78 0.720273 11.1607 0.644141 11.5794 0.606074C13.2163 0.491876 14.7389 1.55773 15.8809 2.69972C16.1474 2.89005 16.3757 3.11845 16.5661 3.38492Z"
                fill="#FFE600"/>
            <path
                d="M13.064 0.758673C11.9982 0.682541 10.9704 0.98707 10.0568 1.52C9.14321 2.05292 8.38188 2.81424 7.77282 3.6517C7.31603 4.29882 6.89729 5.02208 6.66889 5.78341C6.36436 6.84926 6.4405 7.99125 6.66889 9.05711C6.89729 10.161 7.2399 11.1888 7.4683 12.2927C7.54443 12.5211 7.58249 12.7495 7.50635 12.9779C7.39216 13.3205 7.08763 13.5109 6.74503 13.587C6.51663 13.6251 6.28823 13.625 6.0979 13.5489C5.52691 13.3586 4.95593 13.2444 4.423 13.016C3.9662 12.8257 3.5094 12.7876 3.0526 12.6353C2.59581 12.4831 2.59582 11.9501 2.51969 11.4933C2.29129 8.14351 3.89008 4.83176 6.40245 2.62392C6.85924 2.20519 7.3541 1.82453 7.9251 1.48193C9.10515 0.758671 10.5136 0.530273 11.884 0.530273H12.1124C12.4169 0.56834 12.7214 0.64447 12.9879 0.682536C12.9879 0.720602 13.026 0.720607 13.064 0.758673Z"
                fill="#B29518"/>
            <path
                d="M16.2618 2.77576C16.2237 3.11836 15.7288 2.88996 15.5004 2.92802C15.1578 2.96609 14.7772 3.04222 14.4346 3.15642C13.7874 3.42289 13.1784 3.84161 12.6835 4.29841C11.808 5.0978 10.9705 6.04945 10.5137 7.15338C10.3234 7.5721 10.2092 8.0289 9.94276 8.44763C9.90469 8.52376 9.82856 8.59989 9.75243 8.63796C9.67629 8.67602 9.56209 8.67602 9.48596 8.67602C9.25756 8.63796 9.02915 8.5999 8.76269 8.56183C8.61042 8.52376 8.3059 8.56182 8.15364 8.44763C8.00137 8.33343 8.03944 7.99083 8.0775 7.76244C8.0775 7.72437 8.07752 7.6863 8.11558 7.64823C8.22978 6.31591 8.8769 5.28813 9.75243 4.29841C10.7421 3.19449 11.9983 2.35703 13.4449 1.9383C13.8636 1.8241 14.2823 1.74797 14.7391 1.8241C15.0436 1.86217 15.3862 1.90024 15.6527 2.0525C15.8049 2.12864 16.0714 2.35703 16.2237 2.58543C16.2617 2.62349 16.2998 2.69963 16.2618 2.77576Z"
                fill="#B29518"/>
            <path
                d="M16.1855 2.50974L16.1094 2.58588C16.1094 2.58588 15.5764 2.47169 15.5003 2.47169C15.31 2.50975 15.1577 2.50974 14.9674 2.54781C13.5208 2.81427 12.2266 3.804 11.2749 4.90792C11.0846 5.13632 10.8943 5.36471 10.7039 5.63118C10.2852 6.2783 9.82841 7.00156 9.67615 7.76289C9.63808 7.87709 9.63809 7.95321 9.56196 8.02935C9.25743 8.33388 8.41996 8.10548 8.1535 7.83901C8.11543 7.80095 8.07737 7.72482 8.03931 7.68675C8.03931 7.64868 8.0393 7.61062 8.07736 7.57256C8.19156 6.24024 8.83868 5.21244 9.71421 4.22272C10.7039 3.1188 11.9601 2.28135 13.4067 1.86262C13.8254 1.74842 14.2441 1.67229 14.7009 1.74842C15.0054 1.78648 15.348 1.82455 15.6145 1.97682C15.8048 2.09102 16.0332 2.31941 16.1855 2.50974Z"
                fill="#FBDC48"/>
            <path
                d="M11.8459 0.530516C11.7698 0.568582 11.7317 0.568575 11.6936 0.606641C9.90453 1.13957 8.57222 2.09123 7.4683 3.57581C6.70697 4.67973 6.13597 5.89786 5.98371 7.23017C5.90757 7.9915 5.94565 8.75282 6.02179 9.51415C6.09792 10.2755 6.17404 11.0368 6.36437 11.722C6.51663 12.293 6.70696 12.8259 6.74503 13.3588V13.5492C6.51663 13.5872 6.28823 13.5872 6.0979 13.5111C5.52691 13.3208 4.95593 13.2066 4.423 12.9782C3.9662 12.7878 3.5094 12.7498 3.0526 12.5975C2.59581 12.4452 2.59582 11.9123 2.51969 11.4555C2.29129 8.10569 3.89008 4.79393 6.40245 2.58609C6.85924 2.16736 7.3541 1.7867 7.9251 1.4441C9.06708 0.796977 10.5136 0.49245 11.8459 0.530516Z"
                fill="#FBDC48"/>
            <path
                d="M6.78313 0.225586C6.78313 0.225586 6.66892 1.7863 5.6792 1.7863C5.71727 1.82437 6.66892 1.86244 6.74505 3.15669C6.74505 3.15669 6.59279 1.82437 7.46832 1.82437C7.46832 1.9005 6.63086 1.63404 6.78313 0.225586Z"
                fill="white"/>
        </svg>
    )
}