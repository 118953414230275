import {IconProps} from "../../../model";

export const InviteIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21" fill="none">
            <path
                d="M14.9999 0C17.5999 0 19.6799 2 19.8399 4.6V6.68C19.6799 9.24 17.5999 11.28 14.9999 11.28C12.3999 11.28 10.3199 9.28 10.1599 6.68V4.6C10.3199 2.04 12.3599 0 14.9999 0Z"
                fill="black"/>
            <path
                d="M4.67999 0.399902C5.43999 0.399902 6.12 0.599902 6.72 0.999902C7.72 1.6799 8.28 2.59989 8.36 3.83989V5.5999C8.24 6.7999 7.72 7.75989 6.72 8.43989C6.12 8.83989 5.43999 9.03989 4.67999 9.03989C3.91999 9.03989 3.23999 8.83989 2.63999 8.43989C1.63999 7.75989 1.08 6.8399 1 5.5999V3.83989C1.12 2.63989 1.63999 1.6799 2.63999 0.999902C3.23999 0.599902 3.91999 0.399902 4.67999 0.399902Z"
                fill="black"/>
            <path
                d="M21.6 4.7199V3.83989C21.72 2.63989 22.24 1.6799 23.24 0.999902C23.84 0.599902 24.52 0.399902 25.28 0.399902C26.04 0.399902 26.72 0.599902 27.32 0.999902C28.32 1.6799 28.88 2.59989 28.96 3.83989V5.5999C28.84 6.7999 28.32 7.75989 27.32 8.43989C26.72 8.83989 26.04 9.03989 25.28 9.03989C24.52 9.03989 23.84 8.83989 23.24 8.43989C22.24 7.75989 21.68 6.8399 21.6 5.5999V4.7199Z"
                fill="black"/>
            <path
                d="M9.79998 10.2001C7.47998 11.5201 5.79998 13.7601 4.99998 16.2801L4.95997 16.3201C2.83997 14.4001 1.39998 12.0801 0.599976 9.32012C0.599976 9.28012 0.599966 9.24013 0.639966 9.20013L1.59998 8.64012H1.67997C3.39997 10.2401 5.99997 10.2401 7.67997 8.64012C7.70663 8.61345 7.73331 8.61345 7.75997 8.64012C8.51997 9.00012 9.19997 9.52013 9.75997 10.0801C9.83997 10.1601 9.83998 10.2001 9.79998 10.2001Z"
                fill="black"/>
            <path
                d="M24.96 16.2799C24.16 13.7199 22.48 11.5199 20.16 10.1999C20.12 10.1999 20.12 10.1599 20.16 10.1199C20.76 9.51989 21.44 9.0399 22.16 8.6799H22.24C23.96 10.2799 26.56 10.2799 28.24 8.6799C28.28 8.6399 28.32 8.6399 28.32 8.6799L29.28 9.23989C29.32 9.27989 29.32 9.31988 29.32 9.35988C28.52 12.0799 27.04 14.4399 24.96 16.3599C24.9867 16.3332 24.9867 16.3066 24.96 16.2799C24.96 16.3066 24.96 16.3066 24.96 16.2799Z"
                fill="black"/>
            <path
                d="M15 12.1999C16.52 12.1999 17.88 11.6399 18.96 10.6399C19 10.5999 19.04 10.5999 19.08 10.6399C21.72 11.9999 23.44 14.1199 24.24 16.9599C24.24 16.9865 24.2267 17.0132 24.2 17.0399C21.56 19.0799 18.36 20.1999 15 20.1999C11.64 20.1999 8.44001 19.0799 5.80001 17.0399C5.76001 17.0399 5.76001 16.9999 5.76001 16.9599C6.56001 14.1199 8.32001 11.9999 10.92 10.6399H11.04C12.12 11.6399 13.48 12.1999 15 12.1999Z"
                fill="black"/>
        </svg>
    )
}