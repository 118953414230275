import styled from "styled-components";
import {WBloxIcon} from "./icons/wBlox";

interface Props {
    value: number | string | undefined
}

export const WBloxItem = ({value}: Props) => {
    return (
        <Container>
            <WBloxIcon
                style={{
                    position: 'absolute',
                    zIndex: 15,
                    left: -43,
                    top: -14,
                }}
            />
            <IContainer>
                <Inner>
                    <Label>
                        wBloxx
                    </Label>
                    <Value>
                        {value}
                    </Value>
                </Inner>
                <Bg/>
            </IContainer>
        </Container>
    )

}


const Container = styled.div`
    position: relative;
    left: 25px;
`


const Inner = styled.div`
    display: flex;
    padding: 6px 8.5px 6px 36px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #090909;
    flex-direction: column;
    gap: 4px;
    z-index: 10;
    position: relative; /* Ensures proper stacking within the container */

`

const IContainer = styled.div`
    width: auto;
    height: 48px;
    position: relative;
`

const Bg = styled.div`
    width: calc(100% + 8px);
    height: 38px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFA300;
    position: absolute;
    z-index: 0;
    top: 10px;
    left: -4px;
`


const Label = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`
const Value = styled.div`
    color: #00C3FF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    text-transform: uppercase;

`
