import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {Info} from "../info";
import {useNavigate} from "react-router-dom";
import {Area} from "../../api/requests/area";
import {useAreasStore} from "../../store/areas-store";
import {useUserStore} from "../../store/user-store";
import React, { useMemo} from "react";
import {ActionModal} from "../action-modal";
import {usePositionStore} from "../../store/position-store";
import {OnboardingModal} from "./onboarding";
import {Field} from "../icons/field";
import {AreaField} from "../area";
import {TreeIcon} from "../icons/tree";

export const MainView = () => {

    const {areas} = useAreasStore();
    const {user} = useUserStore()
    const nav = useNavigate()
    const [isBuildModalOpen, setIsBuildModalOpen] = React.useState(false)
    const {setPosition} = usePositionStore()

    const rentPerHourOffAllAreas = useMemo(() => {
        return areas?.reduce((acc, area) => {
            return acc + area.rent_per_hour_amount
        }, 0) || 0
    }, [areas])


    const onAreaClick = async (area: Area) => {
        setPosition({pos_x: area.pos_x, pos_y: area.pos_y})
        if (area.building_level > 0) {
            nav(`/tower-info`)
        } else {
            setIsBuildModalOpen(true)
        }
    }

    return (
        <Container>
            <Col>
                <BalanceContainer>
                    <Inner>
                        <Label>
                            Town balance
                        </Label>
                        <Value>
                            {user?.second_balance?.toString() || '0'}
                        </Value>
                    </Inner>
                    <Bg/>
                </BalanceContainer>
                <Row>
                    <Info title={'Blox level'} value={user?.block_level || 0}/>
                    <Info title={'Rent per hour'} value={rentPerHourOffAllAreas}/>
                </Row>
                <Relative>
                    <Field/>
                    <LeftArea
                        id={'1'}
                        bordered
                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 0 && area.pos_y === 0)}
                        top={13} left={22}/>
                    <RightArea
                        id={'2'}
                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 1 && area.pos_y === 0)}
                        top={8} right={16}/>
                    <LeftArea
                        id={'3'}
                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 2 && area.pos_y === 0)}
                        top={68} left={3}/>
                    <RightArea
                        id={'4'}
                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 3 && area.pos_y === 0)}
                        top={60} right={42}/>
                    <LeftArea
                        id={'5'}

                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 0 && area.pos_y === 1)}
                        top={128} left={22}/>
                    <RightArea
                        id={'1'}


                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 1 && area.pos_y === 1)}
                        top={121} right={16}/>
                    <LeftArea
                        id={'2'}

                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 2 && area.pos_y === 1)}
                        top={182} left={3}/>
                    <RightArea
                        id={'3'}
                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 3 && area.pos_y === 1)}
                        top={172} right={42}/>
                    <LeftArea
                        id={'4'}
                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 0 && area.pos_y === 2)}
                        top={235} left={30}/>
                    <RightArea
                        id={'5'}

                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 1 && area.pos_y === 2)}
                        top={235} right={11}/>
                    <LeftArea
                        id={'1'}

                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 2 && area.pos_y === 2)}
                        top={294} left={3}/>
                    <RightArea
                        id={'2'}

                        onClick={onAreaClick}
                        area={areas?.find((area) => area.pos_x === 3 && area.pos_y === 2)}
                        top={288} right={34}/>
                    <STreeIcon
                        type={'5'}/>
                </Relative>
                <ButtonRow>
                </ButtonRow>
                <BottomNav/>
            </Col>
            <ActionModal
                onClick={() => {
                    nav(`/game`)
                }}
                variant={'primary'}
                text={'Build'}
                title={'Build new tower'}
                open={isBuildModalOpen}
                onClose={() => setIsBuildModalOpen(false)}
            />

            <OnboardingModal
                hamster={2}
                text={'This is where we will build our towers. Choose any plot to start. Dont be shy, all plots are free for now!'} next={'/invite'}
            />

        </Container>
    )
}

const STreeIcon = styled(TreeIcon)`
    position: absolute;
    left: 177px;
    top: 92px;
`

const LeftArea = styled(AreaField)<{ top: number, left: number, bordered?: boolean }>`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
`

const RightArea = styled(AreaField)<{ top: number, right: number }>`
    position: absolute;
    top: ${props => props.top}px;
    right: ${props => props.right}px;
`


const Relative = styled.div`
    position: relative;
    margin-top: 46px;
`


const Inner = styled.div`
    display: flex;
    padding: 6px 50px 9px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #090909;
    flex-direction: column;
    gap: 4px;
    z-index: 10;
    position: relative;
    box-sizing: border-box;

`

const BalanceContainer = styled.div`
    width: 230px;
    height: 63px;
    position: relative;
`

const Bg = styled.div`
    width: 238px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFA300;
    position: absolute;
    z-index: 0;
    top: 10px;
    left: -4px;
`


const Label = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`
const Value = styled.div`
    color: #FFE600;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    text-transform: uppercase;

`


const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-top: calc(var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    padding: 16px 0 0 0;
    gap: 15px;
    box-sizing: border-box;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
`


const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
`

