import styled from "styled-components";
import {ColorButton} from "./color-button";
import React from "react";
import {ModalWrapper} from "./modal-wrapper";


interface ActionModalProps {
    open: boolean;
    onPause: () => void;
    onLeave: () => void;
}

export const PauseModal = ({open, onPause, onLeave}: ActionModalProps) => {

    if (!open) return null;

    return (
        <ModalWrapper>
            <InnerContainer>
                <Border>
                    <Pause>
                        <PauseText>
                            Pause
                        </PauseText>
                    </Pause>
                </Border>
                <ColorButton
                    onClick={onPause}
                    variant={'primary'}
                    text={'Continue'}
                />
                <ColorButton
                    variant={'secondary'}
                    onClick={onLeave}
                    text={'main menu'}
                />
            </InnerContainer>
        </ModalWrapper>
    )
}


const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    padding: 24px 16px 48px 16px;
    box-sizing: border-box;
`


const Pause = styled.div`
    display: flex;
    width: 100%;
    height: 44px;
    min-width: 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #000;
    background: #FFE600;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
    padding-bottom: 11px;
    box-sizing: border-box;

`


const Border = styled.div`
    border-radius: 8px;
    border: 2px solid #FFA300;
    box-sizing: border-box;
    width: 100%;
    background: #20150E;
    padding: 2px;
    cursor: pointer;
    margin-bottom: 20px;
`

const PauseText = styled.div`
    color: #0F0F0F;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 40px */
    text-transform: uppercase;
`
