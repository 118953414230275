import React from "react";

export const GarageIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="43" height="33" viewBox="0 0 43 33" fill="none">
            <g opacity="0.17">
                <path
                    d="M15.8054 20.0038C14.9605 19.9443 14.1156 20.1109 13.4849 20.4917L2.81066 27.2152C1.69207 27.9054 1.76347 29.0716 2.94156 29.8094L5.88084 31.642C6.39254 31.9633 7.04703 32.1418 7.70153 32.1894C8.54642 32.2489 9.39132 32.0942 10.022 31.7015L20.6962 24.978C21.8148 24.2878 21.7434 23.1097 20.5772 22.3838L17.638 20.5512C17.1144 20.2418 16.4718 20.0514 15.8054 20.0038Z"
                    fill="black"/>
            </g>
            <path
                d="M9.53418 18.8971L17.2572 22.836C17.757 23.0859 18.0188 23.7285 17.9474 24.5496C17.9355 24.6567 17.9236 24.7638 17.8998 24.8709L10.1768 20.9201C10.2006 20.813 10.2125 20.7059 10.2244 20.5988C10.3077 19.7777 10.034 19.147 9.53418 18.8971Z"
                fill="black"/>
            <path
                d="M2.6202 24.0378L9.14137 27.6554C9.64116 27.9053 9.92676 28.536 9.84346 29.3571C9.83156 29.4642 9.79586 29.5713 9.78396 29.6903L2.06091 25.7514C2.08471 25.6443 2.09661 25.5372 2.10851 25.4301C2.17991 24.5971 3.12 24.2996 2.6202 24.0378Z"
                fill="black"/>
            <path
                d="M9.33183 30.7494C8.77253 31.7847 7.58254 32.4392 6.66624 32.2012C5.74995 31.9632 5.46436 30.9279 6.02365 29.8807C6.58295 28.8335 7.77294 28.1909 8.68923 28.417C9.59363 28.6669 9.89113 29.7022 9.33183 30.7494Z"
                fill="#131113"/>
            <path
                d="M8.7844 29.1906C8.2846 29.0597 7.5587 29.5357 7.16601 30.2616C6.78521 30.9875 6.88041 31.6777 7.3802 31.8086C7.8919 31.9395 8.6059 31.4635 8.9986 30.7376C9.3913 30.0236 9.2961 29.3334 8.7844 29.1906ZM8.7368 30.2021V30.1783L8.356 30.1426L8.5821 29.6309C8.7249 29.738 8.7844 29.9403 8.7368 30.2021ZM8.4036 29.5595L7.9752 30.0355L7.66581 29.9046C7.9038 29.6666 8.1775 29.5357 8.4036 29.5595ZM7.40401 30.214L7.48731 30.6305L7.15411 30.9399C7.13031 30.7376 7.18981 30.6186 7.3207 30.3568C7.3445 30.3092 7.36831 30.2616 7.40401 30.214ZM7.4397 31.4635C7.3683 31.4397 7.26121 31.3445 7.24931 31.2612L7.74911 30.8923L7.86811 31.4516L8.1656 31.2612L8.1894 30.6781L8.6535 30.4877C8.6297 30.5472 8.6059 30.6186 8.5702 30.6781C8.2965 31.2017 7.7848 31.5468 7.4397 31.4635Z"
                fill="#FDFDFD"/>
            <path
                d="M17.4833 25.7752C16.9597 26.8343 15.8173 27.5126 14.901 27.3103C13.9966 27.0961 13.6872 26.0727 14.1989 25.0255C14.7106 23.9665 15.8768 23.2763 16.7693 23.4786C17.6856 23.6928 18.0069 24.7281 17.4833 25.7752Z"
                fill="#131113"/>
            <path
                d="M16.912 24.252C16.4122 24.133 15.7101 24.6328 15.3531 25.3706C14.9961 26.1084 15.1151 26.7986 15.6149 26.9057C16.1147 27.0247 16.8168 26.513 17.1738 25.7871C17.5189 25.0493 17.4118 24.371 16.912 24.252ZM16.8882 25.2516V25.2278L16.5074 25.204L16.7097 24.6804C16.8644 24.7875 16.9239 25.0017 16.8882 25.2516ZM16.5312 24.6209L16.1266 25.1088L15.8172 24.9779C16.0433 24.7518 16.317 24.609 16.5312 24.6209ZM15.5792 25.3111L15.6625 25.7276L15.3412 26.0489C15.3174 25.8466 15.365 25.7276 15.4959 25.4658C15.5197 25.3944 15.5435 25.3587 15.5792 25.3111ZM15.6506 26.5606C15.5792 26.5368 15.4721 26.4535 15.4602 26.3583L15.9481 25.9775L16.079 26.5249L16.3646 26.3226L16.3765 25.7395L16.8287 25.5253C16.8049 25.5967 16.7811 25.6562 16.7454 25.7157C16.4836 26.2631 15.9957 26.632 15.6506 26.5606Z"
                fill="#FDFDFD"/>
            <path
                d="M5.69046 19.4326L11.1168 23.0621C10.9859 23.1335 10.8788 23.2049 10.7955 23.2644C9.65313 24.0022 8.11803 25.1089 6.36875 28.7503L0.942383 25.1208C2.69167 21.4794 4.22676 20.3727 5.36916 19.6349C5.45246 19.5754 5.57146 19.504 5.69046 19.4326Z"
                fill="#1B41A3"/>
            <path d="M0.311658 25.9181L5.73802 29.5476L5.44052 31.8205L0.0141602 28.191L0.311658 25.9181Z"
                  fill="#1B41A3"/>
            <path
                d="M0.94234 25.1089L6.3687 28.7384C6.1188 28.8931 5.77371 29.2977 5.73801 29.5476L0.311646 25.9181C0.347345 25.6682 0.502043 25.3112 0.94234 25.1089Z"
                fill="#03037C"/>
            <path d="M13.4016 17.719L18.8161 21.3604L15.8292 22.7646L10.4028 19.1351L13.4016 17.719Z" fill="#517CC9"/>
            <path
                d="M11.1168 23.0621C12.1283 22.4671 14.2465 21.3248 14.3536 21.2653L15.8173 22.7646L18.8161 21.3605C19.066 21.2058 19.2445 21.301 19.2088 21.5628L18.9113 23.8356C18.8756 24.0974 18.6495 24.4306 18.3996 24.5734L17.8879 24.8828C17.8998 24.8114 17.9117 24.7519 17.9236 24.6924C18.0426 23.7761 17.3286 23.3358 16.2933 23.9546C15.258 24.5734 14.2941 26.0014 14.1751 26.9177C14.1632 26.9772 14.1632 27.0367 14.1632 27.0962L9.77216 29.7023C9.78406 29.6309 9.79596 29.5714 9.80786 29.5C9.89116 28.5361 9.20096 28.1434 8.16567 28.7622C7.13038 29.381 6.16648 30.809 6.05938 31.7253C6.04748 31.7848 6.04748 31.8443 6.04748 31.9157L5.83328 32.0466C5.58338 32.1894 5.40488 32.1061 5.44058 31.8443L5.73808 29.5714C5.77378 29.3215 5.99988 28.9883 6.23788 28.8336C6.24978 28.8217 6.29738 28.7979 6.36878 28.7503C8.11807 25.1089 9.65316 24.0022 10.7955 23.2644C10.8788 23.2049 10.986 23.1335 11.1168 23.0621Z"
                fill="#03037C"/>
            <path
                d="M8.93917 17.6357L14.3655 21.2652C14.2584 21.3247 12.1402 22.4552 11.1288 23.0621L5.70239 19.4326C6.71389 18.8257 8.83207 17.6952 8.93917 17.6357Z"
                fill="#517CC9"/>
            <path
                d="M13.7229 17.6834L19.1493 21.3128C19.0779 21.2652 18.9589 21.2771 18.828 21.3604L13.4016 17.731C13.5325 17.6477 13.6515 17.6358 13.7229 17.6834Z"
                fill="#03037C"/>
            <path
                d="M5.51195 32.0703L0.0855809 28.4408C0.0260813 28.4051 -0.00961994 28.3099 0.00227998 28.1909L5.42865 31.8323C5.42865 31.9394 5.45245 32.0227 5.51195 32.0703Z"
                fill="#360101"/>
            <path
                d="M5.10729 20.6583C5.97599 21.17 8.54637 22.7884 9.34366 23.3358C9.49836 23.4429 9.40317 23.8118 9.31987 23.8713C8.61777 24.4068 7.55868 25.5134 6.64239 27.2746C6.45199 27.6673 6.04739 27.4055 6.04739 27.4055L1.88242 24.6685C1.88242 24.6685 1.58491 24.4663 1.81101 24.1212C2.69161 22.4314 3.7745 21.3128 4.4766 20.7535C4.6194 20.6464 4.94069 20.5512 5.10729 20.6583Z"
                fill="#FDFDFD"/>
            <path
                d="M2.32271 24.5853C2.32271 24.5853 2.02522 24.383 2.25131 24.0379C3.14381 22.3481 4.2148 21.2295 4.9169 20.6702C4.9288 20.6583 4.9526 20.6464 4.9764 20.6345C4.7979 20.6107 4.5956 20.6821 4.5004 20.7654C3.79831 21.3247 2.71541 22.4433 1.83482 24.1331C1.60872 24.4782 1.90621 24.6805 1.90621 24.6805L6.07119 27.4175C6.07119 27.4175 6.39249 27.6317 6.60668 27.3818C6.53528 27.358 6.49959 27.3223 6.49959 27.3223L2.32271 24.5853Z"
                fill="#360101"/>
            <path
                d="M7.57068 27.0605C8.27278 25.6444 9.83166 23.55 13.8657 21.8126C13.9966 21.7531 14.2584 21.6579 14.4845 21.8602C14.7582 22.0982 15.1509 22.4909 15.3889 22.717C15.496 22.8241 15.4603 22.9669 15.2699 23.074C14.0561 23.7642 9.52227 26.3584 7.89198 27.2865C7.57068 27.465 7.47548 27.2628 7.57068 27.0605Z"
                fill="#FDFDFD"/>
            <path
                d="M15.4008 22.717C15.1747 22.4909 14.7701 22.0982 14.4964 21.8602C14.3417 21.7174 14.1632 21.7293 14.0323 21.7531C14.0561 21.765 14.0799 21.7769 14.0918 21.8007C14.3655 22.0387 14.7582 22.4314 14.9962 22.6575C15.1033 22.7646 15.0676 22.9074 14.8772 23.0145C13.6753 23.7047 9.2009 26.2512 7.53491 27.2032C7.53491 27.3341 7.65391 27.4293 7.89191 27.2865C9.5222 26.3583 14.0561 23.7761 15.2699 23.074C15.4722 22.9669 15.5079 22.836 15.4008 22.717Z"
                fill="#360101"/>
            <path
                d="M8.87966 18.9447C9.20096 18.7663 9.70075 18.79 9.99825 18.9804L12.4258 20.6107C12.4258 20.6107 12.6995 20.8249 12.4258 20.9677C12.0212 21.17 11.3548 21.5627 11.3548 21.5627C11.0335 21.7412 10.5337 21.7293 10.2363 21.527L8.04667 20.0633C7.74917 19.8729 7.77297 19.5635 8.09426 19.385L8.87966 18.9447Z"
                fill="#FDFDFD"/>
            <path
                d="M8.85583 19.2066C9.17712 19.04 9.67692 19.0519 9.97442 19.2423L12.402 20.8726C12.402 20.8726 12.4377 20.8964 12.4734 20.944C12.6757 20.8012 12.4377 20.6227 12.4377 20.6227L10.0101 18.9924C9.71262 18.7901 9.21282 18.7782 8.89152 18.9567L8.11803 19.397C7.90383 19.516 7.82053 19.6945 7.88003 19.8492C7.91573 19.7778 7.98713 19.7064 8.09423 19.6469L8.85583 19.2066Z"
                fill="#360101"/>
            <path
                d="M6.41635 29.5475L6.34496 30.1663C6.34496 30.1663 6.35685 30.2615 6.21405 30.3567C6.07125 30.4519 5.57146 30.7375 5.57146 30.7375C5.57146 30.7375 4.40526 29.9759 4.22676 29.845C4.04826 29.7141 4.08397 29.5713 4.08397 29.5713L4.15537 29.0001C4.15537 29.0001 4.17917 28.7383 4.39337 28.8811C4.60756 29.012 5.70236 29.7617 5.70236 29.7617C5.70236 29.7617 6.13076 29.5237 6.28546 29.4166C6.45205 29.3214 6.41635 29.5475 6.41635 29.5475Z"
                fill="#F8B227"/>
            <path
                d="M1.78733 27.358C1.78733 27.4175 1.69213 28.0363 1.69213 28.0363C1.69213 28.0363 1.68023 28.2029 1.48983 28.0601C1.39463 28.0006 0.145142 27.1438 0.145142 27.1438L0.287942 26.1323L1.60883 27.0248C1.62073 27.0248 1.82303 27.12 1.78733 27.358Z"
                fill="#F8B227"/>
            <path opacity="0.17"
                  d="M26.2179 25.1088L40.6882 23.1929L42.616 19.1708L38.3796 15.5175L28.3956 14.8987L26.2179 25.1088Z"
                  fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2925 2.80835L18.2092 2.85595H18.2925V2.80835Z"
                  fill="#F3DBAB"/>
            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.2925 2.80835L18.2092 2.85595H18.2925V2.80835Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.6762 6.6283L16.7099 9.54378L26.3012 15.0772V25.2041L21.2795 22.3005L11.6643 16.7551L11.6762 6.6283Z"
                  fill="#F6E4C0"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.2937 10.4125L17.0906 11.4359L25.0873 16.0649V21.7174L23.3024 20.6821L15.2937 16.053V10.4125Z"
                  fill="#657576"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9877 9.49609L35.9044 9.54369L26.3011 15.0772V25.204L31.3348 22.3004L35.9877 19.6229V9.49609Z"
                  fill="#F3DBAB"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9877 9.49609L35.9044 9.54369L26.3011 15.0772V25.204L31.3348 22.3004L35.9877 19.6229V9.49609Z"
                  fill="#F3DBAB"/>
            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9877 9.49609L35.9044 9.54369L26.3011 15.0772V25.204L31.3348 22.3004L35.9877 19.6229V9.49609Z"
                  fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19.3754 10.1388L18.5662 10.6029L26.2178 15.0297L26.313 14.9702V14.1372L19.3754 10.1388Z"
                  fill="#FFCA2B"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.8926 8.77019H35.9878V8.97249L26.3131 14.6607L11.4382 5.97371V5.74761L25.016 5.31921L35.8926 8.77019Z"
                  fill="#FFC522"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9878 9.30578V9.50808L26.3131 15.1962L11.4382 6.50929V6.2951L11.7238 6.1285L25.016 5.8667L35.9878 9.30578Z"
                  fill="#FFC522"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.4382 6.29502L26.3131 14.9463L35.9878 9.3057L35.607 9.1867L23.6951 9.5794L11.6405 6.17603L11.4382 6.29502Z"
                  fill="#FFCA2B"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.5662 9.87688L25.8847 14.1133L25.4682 12.9233L23.3976 7.0923L22.7669 5.31921L12.9376 5.68811L11.4263 5.74761L18.5662 9.87688Z"
                  fill="#FF953F"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M23.3975 7.0923L25.4681 12.9233L25.8846 14.1133L26.2178 14.3037L26.3011 14.3513L35.833 8.85349L25.2896 5.99751L22.7668 5.31921L23.3975 7.0923Z"
                  fill="#945300"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.2897 5.99756L35.8331 8.85355L35.9045 8.81784L35.9878 8.77025L28.2409 4.29587L28.2647 4.28397L20.8392 0L20.8154 0.0119006L19.8634 0.559298L12.1522 5.31927L20.8392 0.107102L22.767 5.31927L25.2897 5.99756Z"
                  fill="#DD8143"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.7669 5.31927L20.8391 0L12.0927 5.35497L11.4263 5.75957L12.9376 5.70007L22.7669 5.31927ZM18.2925 2.08249V2.13009H18.2092L18.2925 2.08249Z"
                  fill="#FF994D"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M26.3131 14.5536L11.6405 6.08081V6.28311L26.3131 14.7559V14.5536Z" fill="#DF9B17"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M26.3131 14.5536L35.7855 9.07959V9.29379L26.3131 14.7559V14.5536Z" fill="#DF9B17"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.0685 17.3382L13.0804 10.9241V10.9003L13.0922 9.12724L12.89 9.02014V10.7813V10.817L12.8781 17.4572L12.9019 17.4691L13.0922 17.3501L13.0685 17.3382Z"
                  fill="#F3DBAB"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.0804 10.9241L13.0685 17.3381L13.0923 17.35L15.3176 16.0768L15.2938 16.053V12.2093V12.1736V10.4124L13.0923 9.1272L13.0804 10.9003V10.9241Z"
                  fill="#95A5A6"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 16.0649L14.0918 9.71033V14.4941L25.0873 20.8368V16.0649Z" fill="#BDC3C7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 16.3386L14.0918 9.99597V10.1388L25.0873 16.4814V16.3386Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 16.7551L14.0918 10.4125V10.5434L25.0873 16.886V16.7551Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 17.1597L14.0918 10.817V10.9598L25.0873 17.3025V17.1597Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 17.5643L14.0918 11.2217V11.3645L25.0873 17.7071V17.5643Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 17.9689L14.0918 11.6262V11.769L25.0873 18.1117V17.9689Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 18.3854L14.0918 12.0427V12.1736L25.0873 18.5163V18.3854Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 18.7899L14.0918 12.4473V12.5901L25.0873 18.9327V18.7899Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 19.1946L14.0918 12.8519V12.9947L25.0873 19.3374V19.1946Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 19.611L14.0918 13.2565V13.3993L25.0873 19.7419V19.611Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 20.0156L14.0918 13.673V13.8039L25.0873 20.1465V20.0156Z" fill="#556566"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.0873 20.4203L14.0918 14.0776V14.2204L25.0873 20.5631V20.4203Z" fill="#556566"/>
            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.0923 17.3501L17.055 17.0407L15.3176 16.0768L13.0923 17.3501Z" fill="black"/>
        </svg>
    )
}