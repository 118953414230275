import styled from "styled-components";
import {useEffect, useState} from "react";


export const LoadingView = () => {
    const maxCnt = 7
    const [currentCnt, setCurrentCnt] = useState<number>(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentCnt < maxCnt) {
                setCurrentCnt(currentCnt + 1)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [currentCnt])

    return (
        <Container>
            <Logo src={'/images/logo.png'}/>
            <ProgressContainer>
                {[...Array(maxCnt)].map((_, index) => {
                        return <ProgressItem key={index}/>
                    }
                ).slice(0, currentCnt)
                }
            </ProgressContainer>
        </Container>
    )
}


const Logo = styled.img`
   
    margin-top: 100px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/images/splash.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
`

const ProgressContainer = styled.div`
    width: 322.115px;
    height: 16.093px;
    flex-shrink: 0;
    background: #FFD72F;
    position: absolute;
    bottom: 64px;
    border-radius: 4px;
    display: flex;
    align-items: center;
`

const ProgressItem = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="15" viewBox="0 0 45 15" fill="none">
            <path
                d="M44.0937 12.5122C44.0937 13.5147 43.3814 14.2006 42.4053 14.2006H3.12359C2.22663 14.2006 1.43518 13.4091 1.43518 12.5122V2.30263C1.43518 1.30015 2.14748 0.614258 3.12359 0.614258H42.4053C43.3023 0.614258 44.0937 1.40567 44.0937 2.30263V12.5122Z"
                fill="#EA6834"/>
            <path
                d="M42.4846 14.7021H3.09732C1.91016 14.7021 0.986816 13.6996 0.986816 12.5125V2.30294C0.986816 1.11578 1.88378 0.113281 3.09732 0.113281H42.3791C43.5662 0.113281 44.4896 1.11578 44.4896 2.30294V12.5125C44.5951 13.6996 43.5926 14.7021 42.4846 14.7021ZM3.09732 1.01028C2.38502 1.01028 1.91016 1.61703 1.91016 2.30294V12.5125C1.91016 13.2248 2.41141 13.8052 3.09732 13.8052H42.3791C43.0914 13.8052 43.5662 13.1984 43.5662 12.5125V2.30294C43.5662 1.59065 43.065 1.01028 42.3791 1.01028H3.09732Z"
                fill="#7E4611"/>
        </svg>
    )
}



