import styled from "styled-components";
import {Head} from "./icons/head";


interface Props {
    full: number;
}

export const Life = ({full}: Props) => {
    const cnt = 3
    return (
        <Row>
            {[...Array(cnt)].map((_, i) => {
                return <LifeItem key={i} isFull={i < full}/>
            })}
        </Row>
    )

}


const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 2.665px;
    position: absolute;
    top: calc(20px + var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
    left: 10px;
`

const LifeItem = ({isFull = true}) => {
    return (
        <Container>
            {isFull && <Head
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}
            />}
        </Container>
    )
}


const Container = styled.div`
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M30.2106 31.7786H2.23196C1.24224 31.7786 0.442871 30.9792 0.442871 29.9895V2.0108C0.442871 1.02108 1.24224 0.22168 2.23196 0.22168H30.2106C31.2004 0.22168 31.9998 1.02108 31.9998 2.0108V29.9895C31.9998 30.9792 31.2004 31.7786 30.2106 31.7786Z" fill="%23FFA300"/><path d="M29.297 30.8265H3.10751C2.15586 30.8265 1.39453 30.0651 1.39453 29.1135V2.92392C1.39453 1.97226 2.15586 1.21094 3.10751 1.21094H29.297C30.2487 1.21094 31.01 1.97226 31.01 2.92392V29.1135C31.0481 30.0651 30.2487 30.8265 29.297 30.8265Z" fill="%23FFE600"/><path d="M29.1449 29.7987H3.29791C2.84111 29.7987 2.42236 29.418 2.42236 28.9232V3.0762C2.42236 2.61941 2.80304 2.20068 3.29791 2.20068H29.1449C29.6017 2.20068 30.0204 2.58134 30.0204 3.0762V28.9232C30.0204 29.38 29.6397 29.7987 29.1449 29.7987Z" fill="black"/><path d="M3.60254 1.17279C5.73425 1.13472 7.90403 1.09666 10.0357 1.09666L16.4689 1.05859L22.9021 1.09666L26.1378 1.13472L29.3734 1.17279L26.1378 1.21086L22.9021 1.24892L16.4689 1.28699L10.0357 1.24892C7.86597 1.24892 5.73425 1.21085 3.60254 1.17279Z" fill="white"/></svg>');
    position: relative;
    width: 31.557px;
    height: 31.557px;
`

