import styled from "styled-components";


interface Props {
    title: string;
    value: number | string;
    valueColor?: string;
}

export const Info = ({title, value, valueColor}: Props) => {
    return (
        <Container>
            <Inner>
                <Label>
                    {title}
                </Label>
                <Value style={{color: valueColor}}>
                    {value}
                </Value>
            </Inner>
            <Bg/>
        </Container>
    )
}


const Inner = styled.div`
    display: flex;
    padding: 6px 8.5px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #090909;
    flex-direction: column;
    gap: 4px;
    z-index: 10;
    position: relative; /* Ensures proper stacking within the container */

`

const Container = styled.div`
    width: 148px;
    height: 48px;
    position: relative;
`

const Bg = styled.div`
    width: 156px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFA300;
    position: absolute;
    z-index: 0;
    top: 10px;
    left: -4px;
`


const Label = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`
const Value = styled.div`
    color: #FFE600;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    text-transform: uppercase;

`
