import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import {Info} from "../info";
import {useFriendsStore} from "../../store/friends-store";
import {tgLink} from "../utils/tg-link";
import {OnboardingModal} from "./onboarding";
import React from "react";
import {ColorButton} from "../color-button";
import {WBloxItem} from "../w-blox";
import {useUserStore} from "../../store/user-store";

export const InviteView = () => {
    const {friends} = useFriendsStore()
    const {user} = useUserStore()

    return (
        <Wrapper>
            <Container>
                <Col>
                    <BorderedTitle title={'invite'}/>
                    <Desc>
                        Your and your friendrs will receive bonuses
                    </Desc>
                    <ColorButton
                        variant={'primary'}
                        onClick={() => tgLink(friends?.friends_share_link || '')} text={'Invite friends'}/>
                    <Row>
                        {/*TODO: Add real data*/}
                        <Info
                            valueColor={'#00C3FF'}
                            title={'Premium Bonus'} value={'12 200'}/>
                        <Info
                            valueColor={'#00C3FF'}
                            title={'Normal Bonus'} value={'200'}/>
                    </Row>
                    <WBloxItem value={user?.balance}/>
                    <BottomNav/>
                </Col>
                <List>
                    <SubTitle>
                        Statistic
                    </SubTitle>
                    <SItem>
                        <SItem2>
                            <SItem3>
                                <SItem4>
                                    <SItem5>
                                        <Stext>
                                            Invite Friends
                                        </Stext>
                                        <Sblock>
                                            <SblockTitle>
                                                Default
                                            </SblockTitle>
                                            <SblockDesc>
                                               100
                                            </SblockDesc>
                                        </Sblock>
                                        <Sblock>
                                            <SblockTitle>
                                                Premium
                                            </SblockTitle>
                                            <SblockDesc>
                                                2500
                                            </SblockDesc>
                                        </Sblock>
                                    </SItem5>
                                </SItem4>
                            </SItem3>
                        </SItem2>
                    </SItem>
                    <SItem>
                        <SItem2>
                            <SItem3>
                                <SItem4>
                                    <SItem5>
                                        <Stext>
                                            Friends Bonuses
                                        </Stext>
                                        <Sblock>
                                            <SblockTitle>
                                                wBloxx
                                            </SblockTitle>
                                            <SblockDesc>
                                              30 500
                                            </SblockDesc>
                                        </Sblock>
                                    </SItem5>
                                </SItem4>
                            </SItem3>
                        </SItem2>
                    </SItem>
                </List>
            </Container>
            <OnboardingModal

                hamster={3}
                text={'Invite friends and receive welcome bonus'} next={'/earn'}/>
        </Wrapper>

    )
}


const Sblock = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 2px;
    background: #090909;
    box-sizing: border-box;
    flex: 1 0 0;
    flex-direction: column;
`

const SblockTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`

const SblockDesc = styled.div`
    color: #00C3FF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    text-transform: uppercase;
    text-wrap: nowrap;
`


const Stext = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 7px;
`


const SItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #FFA300;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 0, 0.50);
    box-sizing: border-box;
`


const SItem2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #FFA301;
    box-sizing: border-box;

`

const SItem3 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #FFE600;
    box-sizing: border-box;
`

const SItem4 = styled.div`
    display: flex;
    height: 56px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #20150E;
    background: #FFE600;
    box-sizing: border-box;
`


const SItem5 = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    box-sizing: border-box;

`

const SubTitle = styled.div`
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
`


const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;
    padding: 0 16px;


`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;

`
const Desc = styled.div`
    color: #FFE600;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    text-transform: uppercase;
`


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto; /* Enable vertical scrolling */
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto; /* Ensure container can scroll */
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding-bottom: 120px; /* Add padding to ensure the last items are visible */
    padding-top: calc(var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`;


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    gap: 20px;
    width: 100%;
    height: 100%;
`
