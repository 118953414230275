import {IconProps} from "../../model";

export const ConditionerIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
            <path d="M2.04504 6.71966L6.33976 4.23632V1.11023L2.04504 3.59357V6.71966Z" fill="#D4CDBC"/>
            <path d="M2.04498 3.59366L0.131348 2.48346L4.42607 0.00012207L6.3397 1.11032L2.04498 3.59366Z"
                  fill="#FEFBEC"/>
            <path d="M0.131348 5.60949L2.04498 6.71969V3.5936L0.131348 2.4834V5.60949Z" fill="#99917E"/>
            <path d="M0.934779 3.35986L0.774092 3.6082V4.86447L0.394287 5.44879V6.06232L0.934779 5.75556V3.35986Z"
                  fill="#D4CDBC"/>
            <path d="M0.379761 3.38908L0.774174 3.6082L0.93486 3.35987L0.540448 3.14075L0.379761 3.38908Z"
                  fill="#FEFBEC"/>
            <path d="M0.394413 5.44882L0 5.2151L0.379805 4.64539L0.774218 4.8645L0.394413 5.44882Z" fill="#FEFBEC"/>
            <path d="M0.379761 3.38904V4.64531L0.774174 4.86443V3.60816L0.379761 3.38904Z" fill="#C4BBA3"/>
            <path d="M0.394413 5.44881L0 5.21509V5.84323L0.394413 6.06234V5.44881Z" fill="#C4BBA3"/>
            <path
                d="M1.18319 5.02523L1.62142 5.27356C1.63116 5.27356 1.63603 5.26869 1.63603 5.25895C1.63603 5.24435 1.62142 5.22974 1.62142 5.21513L1.18319 4.9668C1.17345 4.9668 1.16858 4.97167 1.16858 4.98141C1.16858 4.99601 1.16858 5.02523 1.18319 5.02523Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.33208L1.18319 5.08374C1.17345 5.08374 1.16858 5.08861 1.16858 5.09835C1.16858 5.11296 1.18319 5.12757 1.18319 5.14218L1.62142 5.39051C1.63116 5.39051 1.63603 5.38564 1.63603 5.3759C1.65064 5.36129 1.63603 5.34668 1.62142 5.33208Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.43425L1.18319 5.18591C1.17345 5.18591 1.16858 5.19078 1.16858 5.20052C1.16858 5.21513 1.18319 5.22974 1.18319 5.24434L1.62142 5.49268C1.63116 5.49268 1.63603 5.48781 1.63603 5.47807C1.65064 5.46346 1.63603 5.44886 1.62142 5.43425Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.53642L1.18319 5.28809C1.17345 5.28809 1.16858 5.29295 1.16858 5.30269C1.16858 5.3173 1.18319 5.33191 1.18319 5.34652L1.62142 5.59485C1.63116 5.59485 1.63603 5.58998 1.63603 5.58024C1.65064 5.56564 1.63603 5.55103 1.62142 5.53642Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.63872L1.18319 5.39038C1.17345 5.39038 1.16858 5.39525 1.16858 5.40499C1.16858 5.4196 1.18319 5.4342 1.18319 5.44881L1.62142 5.69715C1.63116 5.69715 1.63603 5.69228 1.63603 5.68254C1.63603 5.66793 1.63603 5.65332 1.62142 5.63872Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.74101L1.18319 5.49268C1.17345 5.49268 1.16858 5.49755 1.16858 5.50728C1.16858 5.52189 1.18319 5.5365 1.18319 5.55111L1.62142 5.79944C1.63116 5.79944 1.63603 5.79457 1.63603 5.78483C1.65064 5.77023 1.63603 5.75562 1.62142 5.74101Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.84331L1.18319 5.59497C1.17345 5.59497 1.16858 5.59984 1.16858 5.60958C1.16858 5.62419 1.18319 5.6388 1.18319 5.65341L1.62142 5.90174C1.63116 5.90174 1.63603 5.89687 1.63603 5.88713C1.65064 5.87252 1.63603 5.85791 1.62142 5.84331Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 5.94548L1.18319 5.69714C1.17345 5.69714 1.16858 5.70201 1.16858 5.71175C1.16858 5.72636 1.18319 5.74097 1.18319 5.75557L1.62142 6.00391C1.63116 6.00391 1.63603 5.99904 1.63603 5.9893C1.65064 5.97469 1.63603 5.96009 1.62142 5.94548Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 6.04765L1.18319 5.79932C1.17345 5.79932 1.16858 5.80418 1.16858 5.81392C1.16858 5.82853 1.18319 5.84314 1.18319 5.85775L1.62142 6.10608C1.63116 6.10608 1.63603 6.10121 1.63603 6.09147C1.65064 6.07687 1.63603 6.06226 1.62142 6.04765Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 6.14995L1.18319 5.90161C1.17345 5.90161 1.16858 5.90648 1.16858 5.91622C1.16858 5.93083 1.18319 5.94543 1.18319 5.96004L1.62142 6.20838C1.63116 6.20838 1.63603 6.20351 1.63603 6.19377C1.65064 6.17916 1.63603 6.16456 1.62142 6.14995Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 6.25224L1.18319 6.00391C1.17345 6.00391 1.16858 6.00878 1.16858 6.01852C1.16858 6.03312 1.18319 6.04773 1.18319 6.06234L1.62142 6.31067C1.63116 6.31067 1.63603 6.3058 1.63603 6.29606C1.65064 6.28146 1.63603 6.26685 1.62142 6.25224Z"
                fill="#3F2D33"/>
            <path
                d="M1.18319 3.75424L1.62142 4.00257C1.63116 4.00257 1.63603 3.9977 1.63603 3.98796C1.63603 3.97335 1.62142 3.95874 1.62142 3.94414L1.18319 3.6958C1.17345 3.6958 1.16858 3.70067 1.16858 3.71041C1.16858 3.73963 1.16858 3.75424 1.18319 3.75424Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.07572L1.18319 3.82739C1.17345 3.82739 1.16858 3.83226 1.16858 3.842C1.16858 3.8566 1.18319 3.87122 1.18319 3.88582L1.62142 4.13415C1.63116 4.13415 1.63603 4.12929 1.63603 4.11955C1.65064 4.09033 1.63603 4.07572 1.62142 4.07572Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.1779L1.18319 3.92957C1.17345 3.92957 1.16858 3.93444 1.16858 3.94417C1.16858 3.95878 1.18319 3.97339 1.18319 3.988L1.62142 4.23633C1.63116 4.23633 1.63603 4.23146 1.63603 4.22172C1.65064 4.19251 1.63603 4.1779 1.62142 4.1779Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.28019L1.18319 4.03186C1.17345 4.03186 1.16858 4.03673 1.16858 4.04647C1.16858 4.06108 1.18319 4.07568 1.18319 4.09029L1.62142 4.33863C1.63116 4.33863 1.63603 4.33376 1.63603 4.32402C1.65064 4.2948 1.63603 4.28019 1.62142 4.28019Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.38237L1.18319 4.13403C1.17345 4.13403 1.16858 4.1389 1.16858 4.14864C1.16858 4.16325 1.18319 4.17786 1.18319 4.19247L1.62142 4.4408C1.63116 4.4408 1.63603 4.43593 1.63603 4.42619C1.65064 4.39698 1.63603 4.38237 1.62142 4.38237Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.48466L1.18319 4.23633C1.17345 4.23633 1.16858 4.2412 1.16858 4.25094C1.16858 4.26554 1.18319 4.28015 1.18319 4.29476L1.62142 4.54309C1.63116 4.54309 1.63603 4.53822 1.63603 4.52849C1.65064 4.49927 1.63603 4.48466 1.62142 4.48466Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.58695L1.18319 4.33862C1.17345 4.33862 1.16858 4.34349 1.16858 4.35323C1.16858 4.36784 1.18319 4.38245 1.18319 4.39705L1.62142 4.64539C1.63116 4.64539 1.63603 4.64052 1.63603 4.63078C1.65064 4.60156 1.63603 4.58695 1.62142 4.58695Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.68925L1.18319 4.44092C1.17345 4.44092 1.16858 4.44579 1.16858 4.45553C1.16858 4.47013 1.18319 4.48474 1.18319 4.49935L1.62142 4.74768C1.63116 4.74768 1.63603 4.74281 1.63603 4.73308C1.63603 4.71847 1.63603 4.68925 1.62142 4.68925Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.79142L1.18319 4.54309C1.17345 4.54309 1.16858 4.54796 1.16858 4.5577C1.16858 4.57231 1.18319 4.58691 1.18319 4.60152L1.62142 4.84986C1.63116 4.84986 1.63603 4.84499 1.63603 4.83525C1.65064 4.80603 1.63603 4.79142 1.62142 4.79142Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.89372L1.18319 4.64539C1.17345 4.64539 1.16858 4.65026 1.16858 4.65999C1.16858 4.6746 1.18319 4.68921 1.18319 4.70382L1.62142 4.95215C1.63116 4.95215 1.63603 4.94728 1.63603 4.93754C1.65064 4.90833 1.63603 4.89372 1.62142 4.89372Z"
                fill="#3F2D33"/>
            <path
                d="M1.62142 4.99589L1.18319 4.74756C1.17345 4.74756 1.16858 4.75243 1.16858 4.76217C1.16858 4.77678 1.18319 4.79138 1.18319 4.80599L1.62142 5.05433C1.63116 5.05433 1.63603 5.04945 1.63603 5.03972C1.65064 5.0105 1.63603 4.99589 1.62142 4.99589Z"
                fill="#3F2D33"/>
            <path
                d="M3.91492 4.11954C3.91492 4.80611 4.39698 5.08365 4.98129 4.73306C5.58022 4.39708 6.04767 3.56444 6.04767 2.87787C6.04767 2.1913 5.56561 1.91374 4.98129 2.26433C4.39698 2.60031 3.91492 3.43297 3.91492 4.11954Z"
                fill="#070506"/>
            <path
                d="M5.58009 2.13281C5.62392 2.23507 5.65313 2.36654 5.65313 2.52722C5.65313 3.21379 5.17107 4.04644 4.58676 4.38243C4.36764 4.5139 4.17774 4.54311 4.00244 4.5139C4.14852 4.8937 4.54293 5.01057 5.01039 4.74762C5.60931 4.41164 6.07676 3.57899 6.07676 2.89242C6.06215 2.45418 5.87225 2.17664 5.58009 2.13281Z"
                fill="#3F2D33"/>
            <path
                d="M4.51376 4.92292C4.4115 4.92292 4.30925 4.8937 4.2216 4.84988C4.00248 4.73302 3.88562 4.47008 3.88562 4.11949C3.88562 3.43292 4.36768 2.58566 4.98121 2.23507C5.27337 2.05977 5.56553 2.03056 5.77004 2.16203C5.98916 2.27889 6.10602 2.54183 6.10602 2.89242C6.10602 3.57899 5.62396 4.42625 5.01043 4.77684C4.83513 4.86449 4.65984 4.92292 4.51376 4.92292ZM5.46327 2.14742C5.31719 2.14742 5.17111 2.19125 4.99582 2.2935C4.4115 2.62948 3.94405 3.44752 3.94405 4.11949C3.94405 4.44086 4.04631 4.67458 4.23621 4.79145C4.42611 4.90831 4.68905 4.87909 4.9666 4.71841C5.55092 4.38243 6.01837 3.56438 6.01837 2.89242C6.01837 2.57105 5.91612 2.33732 5.72621 2.22046C5.65317 2.17664 5.56553 2.14742 5.46327 2.14742Z"
                fill="#FEFBEC"/>
            <path
                d="M4.73291 3.65207C4.73291 3.81275 4.84977 3.87118 4.98124 3.79814C5.11272 3.72511 5.22958 3.5206 5.22958 3.35991C5.22958 3.19922 5.11272 3.14079 4.98124 3.21383C4.84977 3.30148 4.73291 3.49138 4.73291 3.65207Z"
                fill="#FEFBEC"/>
            <mask id="mask0_4111_69191"
                  style={{maskType: "luminance"}}
                  // style="mask-type:luminance"
                  maskUnits="userSpaceOnUse" x="3" y="2" width="4"
                  height="3">
                <path
                    d="M3.91492 4.11954C3.91492 4.80611 4.39698 5.08365 4.98129 4.73306C5.58022 4.39708 6.04767 3.56444 6.04767 2.87787C6.04767 2.1913 5.56561 1.91374 4.98129 2.26433C4.39698 2.60031 3.91492 3.43297 3.91492 4.11954Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask0_4111_69191)">
                <path d="M4.10478 1.95752H4.07556V5.60949H4.10478V1.95752Z" fill="#FEFBEC"/>
                <path d="M4.36772 1.75293H4.3385V5.4049H4.36772V1.75293Z" fill="#FEFBEC"/>
                <path d="M4.61613 1.54858H4.58691V5.20056H4.61613V1.54858Z" fill="#FEFBEC"/>
                <path d="M4.87895 1.34399H4.84973V4.99597H4.87895V1.34399Z" fill="#FEFBEC"/>
                <path d="M5.12736 1.1394H5.09814V4.79138H5.12736V1.1394Z" fill="#FEFBEC"/>
                <path d="M5.3903 0.92041H5.36108V4.57238H5.3903V0.92041Z" fill="#FEFBEC"/>
                <path d="M5.63859 0.71582H5.60938V4.36779H5.63859V0.71582Z" fill="#FEFBEC"/>
                <path d="M5.88688 0.511353H5.85767V4.16332H5.88688V0.511353Z" fill="#FEFBEC"/>
                <path d="M6.17834 3.90165L3.74866 5.30273L3.75595 5.31539L6.18564 3.9143L6.17834 3.90165Z"
                      fill="#FEFBEC"/>
                <path d="M6.17847 3.74052L3.74878 5.1416L3.75608 5.15426L6.18576 3.75317L6.17847 3.74052Z"
                      fill="#FEFBEC"/>
                <path d="M6.17847 3.57938L3.74878 4.98047L3.75608 4.99312L6.18576 3.59204L6.17847 3.57938Z"
                      fill="#FEFBEC"/>
                <path d="M6.17859 3.41972L3.7489 4.8208L3.7562 4.83346L6.18589 3.43237L6.17859 3.41972Z"
                      fill="#FEFBEC"/>
                <path d="M6.17871 3.25858L3.74902 4.65967L3.75632 4.67232L6.18601 3.27124L6.17871 3.25858Z"
                      fill="#FEFBEC"/>
                <path d="M6.17871 3.09757L3.74902 4.49866L3.75632 4.51131L6.18601 3.11022L6.17871 3.09757Z"
                      fill="#FEFBEC"/>
                <path d="M6.17883 2.93778L3.74915 4.33887L3.75644 4.35152L6.18613 2.95044L6.17883 2.93778Z"
                      fill="#FEFBEC"/>
                <path d="M6.17895 2.77677L3.74927 4.17786L3.75657 4.19051L6.18625 2.78943L6.17895 2.77677Z"
                      fill="#FEFBEC"/>
                <path d="M6.17895 2.61564L3.74927 4.01672L3.75657 4.02938L6.18625 2.62829L6.17895 2.61564Z"
                      fill="#FEFBEC"/>
                <path d="M6.17908 2.45463L3.74939 3.85571L3.75669 3.86837L6.18637 2.46728L6.17908 2.45463Z"
                      fill="#FEFBEC"/>
                <path d="M6.1792 2.29496L3.74951 3.69604L3.75681 3.7087L6.18649 2.30761L6.1792 2.29496Z"
                      fill="#FEFBEC"/>
                <path d="M6.1792 2.13383L3.74951 3.53491L3.75681 3.54757L6.1865 2.14648L6.1792 2.13383Z"
                      fill="#FEFBEC"/>
                <path d="M6.17773 1.97269L3.74805 3.37378L3.75534 3.38643L6.18503 1.98535L6.17773 1.97269Z"
                      fill="#FEFBEC"/>
                <path d="M6.17786 1.81303L3.74817 3.21411L3.75547 3.22677L6.18515 1.82568L6.17786 1.81303Z"
                      fill="#FEFBEC"/>
                <path d="M6.17786 1.65189L3.74817 3.05298L3.75547 3.06563L6.18515 1.66455L6.17786 1.65189Z"
                      fill="#FEFBEC"/>
            </g>
            <path
                d="M4.82056 3.60821C4.82056 3.72507 4.8936 3.76889 4.99585 3.71046C5.0835 3.65203 5.17115 3.52056 5.17115 3.41831C5.17115 3.30144 5.09811 3.25762 4.99585 3.31605C4.8936 3.37448 4.82056 3.49135 4.82056 3.60821Z"
                fill="#D4CDBC"/>
            <path d="M2.29333 3.72506V6.29605L3.46197 5.62409V3.0531L2.29333 3.72506Z" fill="#C4BBA3"/>
            <path d="M3.37432 3.11153V5.58027L2.29333 6.1938V6.29605L3.46197 5.62409V3.0531L3.37432 3.11153Z"
                  fill="#E0DED0"/>
            <path
                d="M1.89893 3.27222C1.84049 3.243 1.84049 3.18457 1.89893 3.15536L5.60933 1.008C5.66776 0.97878 5.75541 0.97878 5.81384 1.008C5.87227 1.03721 5.87227 1.09565 5.81384 1.12486L2.10344 3.27222C2.045 3.31604 1.94275 3.31604 1.89893 3.27222Z"
                fill="#B8AE97"/>
            <path
                d="M1.48999 3.03845C1.43156 3.00924 1.43156 2.95081 1.48999 2.92159L5.20039 0.774231C5.25882 0.745015 5.34647 0.745015 5.4049 0.774231C5.46333 0.803447 5.46333 0.861881 5.4049 0.891097L1.6945 3.03845C1.63607 3.08228 1.53381 3.08228 1.48999 3.03845Z"
                fill="#B8AE97"/>
            <path
                d="M1.08093 2.80481C1.0225 2.77559 1.0225 2.71716 1.08093 2.68794L4.79134 0.540589C4.84977 0.511373 4.93741 0.511373 4.99585 0.540589C5.05428 0.569804 5.05428 0.628234 4.99585 0.65745L1.28544 2.80481C1.22701 2.83403 1.12476 2.83403 1.08093 2.80481Z"
                fill="#B8AE97"/>
            <path
                d="M0.671997 2.57117C0.613566 2.54195 0.613566 2.48352 0.671997 2.4543L4.3824 0.306946C4.44083 0.27773 4.52848 0.27773 4.58691 0.306946C4.64534 0.336162 4.64534 0.394592 4.58691 0.423807L0.876508 2.57117C0.818076 2.60038 0.715821 2.60038 0.671997 2.57117Z"
                fill="#B8AE97"/>
            <path
                d="M1.89891 3.243L5.60931 1.09564C5.66774 1.06643 5.75539 1.06643 5.81382 1.09564C5.82843 1.09564 5.82843 1.11025 5.84304 1.11025C5.87225 1.08104 5.85764 1.03721 5.81382 1.008C5.75539 0.97878 5.66774 0.97878 5.60931 1.008L1.89891 3.15536C1.85508 3.18457 1.84048 3.21379 1.86969 3.25761C1.86969 3.25761 1.8843 3.243 1.89891 3.243Z"
                fill="#D4CDBC"/>
            <path
                d="M1.48997 2.99471L5.20037 0.847349C5.25881 0.818133 5.34645 0.818133 5.40489 0.847349C5.41949 0.847349 5.41949 0.861957 5.4341 0.861957C5.46332 0.832742 5.44871 0.788921 5.40489 0.759705C5.34645 0.730489 5.25881 0.730489 5.20037 0.759705L1.48997 2.90706C1.44615 2.93628 1.43154 2.96549 1.46076 3.00932C1.46076 3.00932 1.47536 3.00932 1.48997 2.99471Z"
                fill="#D4CDBC"/>
            <path
                d="M1.08091 2.76094L4.79132 0.613588C4.84975 0.584373 4.9374 0.584373 4.99583 0.613588C5.01044 0.613588 5.01044 0.628193 5.02504 0.628193C5.05426 0.598977 5.03965 0.555156 4.99583 0.52594C4.9374 0.496724 4.84975 0.496724 4.79132 0.52594L1.08091 2.6733C1.03709 2.70251 1.02248 2.73173 1.0517 2.77555C1.0517 2.77555 1.06631 2.77555 1.08091 2.76094Z"
                fill="#D4CDBC"/>
            <path
                d="M0.671857 2.52718L4.38226 0.379824C4.44069 0.350608 4.52834 0.350608 4.58677 0.379824C4.60138 0.379824 4.60138 0.394432 4.61599 0.394432C4.6452 0.365217 4.63059 0.321391 4.58677 0.292175C4.52834 0.26296 4.44069 0.26296 4.38226 0.292175L0.671857 2.43954C0.628033 2.46875 0.613425 2.49797 0.642641 2.54179C0.642641 2.54179 0.657249 2.54179 0.671857 2.52718Z"
                fill="#D4CDBC"/>
        </svg>
    )
}