import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import {PlusIcon} from "../icons/plus";
import {useChannelsStore} from "../../store/channels-store";
import {useLinksStore} from "../../store/links-store";
import {tgLink} from "../utils/tg-link";
import {CheckedIcon} from "../icons/checked-icon";
import React from "react";
import {useUserStore} from "../../store/user-store";
import {linkHandler} from "../../api/requests/channels";
import {OnboardingModal} from "./onboarding";
import {WBloxItem} from "../w-blox";

export const EarnView = () => {
    const {channels, setChannels} = useChannelsStore()
    const {links, setLinks} = useLinksStore()
    const {setUser, user} = useUserStore()
    return (
        <Wrapper>
            <Container>
                <Col>
                    <BorderedTitle title={'earn'}/>
                    <List style={{marginBottom: 25, marginTop: 25}}>
                        <SubTitle>
                            Channels
                        </SubTitle>
                        {channels?.map((channel, index) => (
                            <EarnItem
                                isAvailible={channel.is_available}
                                onClick={() => {
                                    setChannels(channels.map((ch) => {
                                        if (ch.id === channel.id) {
                                            ch.is_available = false
                                        }
                                        return ch
                                    }))
                                    tgLink(channel.invite_link)
                                }}
                                key={index} channel={channel.title}
                                value={channel.reward}/>
                        ))}
                    </List>
                    <List style={{paddingBottom: 100}}>
                        <SubTitle>
                            Bots
                        </SubTitle>
                        {links?.map((channel, index) => (
                            <EarnItem
                                isAvailible={channel.is_available}
                                onClick={async () => {


                                    const response = await linkHandler(channel.id)

                                    setUser((user) => {
                                        return {
                                            ...user,
                                            balance: response.new_balance
                                        }
                                    })

                                    setLinks(links.map((ch) => {
                                        if (ch.id === channel.id) {
                                            ch.is_available = false
                                        }
                                        return ch
                                    }))
                                    tgLink(channel.link)
                                }}
                                key={index}
                                channel={channel.name}
                                value={channel.reward}/>
                        ))}
                    </List>
                </Col>
            </Container>
            <BottomNav/>
            <AbsoluteContainer>
                <WBloxItem value={user?.balance}/>
            </AbsoluteContainer>
            <OnboardingModal
                hamster={4}
                text={'Here you can complete tasks and get additional BLOXX! The more tasks you complete, the more upgrades you can make'}
                next={'/boost'}/>

        </Wrapper>

    )
}


const AbsoluteContainer = styled.div`
    position: absolute;
    scale: 0.8;
    left: 16px;
    top: calc(14px + var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto; /* Enable vertical scrolling */
    padding-top: calc(var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto; /* Ensure container can scroll */
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding-bottom: 120px; /* Add padding to ensure the last items are visible */
    padding-top: 54px;
`;


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
`


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

`


interface EarnItemProps {
    channel: string;
    value?: number
    onClick: () => void
    isAvailible?: boolean
}

const EarnItem = ({channel, isAvailible, value, onClick}: EarnItemProps) => {
    return (
        <ThreeDContainer isAvailible={isAvailible} onClick={onClick}>
            <ItemRow>
                <IconContainer>
                    <Gate/>
                </IconContainer>
                <EarnCol>
                    <ChannelText isAvailible={isAvailible}>
                        {channel}
                    </ChannelText>
                    {isAvailible ?
                        <ValueText isAvailible={isAvailible}>
                            +{value} BLOXX
                        </ValueText>
                        : <ValueText isAvailible={isAvailible}>
                            Complete
                        </ValueText>
                    }
                </EarnCol>
                {!isAvailible ? <CheckedIcon/> : <PlusIcon/>}
            </ItemRow>
        </ThreeDContainer>
    )
}


const Gate = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
        <path
            d="M30.2133 10.9059L29.3662 9.42352L28.5192 7.94116L27.6721 9.42352L26.825 10.9059V13.7647H23.3309V10.9059L22.4839 9.42352L21.6368 7.94116L20.7897 9.42352L19.9427 10.9059V13.7647H16.4486V10.9059L15.6015 9.42352L14.7544 7.94116L13.9074 9.42352L13.0603 10.9059V13.7647H9.56621V10.9059L8.71916 9.42352L7.8721 7.94116L7.02504 9.42352L6.17798 10.9059V27.7941H9.61916V24.1941H13.1133V27.7941H16.5545V24.1941H20.0486V27.7941H23.4897V24.1941H26.9839V27.7941H30.425L30.2133 10.9059ZM9.56621 22.447V15.5647H13.0603V22.447H9.56621ZM16.4486 22.447V15.5647H19.9427V22.447H16.4486ZM23.3309 22.447V15.5647H26.825V22.447H23.3309Z"
            fill="black"/>
    </svg>
)

const IconContainer = styled.div`
    border-radius: 6px;
    border: 2px solid #20150E;
    background: #FFFBD6;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    height: 36px;
`


const ThreeDContainer = styled.div<{ isAvailible?: boolean; isTask?: boolean }>`
    display: flex;
    height: 56px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #20150E;
    background: ${({isAvailible}) => isAvailible ? '#494D5B' : '#FFE600'};
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 0, 0.50);
`


const ChannelText = styled.div<{ isAvailible?: boolean }>`
    color: ${({isAvailible}) => isAvailible ? '#FFE600' : '#000'};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    display: flex;
    gap: 4px;

`


const ValueText = styled.div<{ isAvailible?: boolean }>`
    color: ${({isAvailible}) => isAvailible ? '#00C3FF' : '#000'};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
`

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const EarnCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`

const SubTitle = styled.div`
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
`


