import {IconProps} from "../../model";
import styled from "styled-components";

export const PlusIcon = (props: React.HTMLAttributes<HTMLImageElement> & IconProps) => {
    return (
        //@ts-ignore
        <Img src={'/images/plus.png'} alt={'plus'} {...props}/>
    )
}


const Img = styled.img`
    width: 36px;
    height: 36px;
`