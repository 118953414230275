import styled from "styled-components";
import {Area} from "../api/requests/area";
import {PoolIcon} from "./icons/pool";
import {GarageIcon} from "./icons/garage";
import {CoinIcon} from "./icons/coin";
import React from "react";
import {TreeIcon} from "./icons/tree";
import {TerraceIcon} from "./icons/terace";
import {ConditionerIcon} from "./icons/conditioner";


interface Props {
    className?: string;
    area?: Area
    onClick?: (area: Area) => void
    isSeparate?: boolean
    id: string
}

export const AreaField = (props: Props) => {
    return (
        <Container
            onClick={() => {
                props.onClick && props.onClick(props.area!!)
            }}
            className={props.className}>
            <Relative>
                {props?.area?.available_to_claim!! > 0 &&
                    <SCoinIcon/>
                }
                {(props?.area?.building_level!! > 0) &&
                    <Tower src={`/images/roof/${props?.area?.building_level}.png`}/>
                }
                {props.area?.has_pool!! && <SPoolIcon levelColor={'#FF63E4'}/>}
                {props.area?.has_parking!! && <SGarageIcon/>}
                {props.area?.has_terrace!! && <STerraceIcon/>}
                {props.area?.has_conditioner!! && <Conditioner/>}
                {(props?.area?.building_level!! < 1) &&
                    <STreeIcon type={props.id}/>
                }
            </Relative>
        </Container>
    )
}


const Conditioner = styled(ConditionerIcon)`
    position: absolute;
    left: 107px;
    top: 20px;
`


const STerraceIcon = styled(TerraceIcon)`
    position: absolute;
    left: 14px;
    top: 11px;
`


const STreeIcon = styled(TreeIcon)`
    position: absolute;
    left: 72px;
    top: 6px;
`

const Container = styled.div`
    width: 157px;
    height: 63px;
`

const Relative = styled.div`
    position: relative;
`


const Tower = styled.img`
    height: 69px;
    position: absolute;
    left: 51px;
    top: -53px;
`


const SPoolIcon = styled(PoolIcon)`
    height: 35px;
    position: absolute;
    left: 65px;
    top: 15px;
`


const SGarageIcon = styled(GarageIcon)`
    height: 35px;
    position: absolute;
    left: 108px;
    top: -5px;
`

const SCoinIcon = styled(CoinIcon)`
    position: absolute;
    z-index: 1;
    top: -60px;
    left: 55px;
`

