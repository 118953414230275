import React from "react";

export const WBloxIcon = (props:React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
            <path
                d="M71.8312 35.9C71.8312 55.7117 55.743 71.8 35.9313 71.8C16.1195 71.8 0.03125 55.7117 0.03125 35.9C0.03125 16.0883 16.1195 0 35.9313 0C55.743 0 71.8312 16.018 71.8312 35.9Z"
                fill="url(#paint0_radial_6001_7405)"/>
            <path
                d="M69.6534 35.9003C69.6534 54.5177 54.5487 69.6224 35.9313 69.6224C17.3139 69.6224 2.20923 54.5177 2.20923 35.9003C2.20923 17.2829 17.3139 2.17822 35.9313 2.17822C54.5487 2.17822 69.6534 17.2829 69.6534 35.9003Z"
                fill="url(#paint1_linear_6001_7405)"/>
            <path opacity="0.64"
                  d="M35.9314 3.16162C17.876 3.16162 3.26306 17.8448 3.26306 35.9002C3.26306 53.9555 17.9462 68.6387 35.9314 68.6387C53.9867 68.6387 68.6699 53.9555 68.6699 35.9002C68.6699 17.8448 53.9867 3.16162 35.9314 3.16162ZM35.8611 67.2336C18.5785 67.2336 4.52763 53.1827 4.52763 35.9002C4.52763 18.6176 18.5785 4.56671 35.8611 4.56671C53.1437 4.56671 67.1946 18.6176 67.1946 35.9002C67.2648 53.1827 53.1437 67.2336 35.8611 67.2336Z"
                  fill="#1F140F"/>
            <g opacity="0.76">
                <path
                    d="M16.6113 25.2212L24.1988 42.3633L31.716 25.432H38.6712L46.1884 42.3633L53.7759 25.2212H61.3634L49.9822 50.7938H43.027L35.2288 33.8625L27.4305 50.7938H20.4753L9.09412 25.2212H16.6113Z"
                    fill="#704B00"/>
            </g>
            <path
                d="M17.5949 25.2212L25.1824 42.3633L32.6996 25.432H39.6548L47.172 42.3633L54.7595 25.2212H62.347L50.9657 50.7938H44.0106L36.2123 33.8625L28.4141 50.7938H21.3887L10.0074 25.2212H17.5949Z"
                fill="#00C3FF"/>
            <defs>
                <radialGradient id="paint0_radial_6001_7405" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(35.9395 35.876) scale(38.4126)">
                    <stop offset="0.8823" stop-color="#F9ED86"/>
                    <stop offset="0.8916" stop-color="#F7E97C"/>
                    <stop offset="0.9077" stop-color="#F2DD60"/>
                    <stop offset="0.9284" stop-color="#E9CA32"/>
                    <stop offset="0.9487" stop-color="#E0B500"/>
                </radialGradient>
                <linearGradient id="paint1_linear_6001_7405" x1="12.6903" y1="11.5029" x2="59.189" y2="60.2497"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E7FF00"/>
                    <stop offset="0.6682" stop-color="#E0B500"/>
                </linearGradient>
            </defs>
        </svg>
    )
}