import styled, {keyframes} from "styled-components";
import {ColorButton} from "../color-button";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useUserStore} from "../../store/user-store";
import {ModalWrapper} from "../modal-wrapper";

interface OnboardingProps {
    text: string;
    next: string;
    isLast?: boolean;
    onClick?: () => void;
    zIndex?: number;
    hamster?: number;

}

export const OnboardingModal = ({text, hamster, onClick, zIndex, next, isLast}: OnboardingProps) => {
    const [isVisible, setIsVisible] = useState(false)
    const nav = useNavigate()
    const {user} = useUserStore()

    useEffect(() => {
        if (user?.is_onboarding_completed === false) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [user])

    if (!isVisible) {
        return null
    }

    return (
        <ModalWrapper hamster={hamster}>
            <Container>
            <SubTitle>
                {text}
            </SubTitle>
            <ColorButton
                variant={'primary'}
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                    nav(next, {replace: true})
                }}
                text={isLast ? 'Finish' : 'Next'}/>
            </Container>
        </ModalWrapper>

    )
}





const SubTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.078px;
    margin-top: 30px;
    line-height: 20px;
`


const Container = styled.div`
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 16px;
    box-sizing: border-box;

`



