import styled from "styled-components";

interface BorderedTitleProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;

}

export const BorderedTitle = (props: BorderedTitleProps) => {
    return (
        <Text  {...props}>
            {props.title}
        </Text>
    )
}

const Text = styled.div`

    color: #E0E000;
    text-align: center;
    text-shadow: 0px 4px 8px rgba(255, 255, 0, 0.50);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 48px */
    letter-spacing: 1px;
    text-transform: uppercase;
`