import {IconProps} from "../../../model";

export const BoostIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="22" height="28" viewBox="0 0 22 28" fill="none">
            <path
                d="M11.04 0.799805C11.04 0.799805 5.76001 6.55982 5.76001 13.6398C5.76001 18.0798 6.8 22.9998 8.2 26.0398C9.6 25.3198 11.04 24.8398 11.04 24.8398C11.04 24.8398 12.48 25.3198 13.88 26.0398C15.32 22.9998 16.32 18.1198 16.32 13.6398C16.32 6.55982 11.04 0.799805 11.04 0.799805ZM11.04 7.43982C11.68 7.43982 12.24 7.95982 12.24 8.63982C12.24 9.27982 11.72 9.83982 11.04 9.83982C10.4 9.83982 9.83999 9.31982 9.83999 8.63982C9.83999 7.99982 10.36 7.43982 11.04 7.43982ZM11.04 15.4798C9.87999 15.4798 8.96001 14.5598 8.96001 13.3998C8.96001 12.2398 9.87999 11.3198 11.04 11.3198C12.2 11.3198 13.12 12.2398 13.12 13.3998C13.12 14.5598 12.16 15.4798 11.04 15.4798Z"
                fill="black"/>
            <path
                d="M4.47998 19.0796C2.83998 19.9196 1.55998 21.0396 0.599976 22.3196V26.9996C2.39998 25.7196 4.47997 25.3996 6.11997 25.8396C4.87997 23.5996 4.63998 21.4396 4.47998 19.0796Z"
                fill="black"/>
            <path
                d="M21.68 22.5996C20.68 21.2396 19.32 19.9996 17.6 19.1196C17.44 21.4796 17.2 23.6396 15.96 25.8796C17.64 25.3996 19.84 25.7596 21.68 27.1996V22.5996Z"
                fill="black"/>
        </svg>
    )
}