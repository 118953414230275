import {IconProps} from "../../model";

export const TerraceIcon = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="42" height="29" viewBox="0 0 42 29" fill="none">
            <path opacity="0.17"
                  d="M12.8326 28.2385L33.241 28.0362L41.4639 21.6459L32.1343 13.7801L16.6525 12.5425L12.8326 28.2385Z"
                  fill="black"/>
            <path
                d="M27.6243 18.7305L28.005 18.9566L13.0706 27.584L1.57532 20.9439L16.9024 12.0903L27.6243 18.2902V18.7305Z"
                fill="#7F4D3D"/>
            <path d="M28.3859 19.3969L13.0707 28.2385V27.584L28.0051 18.9566L28.3859 18.7305V19.3969Z" fill="#3D221A"/>
            <path d="M13.0706 28.2385L1.57532 21.6102V20.9438L13.0706 27.584V28.2385Z" fill="#5D3021"/>
            <path d="M28.0051 18.9565L27.6243 18.7304V18.2901V11.3644L28.0051 11.5905V18.9565Z" fill="#5D3021"/>
            <path
                d="M28.3858 18.7304L28.005 18.9565V11.5905L28.2787 11.1145L28.005 10.9598V8.55599L28.3858 8.3418V18.7304Z"
                fill="#3D221A"/>
            <path d="M28.3859 8.34182L28.0051 8.55602L27.6243 8.34182L28.0051 8.11572L28.3859 8.34182Z" fill="#7F4D3D"/>
            <path d="M27.6243 8.3418L28.0051 8.55599V10.9598L27.6243 10.7337V9.85309V8.3418Z" fill="#5D3021"/>
            <path d="M25.6488 6.4259L27.6242 9.85309V10.7337L25.2561 6.64011L25.6488 6.4259Z" fill="#936758"/>
            <path d="M25.2562 6.64014L27.6243 10.7337L27.0888 10.4243L24.9944 6.79483L25.2562 6.64014Z" fill="#5D3021"/>
            <path d="M28.2787 11.1145L28.005 11.5905V10.9598L28.2787 11.1145Z" fill="#3D221A"/>
            <path
                d="M28.005 10.9598V11.5905L27.6242 11.3644L25.3751 10.0673L25.6488 9.59131L27.0887 10.4243L27.6242 10.7337L28.005 10.9598Z"
                fill="#3D221A"/>
            <path d="M25.2251 9.39325L24.9514 9.86731L25.3327 10.0875L25.6064 9.6134L25.2251 9.39325Z" fill="#5D3021"/>
            <path
                d="M16.9025 5.17649V4.5458L17.2833 4.3316L19.2586 3.18921L19.5323 3.65331L17.2833 4.9504L16.9025 5.17649Z"
                fill="#5D3021"/>
            <path
                d="M16.9024 4.5458V5.17649L16.5097 4.9504L14.2606 3.65331L14.5343 3.18921L16.5097 4.3316L16.9024 4.5458Z"
                fill="#3D221A"/>
            <path d="M14.1144 2.97455L13.8407 3.44861L14.222 3.66876L14.4957 3.1947L14.1144 2.97455Z" fill="#5D3021"/>
            <path d="M16.9025 1.70166L17.2833 1.92776L16.9025 2.14196L16.5098 1.92776L16.9025 1.70166Z" fill="#7F4D3D"/>
            <path d="M17.2833 4.95032V12.3164L16.9025 12.5425V5.17641L17.2833 4.95032Z" fill="#3D221A"/>
            <path d="M17.2833 1.92773V4.33152L16.9025 4.54572V2.14194L17.2833 1.92773Z" fill="#3D221A"/>
            <path d="M16.9025 12.5425L16.5098 12.3164V4.95032L16.9025 5.17641V12.5425Z" fill="#5D3021"/>
            <path d="M16.5098 1.92773L16.9025 2.14194V4.54572L16.5098 4.33152V1.92773Z" fill="#5D3021"/>
            <path d="M19.5885 2.96723L19.2072 3.18738L19.4809 3.66144L19.8622 3.44129L19.5885 2.96723Z" fill="#936758"/>
            <path d="M14.1417 11.0431L15.1056 11.5072L15.3317 11.6381L14.1179 11.055L14.1417 11.0431Z" fill="#5D3021"/>
            <path
                d="M14.118 11.0551L15.3318 11.6382L15.4627 11.6977L16.6764 12.2807V12.4116L16.5455 12.483L16.2956 12.364L15.3318 11.8999L15.2128 11.8404L14.9629 11.7334L13.9871 11.2693V11.1265L14.118 11.0551Z"
                fill="#3D221A"/>
            <path d="M15.3318 11.638L15.1057 11.5071L16.0696 9.93635L16.5456 9.65076L15.3318 11.638Z" fill="#5D3021"/>
            <path d="M16.6764 12.2807L15.4626 11.6976L15.4745 11.6857L16.4384 12.1498L16.6764 12.2807Z" fill="#5D3021"/>
            <path d="M16.6765 12.2807L16.4385 12.1498V10.0911L16.6765 9.71033V12.2807Z" fill="#5D3021"/>
            <path
                d="M16.6765 9.57947V9.71037L16.4385 10.0912L15.4746 11.6858L15.4627 11.6977L15.3318 11.6382L16.5456 9.65087L16.6765 9.57947Z"
                fill="#3D221A"/>
            <path
                d="M15.2128 11.8405L15.3318 11.9L14.3679 13.4826L14.118 13.8872L13.9871 13.9586V13.8277L15.2128 11.8405Z"
                fill="#3D221A"/>
            <path d="M15.2128 11.8404L13.9871 13.8277V13.3279L14.9629 11.7333L15.2128 11.8404Z" fill="#5D3021"/>
            <path d="M16.5455 12.483L14.1179 13.8872L14.3678 13.4826L16.2956 12.364L16.5455 12.483Z" fill="#7F4D3D"/>
            <path
                d="M13.88 14.1609V11.0669L16.7955 9.38904V12.483L13.88 14.1609ZM13.9871 11.1264V13.9586L14.118 13.8872L16.5456 12.483L16.6765 12.4116V9.57943L16.5456 9.65084L16.0696 9.92453L14.1418 11.0431L14.118 11.055L13.9871 11.1264Z"
                fill="#3D221A"/>
            <path d="M13.88 11.0669V14.1609L13.642 14.03V10.936L13.88 11.0669Z" fill="#5D3021"/>
            <path d="M16.7835 9.38902L13.88 11.0669L13.642 10.936L16.5574 9.24622L16.7835 9.38902Z" fill="#7F4D3D"/>
            <path d="M11.2262 12.7211L12.1901 13.1852L12.4162 13.3161L11.2024 12.7449L11.2262 12.7211Z" fill="#5D3021"/>
            <path
                d="M11.2024 12.7449L12.4162 13.3161L12.5471 13.3756L13.7609 13.9587V14.1015L13.6419 14.1729L13.3801 14.0539L12.4162 13.5898L12.2972 13.5303L12.0592 13.4113L11.0834 12.9472V12.8163L11.2024 12.7449Z"
                fill="#3D221A"/>
            <path d="M12.4162 13.316L12.1901 13.1851L13.154 11.6143L13.6419 11.3406L12.4162 13.316Z" fill="#5D3021"/>
            <path d="M13.7609 13.9586L12.5471 13.3755L12.559 13.3636L13.5348 13.8277L13.7609 13.9586Z" fill="#5D3021"/>
            <path d="M13.7609 13.9586L13.5348 13.8277V11.769L13.7609 11.4001V13.9586Z" fill="#5D3021"/>
            <path
                d="M13.7608 11.2693V11.4002L13.5347 11.7691L12.5589 13.3637L12.547 13.3756L12.4161 13.3161L13.6418 11.3407L13.7608 11.2693Z"
                fill="#3D221A"/>
            <path
                d="M12.2972 13.5303L12.4162 13.5898L11.4523 15.1606L11.2024 15.5771L11.0834 15.6485V15.5176L12.2972 13.5303Z"
                fill="#3D221A"/>
            <path d="M12.2972 13.5303L11.0834 15.5175V15.0058L12.0592 13.4113L12.2972 13.5303Z" fill="#5D3021"/>
            <path d="M13.6419 14.1728L11.2024 15.577L11.4523 15.1605L13.3801 14.0538L13.6419 14.1728Z" fill="#7F4D3D"/>
            <path
                d="M10.9644 15.8388V12.7448L13.8798 11.0669V14.1609L10.9644 15.8388ZM11.0834 12.8162V15.6484L11.2143 15.577L13.6418 14.1728L13.7727 14.1014V11.2692L13.6418 11.3406L13.1658 11.6143L11.2381 12.7329L11.2143 12.7448L11.0834 12.8162Z"
                fill="#3D221A"/>
            <path d="M10.9644 12.7448V15.8388L10.7383 15.7079V12.6139L10.9644 12.7448Z" fill="#5D3021"/>
            <path d="M13.8799 11.0669L10.9644 12.7448L10.7383 12.6139L13.6419 10.936L13.8799 11.0669Z" fill="#7F4D3D"/>
            <path d="M8.31073 14.4108L9.27462 14.8749L9.51262 15.0058L8.29883 14.4227L8.31073 14.4108Z" fill="#5D3021"/>
            <path
                d="M8.29887 14.4227L9.51266 15.0058L9.63166 15.0653L10.8455 15.6484V15.7793L10.7265 15.8507L10.4766 15.7317L9.51266 15.2676L9.38176 15.2081L9.14376 15.0891L8.16797 14.625V14.4941L8.29887 14.4227Z"
                fill="#3D221A"/>
            <path d="M9.51266 15.0057L9.27466 14.8748L10.2386 13.2921L10.7264 13.0184L9.51266 15.0057Z" fill="#5D3021"/>
            <path d="M10.8455 15.6484L9.63171 15.0653L9.64361 15.0415L10.6194 15.5175L10.8455 15.6484Z" fill="#5D3021"/>
            <path d="M10.8455 15.6484L10.6194 15.5175V13.4469L10.8455 13.078V15.6484Z" fill="#5D3021"/>
            <path
                d="M10.8455 12.9471V13.078L10.6194 13.4469L9.6436 15.0415L9.63169 15.0653L9.5127 15.0058L10.7265 13.0185L10.8455 12.9471Z"
                fill="#3D221A"/>
            <path
                d="M9.38176 15.2081L9.51266 15.2676L8.54877 16.8384L8.29887 17.2549L8.16797 17.3263V17.1954L9.38176 15.2081Z"
                fill="#3D221A"/>
            <path d="M9.38176 15.2081L8.16797 17.1954V16.6837L9.14376 15.0891L9.38176 15.2081Z" fill="#5D3021"/>
            <path d="M10.7264 15.8507L8.29883 17.2549L8.54873 16.8384L10.4765 15.7317L10.7264 15.8507Z" fill="#7F4D3D"/>
            <path
                d="M8.04895 17.5286V14.4346L10.9644 12.7567V15.8507L8.04895 17.5286ZM8.16795 14.4941V17.3263L8.29885 17.2549L10.7264 15.8507L10.8573 15.7793V12.9471L10.7264 13.0185L10.2504 13.2922L8.32265 14.4108L8.29885 14.4227L8.16795 14.4941Z"
                fill="#3D221A"/>
            <path d="M8.04897 14.4226V17.5285L7.82288 17.3857V14.2917L8.04897 14.4226Z" fill="#5D3021"/>
            <path d="M10.9645 12.7448L8.04897 14.4227L7.82288 14.2918L10.7384 12.6139L10.9645 12.7448Z" fill="#7F4D3D"/>
            <path d="M5.4071 16.0886L6.37099 16.5527L6.59709 16.6836L5.3833 16.1005L5.4071 16.0886Z" fill="#5D3021"/>
            <path
                d="M5.38334 16.1006L6.59713 16.6837L6.72803 16.7432L7.94182 17.3263V17.4572L7.81093 17.5286L7.56103 17.4096L6.59713 16.9455L6.47813 16.886L6.22823 16.7789L5.25244 16.3029V16.172L5.38334 16.1006Z"
                fill="#3D221A"/>
            <path d="M6.59719 16.6836L6.37109 16.5527L7.33499 14.97L7.81098 14.6963L6.59719 16.6836Z" fill="#5D3021"/>
            <path d="M7.94182 17.3262L6.72803 16.7431L6.73993 16.7312L7.70382 17.1953L7.94182 17.3262Z" fill="#5D3021"/>
            <path d="M7.94186 17.3262L7.70386 17.1953V15.1367L7.94186 14.7559V17.3262Z" fill="#5D3021"/>
            <path
                d="M7.94186 14.625V14.7559L7.70386 15.1367L6.73997 16.7313L6.72807 16.7432L6.59717 16.6837L7.81096 14.6964L7.94186 14.625Z"
                fill="#3D221A"/>
            <path
                d="M6.47813 16.886L6.59713 16.9455L5.63324 18.5282L5.38334 18.9328L5.25244 19.0042V18.8733L6.47813 16.886Z"
                fill="#3D221A"/>
            <path d="M6.47813 16.8859L5.25244 18.8732V18.3615L6.22823 16.7788L6.47813 16.8859Z" fill="#5D3021"/>
            <path d="M7.81088 17.5285L5.3833 18.9327L5.6332 18.5281L7.56099 17.4095L7.81088 17.5285Z" fill="#7F4D3D"/>
            <path
                d="M5.14539 19.2064V16.1125L8.06087 14.4346V17.5286L5.14539 19.2064ZM5.25248 16.172V19.0041L5.38338 18.9327L7.81097 17.5286L7.94187 17.4571V14.625L7.81097 14.6964L7.33497 14.9701L5.40718 16.0887L5.38338 16.1006L5.25248 16.172Z"
                fill="#3D221A"/>
            <path d="M5.14535 16.1124V19.2064L4.90735 19.0755V15.9696L5.14535 16.1124Z" fill="#5D3021"/>
            <path d="M8.04893 14.4226L5.14535 16.1124L4.90735 15.9696L7.82283 14.2917L8.04893 14.4226Z" fill="#7F4D3D"/>
            <path d="M2.49169 17.7666L3.45559 18.2307L3.68169 18.3616L2.4679 17.7785L2.49169 17.7666Z" fill="#5D3021"/>
            <path
                d="M2.46788 17.7786L3.68167 18.3617L3.81257 18.4212L5.02636 19.0043V19.1352L4.90736 19.2066L4.64556 19.0876L3.68167 18.6354L3.56267 18.564L3.32467 18.4569L2.34888 17.9928V17.85L2.46788 17.7786Z"
                fill="#3D221A"/>
            <path d="M3.68167 18.3616L3.45557 18.2307L4.41946 16.6599L4.90736 16.3743L3.68167 18.3616Z" fill="#5D3021"/>
            <path d="M5.02641 19.0042L3.81262 18.4211L3.82452 18.4092L4.80032 18.8733L5.02641 19.0042Z" fill="#5D3021"/>
            <path d="M5.02639 19.0042L4.80029 18.8733V16.8146L5.02639 16.4338V19.0042Z" fill="#5D3021"/>
            <path
                d="M5.02633 16.303V16.4339L4.80023 16.8147L3.82444 18.4093L3.81254 18.4212L3.68164 18.3617L4.90733 16.3744L5.02633 16.303Z"
                fill="#3D221A"/>
            <path
                d="M3.56267 18.564L3.68167 18.6354L2.71777 20.2062L2.46788 20.6108L2.34888 20.6822V20.5513L3.56267 18.564Z"
                fill="#3D221A"/>
            <path d="M3.56267 18.5639L2.34888 20.5512V20.0514L3.32467 18.4568L3.56267 18.5639Z" fill="#5D3021"/>
            <path d="M4.90738 19.2065L2.4679 20.6107L2.71779 20.2061L4.64558 19.0875L4.90738 19.2065Z" fill="#7F4D3D"/>
            <path
                d="M2.22986 20.8844V17.7904L5.14534 16.1125V19.2065L2.22986 20.8844ZM2.34886 17.8499V20.6821L2.47976 20.6107L4.90734 19.2065L5.03824 19.1351V16.3029L4.90734 16.3743L4.43134 16.648L2.50356 17.7666L2.47976 17.7785L2.34886 17.8499Z"
                fill="#3D221A"/>
            <path d="M2.22988 17.7904V20.8844L2.00378 20.7535V17.6595L2.22988 17.7904Z" fill="#5D3021"/>
            <path d="M5.14536 16.1125L2.22988 17.7904L2.00378 17.6595L4.90737 15.9697L5.14536 16.1125Z" fill="#7F4D3D"/>
            <path
                d="M1.57532 20.9439V10.5433L1.95611 10.7694V13.1732L1.68242 13.3279L1.95611 13.792V21.17L1.57532 20.9439Z"
                fill="#5D3021"/>
            <path d="M1.57532 10.5433L1.95611 10.3291L2.33691 10.5433L1.95611 10.7694L1.57532 10.5433Z" fill="#7F4D3D"/>
            <path d="M2.33697 20.9438L1.95618 21.1699V13.792L2.33697 13.5778V20.9438Z" fill="#3D221A"/>
            <path d="M1.95618 10.7694L2.33697 10.5433V12.0665V12.9471L1.95618 13.1732V10.7694Z" fill="#3D221A"/>
            <path d="M1.95607 13.792L1.68237 13.3279L1.95607 13.1732V13.792Z" fill="#5D3021"/>
            <path d="M4.7334 11.5658L4.35205 11.7859L4.62566 12.26L5.00701 12.0399L4.7334 11.5658Z" fill="#936758"/>
            <path
                d="M4.32426 11.8047L4.59796 12.2807L2.33697 13.5778L1.95618 13.792V13.1732L2.33697 12.9471L2.88437 12.6377L4.32426 11.8047Z"
                fill="#5D3021"/>
            <path d="M2.33691 12.0665L4.3242 8.62744L4.705 8.85354L2.33691 12.9471V12.0665Z" fill="#5D3021"/>
            <path d="M2.33691 12.9471L4.705 8.85352L4.9787 9.00821L2.88431 12.6377L2.33691 12.9471Z" fill="#3D221A"/>
            <path d="M13.4514 19.9918V19.3611L15.8195 15.2676L16.0813 15.4223L13.4514 19.9918Z" fill="#3D221A"/>
            <path d="M13.4514 19.3612V19.9919L13.1777 19.8372L13.4514 19.3612Z" fill="#3D221A"/>
            <path
                d="M13.1778 19.8372L13.4515 19.9919V27.3579L13.0707 27.584V17.1835L13.4515 16.9574V18.4806V19.3612L13.1778 19.8372Z"
                fill="#3D221A"/>
            <path
                d="M13.0707 17.1835V27.584L12.6899 27.3579V19.9919L12.9517 19.8372L12.6899 19.3612V18.4806V16.9574L13.0707 17.1835Z"
                fill="#5D3021"/>
            <path d="M13.0707 17.1835L12.6899 16.9574L13.0707 16.7432L13.4515 16.9574L13.0707 17.1835Z" fill="#7F4D3D"/>
            <path d="M13.4514 18.4806L15.4268 15.0415L15.8195 15.2676L13.4514 19.3612V18.4806Z" fill="#5D3021"/>
            <path d="M12.9517 19.8372L12.6899 19.9919V19.3612L12.9517 19.8372Z" fill="#5D3021"/>
            <path d="M12.6899 19.3611V19.9918L10.0481 15.4223L10.3218 15.2676L12.6899 19.3611Z" fill="#5D3021"/>
            <path d="M10.7026 15.0415L12.6899 18.4806V19.3612L10.3218 15.2676L10.7026 15.0415Z" fill="#936758"/>
            <path d="M18.5923 13.0066L18.9731 13.2208L17.8545 13.8753L17.4618 13.6492L18.2591 13.197L18.5923 13.0066Z"
                  fill="#936758"/>
            <path
                d="M18.9731 13.2208L18.5923 13.0066L18.2591 13.197L8.29884 7.4493L7.87044 7.1994V6.8067L7.48964 6.5925L7.15645 6.7829L6.60905 6.46161V6.15221L6.10925 5.55721V5.29541L20.3535 13.5183V13.792L20.0917 13.8039H19.8537V14.1133L19.4134 13.8634L18.9731 13.6135V13.2208Z"
                fill="#5D3021"/>
            <path d="M18.9731 13.2208V13.6135L19.4134 13.8634L18.6161 14.3156L17.8545 13.8753L18.9731 13.2208Z"
                  fill="#3D221A"/>
            <path d="M7.87036 6.80676V7.19946L8.29876 7.44936L7.50146 7.90156L6.73987 7.46126L7.87036 6.80676Z"
                  fill="#3D221A"/>
            <path d="M7.48962 6.59253L7.87042 6.80673L6.73993 7.46122L6.35913 7.23513L7.15643 6.78293L7.48962 6.59253Z"
                  fill="#936758"/>
            <path d="M20.3534 13.7921V13.5184L20.5795 13.3875V13.6493L20.3534 13.7921Z" fill="#3D221A"/>
            <path
                d="M6.10925 5.29545L6.33535 5.16455L8.14414 6.21174L8.52494 6.42594L9.29843 6.86624L19.2587 12.6258L19.6395 12.84L20.4011 13.2803L20.5796 13.3874L20.3535 13.5183L6.10925 5.29545Z"
                fill="#936758"/>
            <path d="M20.0916 13.804V13.9825L19.8536 14.1134V13.804H20.0916Z" fill="#3D221A"/>
            <path
                d="M14.3083 2.65363L13.7609 2.33233V2.02293L13.2611 1.42794V1.16614L27.5053 9.38899V9.65079L27.2435 9.66268L27.0174 9.67459V9.98398L26.5652 9.72218L26.1368 9.47229V9.09149L25.7441 8.87729L25.4109 9.06769L15.4507 3.32002L15.0223 3.07013V2.67743L14.6415 2.46323L14.3083 2.65363Z"
                fill="#5D3021"/>
            <path d="M26.1368 9.09155V9.47235L26.5652 9.72225L25.7679 10.1863L25.0063 9.74605L26.1368 9.09155Z"
                  fill="#3D221A"/>
            <path d="M25.7441 8.87732L26.1368 9.09152L25.0063 9.74601L24.6255 9.51991L25.4109 9.06772L25.7441 8.87732Z"
                  fill="#936758"/>
            <path d="M15.0222 2.67749V3.07019L15.4506 3.32009L14.6652 3.77228L13.8917 3.33199L15.0222 2.67749Z"
                  fill="#3D221A"/>
            <path d="M14.6415 2.46326L15.0223 2.67746L13.8918 3.33195L13.511 3.10586L14.3083 2.65366L14.6415 2.46326Z"
                  fill="#936758"/>
            <path d="M27.5052 9.65087V9.38907L27.7432 9.25818V9.51997L27.5052 9.65087Z" fill="#3D221A"/>
            <path
                d="M13.2611 1.16618L13.4872 1.03528L15.3079 2.07057L15.6887 2.29667L16.4503 2.73697L26.4105 8.48464L26.7913 8.71073L27.5648 9.15103L27.7433 9.25813L27.5053 9.38903L13.2611 1.16618Z"
                fill="#936758"/>
            <path d="M27.2434 9.66272V9.85312L27.0173 9.98402V9.67462L27.2434 9.66272Z" fill="#3D221A"/>
            <path
                d="M8.94136 5.74763L8.39396 5.43822V5.12883L7.89417 4.52193V4.26013L22.1384 12.483V12.7567L21.8766 12.7686L21.6505 12.7805V13.0899L21.1983 12.8281L20.7699 12.5782V12.1974L20.3772 11.9713L20.044 12.1617L10.0838 6.41402L9.65535 6.16412V5.78332L9.27456 5.55722L8.94136 5.74763Z"
                fill="#5D3021"/>
            <path d="M20.3773 11.9713L20.77 12.1974L19.6395 12.84L19.2587 12.6258L20.0441 12.1617L20.3773 11.9713Z"
                  fill="#936758"/>
            <path d="M20.7699 12.1974V12.5782L21.1983 12.8281L20.401 13.2803L19.6394 12.84L20.7699 12.1974Z"
                  fill="#3D221A"/>
            <path d="M9.6554 5.78333V6.16412L10.0838 6.41402L9.2984 6.86622L8.5249 6.42592L9.6554 5.78333Z"
                  fill="#3D221A"/>
            <path d="M9.27466 5.55725L9.65546 5.78335L8.52496 6.42594L8.14417 6.21175L8.94146 5.74765L9.27466 5.55725Z"
                  fill="#936758"/>
            <path d="M22.1384 12.7568V12.4831L22.3764 12.3522V12.6259L22.1384 12.7568Z" fill="#3D221A"/>
            <path
                d="M7.89417 4.26017L8.12026 4.12927L9.94095 5.17646L10.3218 5.39067L11.0833 5.84286L21.0436 11.5905L21.4244 11.8047L22.1979 12.2569L22.3764 12.3521L22.1384 12.483L7.89417 4.26017Z"
                fill="#936758"/>
            <path d="M21.8766 12.7687V12.9472L21.6505 13.09V12.7806L21.8766 12.7687Z" fill="#3D221A"/>
            <path
                d="M5.35957 7.8183L4.81217 7.497V7.18761L4.31238 6.59261V6.33081L18.5685 14.5537V14.8155L18.2948 14.8274L18.0687 14.8393V15.1487L17.6165 14.8869L17.1881 14.637V14.2562L16.8073 14.042L16.4741 14.2324L6.51386 8.4847L6.07357 8.2348V7.8421L5.69277 7.62791L5.35957 7.8183Z"
                fill="#5D3021"/>
            <path d="M16.8073 14.0419L17.188 14.2561L16.0576 14.9106L15.6768 14.6845L16.4741 14.2323L16.8073 14.0419Z"
                  fill="#936758"/>
            <path d="M17.1881 14.2561V14.6369L17.6165 14.8868L16.8311 15.3509L16.0576 14.9106L17.1881 14.2561Z"
                  fill="#3D221A"/>
            <path d="M6.07355 7.84204V8.23474L6.51385 8.48464L5.71655 8.93684L4.95496 8.49654L6.07355 7.84204Z"
                  fill="#3D221A"/>
            <path d="M5.69269 7.62781L6.07349 7.842L4.95489 8.4965L4.5741 8.2704L5.35949 7.8182L5.69269 7.62781Z"
                  fill="#936758"/>
            <path d="M18.5685 14.8154V14.5536L18.7946 14.4227V14.6845L18.5685 14.8154Z" fill="#3D221A"/>
            <path
                d="M4.31238 6.33073L4.55038 6.19983L6.35916 7.23512L6.73996 7.46122L7.50156 7.90152L17.4618 13.6492L17.8545 13.8753L18.6161 14.3156L18.7946 14.4227L18.5685 14.5536L4.31238 6.33073Z"
                fill="#936758"/>
            <path d="M18.2947 14.8273V15.0177L18.0686 15.1486V14.8392L18.2947 14.8273Z" fill="#3D221A"/>
            <path
                d="M10.7264 4.71234L10.179 4.40295V4.09355L9.6792 3.49855V3.22485L23.9353 11.4596V11.7214L23.6616 11.7333L23.4355 11.7452V12.0546L22.9833 11.7928L22.5549 11.5429V11.1621L22.1741 10.936L21.8409 11.1264L11.8807 5.37874L11.4404 5.12884V4.74804L11.0596 4.52194L10.7264 4.71234Z"
                fill="#5D3021"/>
            <path d="M22.5549 11.1621V11.5429L22.9833 11.7928L22.1979 12.2569L21.4244 11.8047L22.5549 11.1621Z"
                  fill="#3D221A"/>
            <path d="M22.1741 10.936L22.5549 11.1621L21.4244 11.8047L21.0436 11.5905L21.8409 11.1264L22.1741 10.936Z"
                  fill="#936758"/>
            <path d="M11.4404 4.74805V5.12885L11.8807 5.37874L11.0834 5.84284L10.3218 5.39065L11.4404 4.74805Z"
                  fill="#3D221A"/>
            <path d="M11.0596 4.52197L11.4404 4.74807L10.3218 5.39067L9.94104 5.17646L10.7264 4.71237L11.0596 4.52197Z"
                  fill="#936758"/>
            <path d="M23.9353 11.7214V11.4596L24.1614 11.3168V11.5905L23.9353 11.7214Z" fill="#3D221A"/>
            <path
                d="M9.6792 3.22489L9.9172 3.09399L11.726 4.14119L12.1068 4.36728L12.8684 4.80758L22.8286 10.5552L23.2213 10.7813L23.9829 11.2216L24.1614 11.3168L23.9353 11.4596L9.6792 3.22489Z"
                fill="#936758"/>
            <path d="M23.6616 11.7333V11.9237L23.4355 12.0546V11.7452L23.6616 11.7333Z" fill="#3D221A"/>
            <path
                d="M3.57454 8.85351L3.02714 8.53221V8.22282L2.52734 7.62782V7.35413L16.7716 15.5889V15.8507L16.5098 15.8626L16.2837 15.8745V16.1839L15.8315 15.9221L15.4031 15.6722V15.2914L15.0104 15.0653L14.6772 15.2676L4.71693 9.50801L4.28853 9.25811V8.87731L3.90773 8.65121L3.57454 8.85351Z"
                fill="#5D3021"/>
            <path d="M15.0104 15.0653L15.4031 15.2914L14.2607 15.9459L13.8799 15.7198L14.6772 15.2676L15.0104 15.0653Z"
                  fill="#936758"/>
            <path d="M15.403 15.2914V15.6722L15.8314 15.9221L15.0341 16.3862L14.2606 15.9459L15.403 15.2914Z"
                  fill="#3D221A"/>
            <path d="M4.28857 8.87732V9.25812L4.71697 9.50801L3.91968 9.97211L3.15808 9.53182L4.28857 8.87732Z"
                  fill="#3D221A"/>
            <path d="M3.90771 8.65125L4.28851 8.87735L3.15802 9.53184L2.77722 9.30574L3.57452 8.85355L3.90771 8.65125Z"
                  fill="#936758"/>
            <path d="M16.7716 15.8507V15.5889L17.0096 15.458V15.7198L16.7716 15.8507Z" fill="#3D221A"/>
            <path
                d="M2.52734 7.35417L2.75344 7.22327L4.57413 8.27046L4.95493 8.49656L5.71652 8.93686L15.6768 14.6845L16.0576 14.9106L16.8311 15.3509L17.0096 15.458L16.7716 15.5889L2.52734 7.35417Z"
                fill="#936758"/>
            <path d="M16.5098 15.8625V16.0529L16.2837 16.1838V15.8744L16.5098 15.8625Z" fill="#3D221A"/>
            <path
                d="M12.5233 3.68897L11.9759 3.36767V3.05827L11.4761 2.46327V2.18958L25.7203 10.4243V10.6861L25.4585 10.698L25.2205 10.7099V11.0193L24.7802 10.7575L24.3399 10.5076V10.1268L23.9591 9.90072L23.6259 10.103L13.6657 4.34346L13.2373 4.09356V3.71276L12.8565 3.48667L12.5233 3.68897Z"
                fill="#5D3021"/>
            <path d="M24.3399 10.1268V10.5076L24.7802 10.7575L23.9829 11.2216L23.2213 10.7813L24.3399 10.1268Z"
                  fill="#3D221A"/>
            <path d="M23.9591 9.90076L24.3399 10.1269L23.2213 10.7814L22.8286 10.5553L23.6259 10.1031L23.9591 9.90076Z"
                  fill="#936758"/>
            <path d="M13.2373 3.71277V4.09357L13.6657 4.34347L12.8684 4.80756L12.1068 4.36726L13.2373 3.71277Z"
                  fill="#3D221A"/>
            <path d="M12.8564 3.48669L13.2372 3.71279L12.1067 4.36729L11.726 4.14119L12.5232 3.68899L12.8564 3.48669Z"
                  fill="#936758"/>
            <path d="M25.7203 10.6862V10.4244L25.9464 10.2935V10.5553L25.7203 10.6862Z" fill="#3D221A"/>
            <path
                d="M11.4761 2.18962L11.7022 2.05872L13.511 3.10591L13.8918 3.33201L14.6653 3.7723L24.6255 9.51997L25.0063 9.74607L25.7679 10.1864L25.9464 10.2935L25.7203 10.4244L11.4761 2.18962Z"
                fill="#936758"/>
            <path d="M25.4585 10.698V10.8884L25.2205 11.0193V10.7099L25.4585 10.698Z" fill="#3D221A"/>
            <path
                d="M1.77766 9.88886L1.23027 9.56756V9.25816L0.730469 8.66317V8.40137L14.9866 16.6242V16.886L14.7129 16.8979L14.4868 16.9098V17.2192L14.0346 16.9574L13.6062 16.7075V16.3267L13.2254 16.1006L13.1659 16.1363L2.49166 9.97216V9.91266L2.11086 9.68656L1.77766 9.88886Z"
                fill="#5D3021"/>
            <path
                d="M13.2254 16.1006L13.6062 16.3267L12.1782 17.1478L11.7974 16.9336L12.0235 16.8027L12.7851 16.3505L13.1659 16.1363L13.2254 16.1006Z"
                fill="#936758"/>
            <path d="M13.6062 16.3267V16.7075L14.0346 16.9574L12.1782 18.0403V17.1478L13.6062 16.3267Z" fill="#3D221A"/>
            <path d="M2.49169 9.9126V9.9721L2.44409 9.93639L2.49169 9.9126Z" fill="#3D221A"/>
            <path
                d="M2.11085 9.68652L2.49165 9.91262L2.44405 9.93642L2.06325 10.1625L1.06366 10.7337L0.682861 10.5195L1.77765 9.88882L2.11085 9.68652Z"
                fill="#936758"/>
            <path d="M14.9866 16.886V16.6242L15.2127 16.4933V16.7551L14.9866 16.886Z" fill="#3D221A"/>
            <path
                d="M0.730469 8.40135L0.956567 8.25854L2.77726 9.30574L3.15805 9.53184L3.91965 9.97214L13.8799 15.7198L14.2607 15.9459L15.0342 16.3862L15.2127 16.4933L14.9866 16.6242L0.730469 8.40135Z"
                fill="#936758"/>
            <path d="M14.7129 16.8979V17.0884L14.4868 17.2192V16.9099L14.7129 16.8979Z" fill="#3D221A"/>
            <path d="M11.7974 16.9336L12.1782 17.1478V18.0403L11.7974 17.8142V16.9336Z" fill="#5D3021"/>
            <path d="M28.1954 7.46121L29.1117 6.93762L29.4925 7.15182L28.5881 7.67541L28.1954 7.46121Z" fill="#936758"/>
            <path d="M29.4925 7.15186V8.03245L29.3497 8.11575L28.5881 7.67545L29.4925 7.15186Z" fill="#3D221A"/>
            <path d="M28.3501 8.69887L27.5648 9.15107L26.7913 8.71077L27.9218 8.05627V8.44897L28.3501 8.69887Z"
                  fill="#3D221A"/>
            <path
                d="M27.9217 8.4489V8.05621L27.5409 7.84201L27.2077 8.0324L17.0452 2.16574L16.8072 2.03485V1.64215L16.4264 1.42795L16.0932 1.61835L15.5458 1.29705V0.987652L15.046 0.392656V0.130859L29.3021 8.3537V8.6274L29.0284 8.63931H28.8023V8.9487L28.3501 8.6988L27.9217 8.4489Z"
                fill="#5D3021"/>
            <path
                d="M27.541 7.84204L27.9218 8.05624L26.7913 8.71073L26.4105 8.48464L27.0055 8.15144L27.2078 8.03244L27.541 7.84204Z"
                fill="#936758"/>
            <path d="M16.8073 1.64221V2.03491L17.0453 2.16581V2.40381L16.4503 2.73701L15.6887 2.29671L16.8073 1.64221Z"
                  fill="#3D221A"/>
            <path d="M17.0453 2.40377V2.16577L27.2078 8.03243L27.0055 8.15143L17.0453 2.40377Z" fill="#5D3021"/>
            <path d="M16.4265 1.42798L16.8073 1.64218L15.6887 2.29667L15.3079 2.07057L16.0933 1.61838L16.4265 1.42798Z"
                  fill="#936758"/>
            <path d="M0.682861 10.5195L1.06366 10.7337V11.6262L0.682861 11.4001V10.5195Z" fill="#5D3021"/>
            <path d="M17.0929 1.04716L17.9973 0.52356L18.3781 0.737757L17.4737 1.26136L17.0929 1.04716Z"
                  fill="#936758"/>
            <path d="M1.06372 11.6262V10.7337L2.06331 10.1625V11.0431L1.06372 11.6262Z" fill="#3D221A"/>
            <path d="M2.06323 11.0431V10.1625L12.7851 16.3504L12.0235 16.8026L2.06323 11.0431Z" fill="#5D3021"/>
            <path d="M18.378 0.737793V1.63029L18.2471 1.70169L17.4736 1.26139L18.378 0.737793Z" fill="#3D221A"/>
            <path d="M29.3021 8.62749V8.3538L29.5282 8.2229V8.4847L29.3021 8.62749Z" fill="#3D221A"/>
            <path
                d="M15.046 0.1309L15.284 0L17.0928 1.04719L17.4736 1.26139L18.2471 1.70169L28.1954 7.46125L28.5881 7.67545L29.3497 8.11575L29.5282 8.22285L29.3021 8.35374L15.046 0.1309Z"
                fill="#936758"/>
            <path d="M29.0285 8.6394V8.8179L28.8024 8.9488V8.6394H29.0285Z" fill="#3D221A"/>
            <path d="M13.1659 16.1363L12.7851 16.3505L2.06323 10.1625L2.44403 9.9364L2.49163 9.97211L13.1659 16.1363Z"
                  fill="#936758"/>
            <path d="M34.7881 16.8383L34.8 19.123L34.9666 19.2182L34.9547 16.9454L34.7881 16.8383Z" fill="#5D3021"/>
            <path d="M34.9547 16.9454L34.9666 19.2182L35.0737 19.1587L35.0618 16.8859L34.9547 16.9454Z" fill="#4B2517"/>
            <path d="M34.7881 16.8383L34.9547 16.9454L35.0618 16.8859L34.8952 16.7788L34.7881 16.8383Z" fill="#936758"/>
            <path d="M34.9547 18.2781V18.4685L36.3351 19.2658V19.0754L34.9547 18.2781Z" fill="#5D3021"/>
            <path d="M36.3351 19.0754V19.2658L36.3827 19.2301V19.0397L36.3351 19.0754Z" fill="#936758"/>
            <path d="M34.9547 18.2781L36.3351 19.0754L36.3827 19.0397L35.0142 18.2424L34.9547 18.2781Z" fill="#936758"/>
            <path d="M34.9547 17.8259V18.0163L36.3351 18.8136V18.6232L34.9547 17.8259Z" fill="#5D3021"/>
            <path d="M36.3351 18.6232V18.8136L36.3827 18.7779V18.5875L36.3351 18.6232Z" fill="#936758"/>
            <path d="M34.9547 17.826L36.3351 18.6233L36.3827 18.5876L35.0142 17.7903L34.9547 17.826Z" fill="#936758"/>
            <path d="M36.2517 17.7189V20.0037L36.4183 20.0989V17.8141L36.2517 17.7189Z" fill="#5D3021"/>
            <path d="M36.4183 17.8141V20.0989L36.5254 20.0394L36.5135 17.7546L36.4183 17.8141Z" fill="#4B2517"/>
            <path d="M36.2517 17.7189L36.4183 17.8141L36.5135 17.7546L36.3469 17.6594L36.2517 17.7189Z" fill="#936758"/>
            <path d="M34.6215 16.3503V17.2071L36.6206 18.3614V17.5046L34.6215 16.3503Z" fill="#5D3021"/>
            <path d="M36.6206 17.5047V18.3615L36.7277 18.3139L36.7158 17.4452L36.6206 17.5047Z" fill="#4B2517"/>
            <path d="M34.6215 16.3504L36.6206 17.5047L36.7158 17.4452L34.7286 16.2909L34.6215 16.3504Z" fill="#936758"/>
            <path
                d="M34.9904 22.8002L34.9785 20.8843C34.9785 20.8962 34.9666 20.9081 34.9547 20.9081L34.9666 22.824C34.9785 22.824 34.9904 22.8121 34.9904 22.8002Z"
                fill="#3D221A"/>
            <mask id="mask0_4086_29278" style={{
                    maskType: 'luminance'
                  }} maskUnits="userSpaceOnUse" x="34" y="20" width="1"
                  height="3">
                <path
                    d="M34.9904 22.8002L34.9785 20.8843C34.9785 20.8962 34.9666 20.9081 34.9547 20.9081L34.9666 22.824C34.9785 22.824 34.9904 22.8121 34.9904 22.8002Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask0_4086_29278)">
                <path
                    d="M34.9666 20.92L34.9785 22.8359C34.9864 22.828 34.9904 22.8201 34.9904 22.8121L34.9785 20.8962C34.9904 20.8962 34.9785 20.9081 34.9666 20.92Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M34.4906 20.9081L34.5025 22.824C34.5025 22.8359 34.5144 22.8478 34.5263 22.8478L34.5144 20.9319L34.4906 20.9081Z"
                fill="#4B2517"/>
            <mask id="mask1_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="34" y="20" width="1"
                  height="3">
                <path
                    d="M34.4906 20.9081L34.5025 22.824C34.5025 22.8359 34.5144 22.8478 34.5263 22.8478L34.5144 20.9319L34.4906 20.9081Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask1_4086_29278)">
                <path
                    d="M34.4906 20.9081L34.5025 22.824C34.5025 22.8359 34.5144 22.8478 34.5263 22.8478L34.5144 20.9319L34.4906 20.9081Z"
                    fill="#4B2517"/>
            </g>
            <path d="M34.5144 20.9319L34.5263 22.8478L34.681 22.943V21.0271L34.5144 20.9319Z" fill="#4B2517"/>
            <path d="M34.7762 21.027L34.7881 22.9429L34.9666 22.8358V20.9199L34.7762 21.027Z" fill="#3D221A"/>
            <path
                d="M34.681 21.0271L34.6929 22.943C34.7167 22.9549 34.7643 22.9549 34.7881 22.943L34.7762 21.0271C34.7524 21.039 34.7048 21.039 34.681 21.0271Z"
                fill="#4B2517"/>
            <mask id="mask2_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="34" y="21" width="1"
                  height="2">
                <path
                    d="M34.681 21.0271L34.6929 22.943C34.7167 22.9549 34.7643 22.9549 34.7881 22.943L34.7762 21.0271C34.7524 21.039 34.7048 21.039 34.681 21.0271Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask2_4086_29278)">
                <path
                    d="M34.681 21.0271L34.6929 22.943C34.7048 22.943 34.7167 22.9549 34.7167 22.9549L34.7048 21.039C34.6929 21.039 34.6929 21.0271 34.681 21.0271Z"
                    fill="#4B2517"/>
                <path
                    d="M34.7048 21.039L34.7167 22.9549C34.7405 22.9549 34.7643 22.9549 34.7881 22.943L34.7762 21.0271C34.7643 21.039 34.7286 21.039 34.7048 21.039Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M34.5144 20.8724C34.4906 20.8843 34.4906 20.92 34.5144 20.9319L34.681 21.0271C34.7048 21.039 34.7524 21.039 34.7762 21.0271L34.9666 20.92C34.9904 20.9081 34.9904 20.8724 34.9666 20.8605L34.8 20.7653C34.7762 20.7534 34.7286 20.7534 34.7048 20.7653L34.5144 20.8724Z"
                fill="#5D3021"/>
            <path
                d="M33.5029 21.9552L33.491 20.0393C33.491 20.0512 33.4791 20.0631 33.4672 20.0631L33.4791 21.979C33.5029 21.9671 33.5029 21.9552 33.5029 21.9552Z"
                fill="#3D221A"/>
            <mask id="mask3_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="33" y="20" width="1"
                  height="2">
                <path
                    d="M33.5029 21.9552L33.491 20.0393C33.491 20.0512 33.4791 20.0631 33.4672 20.0631L33.4791 21.979C33.5029 21.9671 33.5029 21.9552 33.5029 21.9552Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask3_4086_29278)">
                <path
                    d="M33.479 20.0631L33.4909 21.979C33.4988 21.9711 33.5028 21.9631 33.5028 21.9552L33.4909 20.0393C33.5028 20.0393 33.4909 20.0512 33.479 20.0631Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M33.015 20.0513L33.0269 21.9672C33.0269 21.9791 33.0388 21.991 33.0507 21.991L33.0388 20.0751C33.0229 20.0671 33.015 20.0592 33.015 20.0513Z"
                fill="#4B2517"/>
            <mask id="mask4_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="33" y="20" width="1"
                  height="2">
                <path
                    d="M33.015 20.0513L33.0269 21.9672C33.0269 21.9791 33.0388 21.991 33.0507 21.991L33.0388 20.0751C33.0229 20.0671 33.015 20.0592 33.015 20.0513Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask4_4086_29278)">
                <path
                    d="M33.015 20.0513L33.0269 21.9672C33.0269 21.9791 33.0388 21.991 33.0507 21.991L33.0388 20.0751C33.0229 20.0671 33.015 20.0592 33.015 20.0513Z"
                    fill="#4B2517"/>
            </g>
            <path d="M33.0388 20.0751V21.991L33.2054 22.0862L33.1935 20.1703L33.0388 20.0751Z" fill="#4B2517"/>
            <path d="M33.3007 20.1702V22.0861L33.4911 21.979L33.4792 20.0631L33.3007 20.1702Z" fill="#3D221A"/>
            <path
                d="M33.1935 20.1703L33.2054 22.0862C33.2292 22.0981 33.2768 22.0981 33.3006 22.0862L33.2887 20.1703C33.2649 20.1822 33.2173 20.1822 33.1935 20.1703Z"
                fill="#4B2517"/>
            <mask id="mask5_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="33" y="20" width="1"
                  height="3">
                <path
                    d="M33.1935 20.1703L33.2054 22.0862C33.2292 22.0981 33.2768 22.0981 33.3006 22.0862L33.2887 20.1703C33.2649 20.1822 33.2173 20.1822 33.1935 20.1703Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask5_4086_29278)">
                <path
                    d="M33.1935 20.1703L33.2054 22.0862C33.2173 22.0862 33.2292 22.0981 33.2292 22.0981L33.2173 20.1822C33.2173 20.1822 33.2054 20.1703 33.1935 20.1703Z"
                    fill="#4B2517"/>
                <path
                    d="M33.2291 20.1822L33.241 22.0981C33.2648 22.0981 33.2886 22.0981 33.3124 22.0862L33.3005 20.1703C33.2767 20.1822 33.2529 20.1822 33.2291 20.1822Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M33.0388 20.0156C33.015 20.0275 33.015 20.0632 33.0388 20.0751L33.2054 20.1703C33.2292 20.1822 33.2768 20.1822 33.3006 20.1703L33.491 20.0632C33.5148 20.0513 33.5148 20.0156 33.491 20.0037L33.3244 19.9085C33.3006 19.8966 33.253 19.8966 33.2292 19.9085L33.0388 20.0156Z"
                fill="#5D3021"/>
            <path
                d="M36.5493 21.8957L36.5374 19.9799C36.5374 19.9918 36.5254 20.0037 36.5135 20.0037L36.5255 21.9195C36.5374 21.9076 36.5493 21.9076 36.5493 21.8957Z"
                fill="#3D221A"/>
            <mask id="mask6_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="36" y="19" width="1"
                  height="3">
                <path
                    d="M36.5493 21.8957L36.5374 19.9799C36.5374 19.9918 36.5254 20.0037 36.5135 20.0037L36.5255 21.9195C36.5374 21.9076 36.5493 21.9076 36.5493 21.8957Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask6_4086_29278)">
                <path
                    d="M36.5255 20.0037L36.5374 21.9195C36.5453 21.9116 36.5493 21.9037 36.5493 21.8957L36.5374 19.9799C36.5374 19.9799 36.5374 19.9918 36.5255 20.0037Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M36.0494 19.9918L36.0613 21.9077C36.0613 21.9196 36.0732 21.9315 36.0851 21.9315L36.0732 20.0156C36.0613 20.0156 36.0494 20.0037 36.0494 19.9918Z"
                fill="#4B2517"/>
            <mask id="mask7_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="36" y="19" width="1"
                  height="3">
                <path
                    d="M36.0494 19.9918L36.0613 21.9077C36.0613 21.9196 36.0732 21.9315 36.0851 21.9315L36.0732 20.0156C36.0613 20.0156 36.0494 20.0037 36.0494 19.9918Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask7_4086_29278)">
                <path
                    d="M36.0494 19.9918L36.0613 21.9077C36.0613 21.9196 36.0732 21.9315 36.0851 21.9315L36.0732 20.0156C36.0613 20.0156 36.0494 20.0037 36.0494 19.9918Z"
                    fill="#4B2517"/>
            </g>
            <path d="M36.0732 20.0156L36.0851 21.9315L36.2398 22.0267V20.1108L36.0732 20.0156Z" fill="#4B2517"/>
            <path d="M36.3351 20.1108L36.347 22.0266L36.5255 21.9195V20.0037L36.3351 20.1108Z" fill="#3D221A"/>
            <path
                d="M36.2399 20.1108L36.2518 22.0267C36.2756 22.0386 36.3232 22.0386 36.347 22.0267L36.3351 20.1108C36.3113 20.1227 36.2637 20.1227 36.2399 20.1108Z"
                fill="#4B2517"/>
            <mask id="mask8_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="36" y="20" width="1"
                  height="3">
                <path
                    d="M36.2399 20.1108L36.2518 22.0267C36.2756 22.0386 36.3232 22.0386 36.347 22.0267L36.3351 20.1108C36.3113 20.1227 36.2637 20.1227 36.2399 20.1108Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask8_4086_29278)">
                <path
                    d="M36.2399 20.1108L36.2518 22.0267C36.2637 22.0267 36.2756 22.0386 36.2756 22.0386L36.2637 20.1227C36.2518 20.1227 36.2399 20.1227 36.2399 20.1108Z"
                    fill="#4B2517"/>
                <path
                    d="M36.2637 20.1227L36.2756 22.0386C36.2994 22.0386 36.3232 22.0386 36.347 22.0267L36.3351 20.1108C36.3113 20.1227 36.2875 20.1227 36.2637 20.1227Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M36.0733 19.9561C36.0495 19.968 36.0495 20.0037 36.0733 20.0156L36.2399 20.1108C36.2637 20.1227 36.3113 20.1227 36.3351 20.1108L36.5255 20.0037C36.5493 19.9918 36.5493 19.9561 36.5255 19.9442L36.3589 19.849C36.3351 19.8371 36.2875 19.8371 36.2637 19.849L36.0733 19.9561Z"
                fill="#5D3021"/>
            <mask id="mask9_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="36" y="19" width="1"
                  height="2">
                <path
                    d="M36.5493 20.1107V19.9203C36.5493 19.956 36.5255 19.9917 36.4779 20.0155V20.2059C36.5255 20.194 36.5493 20.1583 36.5493 20.1107Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask9_4086_29278)">
                <path
                    d="M36.4779 20.0155V20.2059C36.5255 20.1821 36.5493 20.1464 36.5493 20.1107V19.9203C36.5493 19.956 36.5255 19.9917 36.4779 20.0155Z"
                    fill="#4B2517"/>
            </g>
            <mask id="mask10_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="32" y="19" width="2"
                  height="2">
                <path
                    d="M32.9436 19.9679V20.1583C32.9436 20.194 32.9674 20.2297 33.015 20.2535V20.0631C32.9674 20.0393 32.9436 20.0036 32.9436 19.9679Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask10_4086_29278)">
                <path
                    d="M32.9436 19.9679V20.1583C32.9436 20.194 32.9674 20.2297 33.015 20.2535V20.0631C32.9674 20.0393 32.9436 20.0036 32.9436 19.9679Z"
                    fill="#4B2517"/>
            </g>
            <path d="M33.015 20.0631V20.2654L34.5263 21.146V20.9437L33.015 20.0631Z" fill="#4B2517"/>
            <path d="M34.8713 20.9437V21.146L36.4778 20.2178V20.0155L34.8713 20.9437Z" fill="#4B2517"/>
            <mask id="mask11_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="34" y="20" width="1"
                  height="2">
                <path
                    d="M34.5262 20.9437V21.1341C34.6214 21.1936 34.7761 21.1936 34.8713 21.1341V20.9437C34.7761 21.0032 34.6214 21.0032 34.5262 20.9437Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask11_4086_29278)">
                <path
                    d="M34.5262 20.9437V21.1341C34.6214 21.1936 34.7761 21.1936 34.8713 21.1341V20.9437C34.7761 21.0032 34.6214 21.0032 34.5262 20.9437Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M33.015 19.8727C32.9198 19.9322 32.9198 20.0155 33.015 20.075L34.5382 20.9556C34.6334 21.0151 34.7881 21.0151 34.8833 20.9556L36.4898 20.0274C36.585 19.9679 36.585 19.8846 36.4898 19.8251L34.9666 18.9445C34.8714 18.885 34.7167 18.885 34.6215 18.9445L33.015 19.8727Z"
                fill="#7F4D3D"/>
            <path d="M35.169 23.6808L34.9191 23.5261L36.597 24.49L36.8588 24.6328L35.169 23.6808Z" fill="#524037"/>
            <path d="M34.7881 24.3353L34.5382 24.1925L34.919 23.5261L35.1689 23.6808L34.7881 24.3353Z" fill="#7F4D3D"/>
            <path
                d="M36.466 20.5631L36.8468 20.7773L35.1689 23.6809L34.7881 24.3354L33.1221 27.239L32.7413 27.0129L34.4073 24.1212L34.7881 23.4548L36.466 20.5631Z"
                fill="#3D221A"/>
            <path d="M35.1689 23.6809L36.8587 24.6329L36.4779 25.2993L34.7881 24.3354L35.1689 23.6809Z" fill="#3D221A"/>
            <path d="M32.7413 27.0128L32.4795 26.87L34.1574 23.9664L34.4073 24.1211L32.7413 27.0128Z" fill="#7F4D3D"/>
            <path d="M34.4074 24.1212L34.1575 23.9665L34.5383 23.312L34.7882 23.4548L34.4074 24.1212Z" fill="#7F4D3D"/>
            <path d="M34.7881 23.4548L34.5382 23.312L36.2042 20.4203L36.466 20.5631L34.7881 23.4548Z" fill="#7F4D3D"/>
            <path d="M36.466 20.5631L36.2042 20.4203L36.5969 20.6345L36.8468 20.7773L36.466 20.5631Z" fill="#524037"/>
            <path d="M33.1103 22.5028L34.7882 23.4548L34.4074 24.1212L32.7295 23.1573L33.1103 22.5028Z" fill="#3D221A"/>
            <path d="M32.7295 23.1572L32.4677 23.0144L32.8485 22.348L33.1102 22.5027L32.7295 23.1572Z" fill="#7F4D3D"/>
            <path d="M33.1102 22.5027L32.8484 22.348L34.5382 23.3119L34.7881 23.4547L33.1102 22.5027Z" fill="#524037"/>
            <path d="M27.6481 19.2898L27.3864 19.147L29.0761 20.1109L29.326 20.2537L27.6481 19.2898Z" fill="#524037"/>
            <path d="M27.2674 19.9562L27.0056 19.8015L27.3864 19.147L27.6482 19.2898L27.2674 19.9562Z" fill="#7F4D3D"/>
            <path d="M27.6481 19.2898L29.326 20.2537L28.9452 20.9082L27.2673 19.9562L27.6481 19.2898Z" fill="#5D3021"/>
            <path d="M25.2087 22.6337L24.9469 22.479L26.6248 19.5873L26.8747 19.742L25.2087 22.6337Z" fill="#7F4D3D"/>
            <path d="M26.8747 19.7419L26.6248 19.5872L27.0056 18.9327L27.2555 19.0755L26.8747 19.7419Z" fill="#7F4D3D"/>
            <path d="M27.9814 17.2311L27.0056 18.9328L27.2555 19.0756L28.3265 17.2311H27.9814Z" fill="#7F4D3D"/>
            <path
                d="M28.3265 17.2311L27.2555 19.0756L26.8747 19.742L25.2087 22.6336L25.5895 22.8478L27.2674 19.9562L27.6482 19.2898L28.8263 17.2311H28.3265Z"
                fill="#3D221A"/>
            <path d="M26.1369 18.433V19.0875H25.7323L26.8747 19.742L27.2555 19.0756L26.1369 18.433Z" fill="#5D3021"/>
            <path d="M26.1368 18.433L27.2554 19.0756L27.0055 18.9328L26.1368 18.433Z" fill="#524037"/>
            <path d="M31.3252 14.8748L31.3371 17.1476L31.5037 17.2547V14.97L31.3252 14.8748Z" fill="#5D3021"/>
            <path d="M31.5037 14.97V17.2548L31.6108 17.1953L31.5989 14.9105L31.5037 14.97Z" fill="#4B2517"/>
            <path d="M31.3252 14.8748L31.5037 14.97L31.5989 14.9105L31.4323 14.8153L31.3252 14.8748Z" fill="#936758"/>
            <path d="M31.5037 16.3027V16.4931L32.8722 17.2904V17.1L31.5037 16.3027Z" fill="#5D3021"/>
            <path d="M32.8722 17.1V17.2904L32.9317 17.2547V17.0643L32.8722 17.1Z" fill="#936758"/>
            <path d="M31.5037 16.3028L32.8722 17.1001L32.9317 17.0644L31.5513 16.2671L31.5037 16.3028Z" fill="#936758"/>
            <path d="M31.5037 15.8506V16.041L32.8722 16.8383V16.6479L31.5037 15.8506Z" fill="#5D3021"/>
            <path d="M32.8722 16.6479V16.8383L32.9317 16.8145V16.6241L32.8722 16.6479Z" fill="#936758"/>
            <path d="M31.5037 15.8506L32.8722 16.6479L32.9317 16.6241L31.5513 15.8149L31.5037 15.8506Z" fill="#936758"/>
            <path d="M32.7888 15.7435V18.0283L32.9673 18.1235L32.9554 15.8387L32.7888 15.7435Z" fill="#5D3021"/>
            <path d="M32.9554 15.8388L32.9673 18.1236L33.0625 18.0641V15.7793L32.9554 15.8388Z" fill="#4B2517"/>
            <path d="M32.7888 15.7436L32.9554 15.8388L33.0625 15.7793L32.884 15.6841L32.7888 15.7436Z" fill="#936758"/>
            <path d="M31.1586 14.375L31.1705 15.2318L33.1578 16.398V15.5293L31.1586 14.375Z" fill="#5D3021"/>
            <path d="M33.1577 15.5293V16.398L33.2648 16.3385V15.4817L33.1577 15.5293Z" fill="#4B2517"/>
            <path d="M31.1586 14.3751L33.1578 15.5293L33.2649 15.4817L31.2657 14.3156L31.1586 14.3751Z" fill="#936758"/>
            <path
                d="M31.5275 20.8367L31.5156 18.9208C31.5156 18.9327 31.5037 18.9446 31.4918 18.9446L31.5037 20.8605C31.5196 20.8525 31.5275 20.8446 31.5275 20.8367Z"
                fill="#3D221A"/>
            <mask id="mask12_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="18" width="1"
                  height="3">
                <path
                    d="M31.5275 20.8367L31.5156 18.9208C31.5156 18.9327 31.5037 18.9446 31.4918 18.9446L31.5037 20.8605C31.5196 20.8525 31.5275 20.8446 31.5275 20.8367Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask12_4086_29278)">
                <path
                    d="M31.5037 18.9446L31.5156 20.8605C31.5235 20.8525 31.5275 20.8446 31.5275 20.8367L31.5156 18.9208C31.5275 18.9208 31.5156 18.9327 31.5037 18.9446Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M31.0397 18.9327L31.0516 20.8486C31.0516 20.8605 31.0635 20.8724 31.0754 20.8724L31.0635 18.9565C31.0397 18.9565 31.0397 18.9446 31.0397 18.9327Z"
                fill="#4B2517"/>
            <mask id="mask13_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="18" width="1"
                  height="3">
                <path
                    d="M31.0397 18.9327L31.0516 20.8486C31.0516 20.8605 31.0635 20.8724 31.0754 20.8724L31.0635 18.9565C31.0397 18.9565 31.0397 18.9446 31.0397 18.9327Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask13_4086_29278)">
                <path
                    d="M31.0397 18.9327L31.0516 20.8486C31.0516 20.8605 31.0635 20.8724 31.0754 20.8724L31.0635 18.9565C31.0397 18.9565 31.0397 18.9446 31.0397 18.9327Z"
                    fill="#4B2517"/>
            </g>
            <path d="M31.0515 18.9565L31.0634 20.8724L31.23 20.9676L31.2181 19.0517L31.0515 18.9565Z" fill="#4B2517"/>
            <path d="M31.3134 19.0517L31.3253 20.9676L31.5157 20.8605L31.5038 18.9446L31.3134 19.0517Z" fill="#3D221A"/>
            <path
                d="M31.2181 19.0518L31.23 20.9676C31.2538 20.9795 31.3014 20.9795 31.3252 20.9676L31.3133 19.0518C31.2895 19.0637 31.2419 19.0637 31.2181 19.0518Z"
                fill="#4B2517"/>
            <mask id="mask14_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="19" width="1"
                  height="2">
                <path
                    d="M31.2181 19.0518L31.23 20.9676C31.2538 20.9795 31.3014 20.9795 31.3252 20.9676L31.3133 19.0518C31.2895 19.0637 31.2419 19.0637 31.2181 19.0518Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask14_4086_29278)">
                <path
                    d="M31.2181 19.0518L31.23 20.9676C31.2419 20.9676 31.2538 20.9795 31.2538 20.9795L31.2419 19.0637C31.2419 19.0637 31.23 19.0637 31.2181 19.0518Z"
                    fill="#4B2517"/>
                <path
                    d="M31.2538 19.0637L31.2657 20.9795C31.2895 20.9795 31.3133 20.9795 31.3371 20.9676L31.3252 19.0518C31.3014 19.0637 31.2776 19.0637 31.2538 19.0637Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M31.0515 18.897C31.0277 18.9089 31.0277 18.9446 31.0515 18.9565L31.2181 19.0517C31.2419 19.0636 31.2895 19.0636 31.3133 19.0517L31.5037 18.9446C31.5275 18.9327 31.5275 18.897 31.5037 18.8851L31.3371 18.7899C31.3133 18.778 31.2657 18.778 31.2419 18.7899L31.0515 18.897Z"
                fill="#5D3021"/>
            <path
                d="M30.0519 19.9798L30.04 18.064C30.04 18.0759 30.0281 18.0878 30.0162 18.0878L30.0281 20.0037C30.04 19.9918 30.0519 19.9917 30.0519 19.9798Z"
                fill="#3D221A"/>
            <mask id="mask15_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="30" y="18" width="1"
                  height="3">
                <path
                    d="M30.0519 19.9798L30.04 18.064C30.04 18.0759 30.0281 18.0878 30.0162 18.0878L30.0281 20.0037C30.04 19.9918 30.0519 19.9917 30.0519 19.9798Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask15_4086_29278)">
                <path
                    d="M30.0281 18.0878L30.04 20.0037C30.0479 19.9957 30.0519 19.9878 30.0519 19.9798L30.04 18.064C30.04 18.0719 30.036 18.0798 30.0281 18.0878Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M29.5521 18.0759L29.564 19.9918C29.564 20.0037 29.5759 20.0156 29.5878 20.0156L29.5759 18.0997C29.564 18.0997 29.5521 18.0878 29.5521 18.0759Z"
                fill="#4B2517"/>
            <mask id="mask16_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="29" y="18" width="1"
                  height="3">
                <path
                    d="M29.5521 18.0759L29.564 19.9918C29.564 20.0037 29.5759 20.0156 29.5878 20.0156L29.5759 18.0997C29.564 18.0997 29.5521 18.0878 29.5521 18.0759Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask16_4086_29278)">
                <path
                    d="M29.5521 18.0759L29.564 19.9918C29.564 20.0037 29.5759 20.0156 29.5878 20.0156L29.5759 18.0997C29.564 18.0997 29.5521 18.0878 29.5521 18.0759Z"
                    fill="#4B2517"/>
            </g>
            <path d="M29.5759 18.0997V20.0156L29.7425 20.1108L29.7306 18.1949L29.5759 18.0997Z" fill="#4B2517"/>
            <path d="M29.8378 18.1949V20.1108L30.0282 20.0037L30.0163 18.0878L29.8378 18.1949Z" fill="#3D221A"/>
            <path
                d="M29.7306 18.1949L29.7425 20.1108C29.7663 20.1227 29.8139 20.1227 29.8377 20.1108L29.8258 18.1949C29.802 18.2068 29.7663 18.2068 29.7306 18.1949Z"
                fill="#4B2517"/>
            <mask id="mask17_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="29" y="18" width="1"
                  height="3">
                <path
                    d="M29.7306 18.1949L29.7425 20.1108C29.7663 20.1227 29.8139 20.1227 29.8377 20.1108L29.8258 18.1949C29.802 18.2068 29.7663 18.2068 29.7306 18.1949Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask17_4086_29278)">
                <path
                    d="M29.7306 18.1949L29.7425 20.1108C29.7544 20.1108 29.7663 20.1227 29.7663 20.1227L29.7544 18.2068C29.7544 18.2068 29.7425 18.2068 29.7306 18.1949Z"
                    fill="#4B2517"/>
                <path
                    d="M29.7662 18.2068L29.7781 20.1227C29.8019 20.1227 29.8257 20.1227 29.8495 20.1108L29.8376 18.1949C29.8138 18.2068 29.79 18.2068 29.7662 18.2068Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M29.576 18.0402C29.5522 18.0521 29.5522 18.0878 29.576 18.0997L29.7426 18.1949C29.7664 18.2068 29.814 18.2068 29.8378 18.1949L30.0282 18.0878C30.052 18.0759 30.052 18.0402 30.0282 18.0283L29.8616 17.9331C29.8378 17.9212 29.7902 17.9212 29.7664 17.9331L29.576 18.0402Z"
                fill="#5D3021"/>
            <path
                d="M33.0864 19.9204L33.0745 18.0045C33.0745 18.0164 33.0626 18.0283 33.0507 18.0283L33.0626 19.9442C33.0864 19.9442 33.0864 19.9323 33.0864 19.9204Z"
                fill="#3D221A"/>
            <mask id="mask18_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="33" y="18" width="1"
                  height="2">
                <path
                    d="M33.0864 19.9204L33.0745 18.0045C33.0745 18.0164 33.0626 18.0283 33.0507 18.0283L33.0626 19.9442C33.0864 19.9442 33.0864 19.9323 33.0864 19.9204Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask18_4086_29278)">
                <path
                    d="M33.0625 18.0283L33.0744 19.9442C33.0823 19.9363 33.0863 19.9283 33.0863 19.9204L33.0744 18.0045C33.0863 18.0164 33.0744 18.0164 33.0625 18.0283Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M32.5985 18.0164L32.6104 19.9322C32.6104 19.9441 32.6223 19.956 32.6342 19.956L32.6223 18.0402C32.5985 18.0402 32.5985 18.0283 32.5985 18.0164Z"
                fill="#4B2517"/>
            <mask id="mask19_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="32" y="18" width="1"
                  height="2">
                <path
                    d="M32.5985 18.0164L32.6104 19.9322C32.6104 19.9441 32.6223 19.956 32.6342 19.956L32.6223 18.0402C32.5985 18.0402 32.5985 18.0283 32.5985 18.0164Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask19_4086_29278)">
                <path
                    d="M32.5985 18.0164L32.6104 19.9322C32.6104 19.9441 32.6223 19.956 32.6342 19.956L32.6223 18.0402C32.5985 18.0402 32.5985 18.0283 32.5985 18.0164Z"
                    fill="#4B2517"/>
            </g>
            <path d="M32.6104 18.0402L32.6223 19.956L32.7769 20.0512V18.1354L32.6104 18.0402Z" fill="#4B2517"/>
            <path d="M32.8722 18.1354L32.8841 20.0513L33.0626 19.9442V18.0283L32.8722 18.1354Z" fill="#3D221A"/>
            <path
                d="M32.777 18.1354L32.7889 20.0513C32.8127 20.0632 32.8603 20.0632 32.8841 20.0513L32.8722 18.1354C32.8484 18.1592 32.8008 18.1592 32.777 18.1354Z"
                fill="#4B2517"/>
            <mask id="mask20_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="32" y="18" width="1"
                  height="3">
                <path
                    d="M32.777 18.1354L32.7889 20.0513C32.8127 20.0632 32.8603 20.0632 32.8841 20.0513L32.8722 18.1354C32.8484 18.1592 32.8008 18.1592 32.777 18.1354Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask20_4086_29278)">
                <path
                    d="M32.777 18.1354L32.7889 20.0513C32.8008 20.0513 32.8127 20.0632 32.8127 20.0632L32.8008 18.1473C32.7928 18.1473 32.7849 18.1433 32.777 18.1354Z"
                    fill="#4B2517"/>
                <path
                    d="M32.8008 18.1473L32.8127 20.0632C32.8365 20.0632 32.8603 20.0632 32.8841 20.0513L32.8722 18.1354C32.8603 18.1473 32.8246 18.1592 32.8008 18.1473Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M32.6104 17.9926C32.5866 18.0045 32.5866 18.0402 32.6104 18.0521L32.777 18.1473C32.8008 18.1592 32.8484 18.1592 32.8722 18.1473L33.0626 18.0402C33.0864 18.0283 33.0864 17.9926 33.0626 17.9807L32.896 17.8855C32.8722 17.8736 32.8246 17.8736 32.8008 17.8855L32.6104 17.9926Z"
                fill="#5D3021"/>
            <mask id="mask21_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="33" y="17" width="1"
                  height="2">
                <path
                    d="M33.0864 18.1473V17.9569C33.0864 17.9926 33.0626 18.0283 33.015 18.0521V18.2425C33.0626 18.2187 33.0864 18.183 33.0864 18.1473Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask21_4086_29278)">
                <path
                    d="M33.015 18.0521V18.2425C33.0626 18.2187 33.0864 18.183 33.0864 18.1473V17.9569C33.0864 17.9807 33.0626 18.0164 33.015 18.0521Z"
                    fill="#4B2517"/>
            </g>
            <mask id="mask22_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="29" y="17" width="1"
                  height="2">
                <path
                    d="M29.4807 17.9926V18.183C29.4807 18.2187 29.5045 18.2544 29.5521 18.2782V18.0878C29.5045 18.064 29.4807 18.0283 29.4807 17.9926Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask22_4086_29278)">
                <path
                    d="M29.4807 17.9926V18.183C29.4807 18.2187 29.5045 18.2544 29.5521 18.2782V18.0878C29.5045 18.064 29.4807 18.0283 29.4807 17.9926Z"
                    fill="#4B2517"/>
            </g>
            <path d="M29.5521 18.0878V18.2901L31.0634 19.1707V18.9803L29.5521 18.0878Z" fill="#4B2517"/>
            <path d="M31.4084 18.9803V19.1707L33.0149 18.2425V18.0521L31.4084 18.9803Z" fill="#4B2517"/>
            <mask id="mask23_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="18" width="1"
                  height="2">
                <path
                    d="M31.0634 18.9802V19.1706C31.1586 19.2301 31.3133 19.2301 31.4085 19.1706V18.9802C31.3133 19.0278 31.1586 19.0278 31.0634 18.9802Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask23_4086_29278)">
                <path
                    d="M31.0634 18.9802V19.1706C31.1586 19.2301 31.3133 19.2301 31.4085 19.1706V18.9802C31.3133 19.0278 31.1586 19.0278 31.0634 18.9802Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M29.5521 17.8974C29.4569 17.9569 29.4569 18.0402 29.5521 18.0997L31.0753 18.9803C31.1705 19.0398 31.3252 19.0398 31.4204 18.9803L33.0269 18.0521C33.1221 17.9926 33.1221 17.9093 33.0269 17.8498L31.5037 16.9692C31.4085 16.9097 31.2538 16.9097 31.1586 16.9692L29.5521 17.8974Z"
                fill="#7F4D3D"/>
            <path
                d="M34.7881 20.9082C34.919 20.8368 35.0975 20.813 35.1808 20.8606C35.2641 20.9082 35.2284 21.0153 35.0975 21.0867C34.9666 21.1581 34.7881 21.1819 34.7048 21.1343C34.6215 21.0867 34.6572 20.9796 34.7881 20.9082Z"
                fill="#8C879F"/>
            <path
                d="M35.1333 20.9319C35.0976 20.9081 35.0738 20.8962 35.0381 20.8724C35.2761 20.7653 35.4427 20.6226 35.6688 20.5036C35.7045 20.4917 35.7521 20.4917 35.7759 20.5036C35.7997 20.5155 35.7997 20.5393 35.7759 20.5631C35.5855 20.7059 35.3237 20.801 35.1333 20.9319Z"
                fill="#8C879F"/>
            <path
                d="M34.8119 20.92C34.919 20.8605 35.0618 20.8367 35.1213 20.8843C35.1927 20.92 35.157 21.0033 35.0499 21.0628C34.9428 21.1223 34.8 21.1461 34.7405 21.0985C34.681 21.0628 34.7167 20.9914 34.8119 20.92Z"
                fill="#767288"/>
            <path
                d="M26.8746 24.0377L26.8627 22.3361C26.8627 22.348 26.8508 22.3599 26.8389 22.3599L26.8508 24.0615C26.8627 24.0615 26.8746 24.0496 26.8746 24.0377Z"
                fill="#3D221A"/>
            <mask id="mask24_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="26" y="22" width="1"
                  height="3">
                <path
                    d="M26.8746 24.0377L26.8627 22.3361C26.8627 22.348 26.8508 22.3599 26.8389 22.3599L26.8508 24.0615C26.8627 24.0615 26.8746 24.0496 26.8746 24.0377Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask24_4086_29278)">
                <path
                    d="M26.8508 22.3718L26.8627 24.0735C26.8707 24.0656 26.8746 24.0576 26.8746 24.0497L26.8627 22.348C26.8746 22.3599 26.8627 22.3599 26.8508 22.3718Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M26.3748 22.3599L26.3867 24.0616C26.3867 24.0735 26.3986 24.0854 26.4105 24.0854L26.3986 22.3837C26.3867 22.3837 26.3748 22.3718 26.3748 22.3599Z"
                fill="#4B2517"/>
            <mask id="mask25_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="26" y="22" width="1"
                  height="3">
                <path
                    d="M26.3748 22.3599L26.3867 24.0616C26.3867 24.0735 26.3986 24.0854 26.4105 24.0854L26.3986 22.3837C26.3867 22.3837 26.3748 22.3718 26.3748 22.3599Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask25_4086_29278)">
                <path
                    d="M26.3748 22.3599L26.3867 24.0616C26.3867 24.0735 26.3986 24.0854 26.4105 24.0854L26.3986 22.3837C26.3867 22.3837 26.3748 22.3718 26.3748 22.3599Z"
                    fill="#4B2517"/>
            </g>
            <path d="M26.3986 22.3837L26.4105 24.0854L26.5652 24.1806V22.4789L26.3986 22.3837Z" fill="#4B2517"/>
            <path d="M26.6604 22.4789L26.6723 24.1806L26.8508 24.0735V22.3718L26.6604 22.4789Z" fill="#3D221A"/>
            <path
                d="M26.5652 22.4789L26.5771 24.1806C26.6009 24.1925 26.6485 24.1925 26.6723 24.1806L26.6604 22.4789C26.6366 22.5027 26.589 22.5027 26.5652 22.4789Z"
                fill="#4B2517"/>
            <mask id="mask26_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="26" y="22" width="1"
                  height="3">
                <path
                    d="M26.5652 22.4789L26.5771 24.1806C26.6009 24.1925 26.6485 24.1925 26.6723 24.1806L26.6604 22.4789C26.6366 22.5027 26.589 22.5027 26.5652 22.4789Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask26_4086_29278)">
                <path
                    d="M26.5652 22.4789L26.5771 24.1806C26.589 24.1806 26.6009 24.1925 26.6009 24.1925L26.589 22.4908C26.5811 22.4908 26.5731 22.4868 26.5652 22.4789Z"
                    fill="#4B2517"/>
                <path
                    d="M26.589 22.4908L26.6009 24.1925C26.6247 24.1925 26.6485 24.1925 26.6723 24.1806L26.6604 22.4789C26.6485 22.4908 26.6128 22.5027 26.589 22.4908Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M26.3986 22.3361C26.3748 22.348 26.3748 22.3837 26.3986 22.3956L26.5652 22.4908C26.589 22.5027 26.6366 22.5027 26.6604 22.4908L26.8508 22.3837C26.8746 22.3718 26.8746 22.3361 26.8508 22.3242L26.6842 22.229C26.6604 22.2171 26.6128 22.2171 26.589 22.229L26.3986 22.3361Z"
                fill="#5D3021"/>
            <path
                d="M25.387 23.1929L25.3751 21.4912C25.3751 21.5031 25.3632 21.515 25.3513 21.515L25.3632 23.2167C25.387 23.2048 25.387 23.1929 25.387 23.1929Z"
                fill="#3D221A"/>
            <mask id="mask27_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="25" y="21" width="1"
                  height="3">
                <path
                    d="M25.387 23.1929L25.3751 21.4912C25.3751 21.5031 25.3632 21.515 25.3513 21.515L25.3632 23.2167C25.387 23.2048 25.387 23.1929 25.387 23.1929Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask27_4086_29278)">
                <path
                    d="M25.3632 21.515L25.3751 23.2167C25.383 23.2088 25.387 23.2008 25.387 23.1929L25.3751 21.4912C25.387 21.5031 25.3751 21.515 25.3632 21.515Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M24.8992 21.5031L24.9111 23.2047C24.9111 23.2166 24.923 23.2285 24.9349 23.2285L24.923 21.5269C24.8992 21.5269 24.8992 21.515 24.8992 21.5031Z"
                fill="#4B2517"/>
            <mask id="mask28_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="24" y="21" width="1"
                  height="3">
                <path
                    d="M24.8992 21.5031L24.9111 23.2047C24.9111 23.2166 24.923 23.2285 24.9349 23.2285L24.923 21.5269C24.8992 21.5269 24.8992 21.515 24.8992 21.5031Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask28_4086_29278)">
                <path
                    d="M24.8992 21.5031L24.9111 23.2047C24.9111 23.2166 24.923 23.2285 24.9349 23.2285L24.923 21.5269C24.8992 21.5269 24.8992 21.515 24.8992 21.5031Z"
                    fill="#4B2517"/>
            </g>
            <path d="M24.923 21.5269V23.2285L25.0896 23.3237L25.0777 21.6221L24.923 21.5269Z" fill="#4B2517"/>
            <path d="M25.1848 21.6221V23.3238L25.3752 23.2167L25.3633 21.515L25.1848 21.6221Z" fill="#3D221A"/>
            <path
                d="M25.0776 21.6221L25.0895 23.3238C25.1133 23.3357 25.1609 23.3357 25.1847 23.3238L25.1728 21.6221C25.149 21.6459 25.1014 21.6459 25.0776 21.6221Z"
                fill="#4B2517"/>
            <mask id="mask29_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="25" y="21" width="1"
                  height="3">
                <path
                    d="M25.0776 21.6221L25.0895 23.3238C25.1133 23.3357 25.1609 23.3357 25.1847 23.3238L25.1728 21.6221C25.149 21.6459 25.1014 21.6459 25.0776 21.6221Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask29_4086_29278)">
                <path
                    d="M25.0776 21.6221L25.0895 23.3238C25.1014 23.3238 25.1133 23.3357 25.1133 23.3357L25.1014 21.634C25.1014 21.634 25.0895 21.634 25.0776 21.6221Z"
                    fill="#4B2517"/>
                <path
                    d="M25.1133 21.634L25.1252 23.3357C25.149 23.3357 25.1728 23.3357 25.1966 23.3238L25.1847 21.6221C25.1609 21.634 25.1371 21.6459 25.1133 21.634Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M24.923 21.4793C24.8992 21.4912 24.8992 21.5269 24.923 21.5388L25.0896 21.634C25.1134 21.6459 25.161 21.6459 25.1848 21.634L25.3752 21.5269C25.399 21.515 25.399 21.4793 25.3752 21.4674L25.2086 21.3722C25.1848 21.3603 25.1372 21.3603 25.1134 21.3722L24.923 21.4793Z"
                fill="#5D3021"/>
            <path
                d="M28.4335 23.1335L28.4216 21.4318C28.4216 21.4437 28.4097 21.4556 28.3978 21.4556L28.4097 23.1573C28.4216 23.1454 28.4335 23.1454 28.4335 23.1335Z"
                fill="#3D221A"/>
            <mask id="mask30_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="21" width="1"
                  height="3">
                <path
                    d="M28.4335 23.1335L28.4216 21.4318C28.4216 21.4437 28.4097 21.4556 28.3978 21.4556L28.4097 23.1573C28.4216 23.1454 28.4335 23.1454 28.4335 23.1335Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask30_4086_29278)">
                <path
                    d="M28.4097 21.4556L28.4216 23.1573C28.4295 23.1493 28.4335 23.1414 28.4335 23.1335L28.4216 21.4318C28.4216 21.4437 28.4216 21.4556 28.4097 21.4556Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M27.9337 21.4436L27.9456 23.1453C27.9456 23.1572 27.9575 23.1691 27.9694 23.1691L27.9575 21.4674C27.9456 21.4674 27.9337 21.4555 27.9337 21.4436Z"
                fill="#4B2517"/>
            <mask id="mask31_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="27" y="21" width="1"
                  height="3">
                <path
                    d="M27.9337 21.4436L27.9456 23.1453C27.9456 23.1572 27.9575 23.1691 27.9694 23.1691L27.9575 21.4674C27.9456 21.4674 27.9337 21.4555 27.9337 21.4436Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask31_4086_29278)">
                <path
                    d="M27.9337 21.4436L27.9456 23.1453C27.9456 23.1572 27.9575 23.1691 27.9694 23.1691L27.9575 21.4674C27.9456 21.4674 27.9337 21.4555 27.9337 21.4436Z"
                    fill="#4B2517"/>
            </g>
            <path d="M27.9575 21.4794L27.9694 23.1692L28.1241 23.2644V21.5746L27.9575 21.4794Z" fill="#4B2517"/>
            <path d="M28.2194 21.5745L28.2313 23.2643L28.4098 23.1572V21.4674L28.2194 21.5745Z" fill="#3D221A"/>
            <path
                d="M28.1241 21.5746L28.136 23.2763C28.1598 23.2882 28.2074 23.2882 28.2312 23.2763L28.2193 21.5746C28.1955 21.5865 28.1479 21.5865 28.1241 21.5746Z"
                fill="#4B2517"/>
            <mask id="mask32_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="21" width="1"
                  height="3">
                <path
                    d="M28.1241 21.5746L28.136 23.2763C28.1598 23.2882 28.2074 23.2882 28.2312 23.2763L28.2193 21.5746C28.1955 21.5865 28.1479 21.5865 28.1241 21.5746Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask32_4086_29278)">
                <path
                    d="M28.1241 21.5746L28.136 23.2763C28.1479 23.2763 28.1598 23.2882 28.1598 23.2882L28.1479 21.5865C28.136 21.5746 28.1241 21.5746 28.1241 21.5746Z"
                    fill="#4B2517"/>
                <path
                    d="M28.1479 21.5745L28.1598 23.2762C28.1836 23.2762 28.2074 23.2762 28.2312 23.2643L28.2193 21.5626C28.1955 21.5745 28.1717 21.5864 28.1479 21.5745Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M27.9575 21.4199C27.9337 21.4318 27.9337 21.4675 27.9575 21.4794L28.1241 21.5746C28.1479 21.5865 28.1955 21.5865 28.2193 21.5746L28.4097 21.4675C28.4335 21.4556 28.4335 21.4199 28.4097 21.408L28.2431 21.3128C28.2193 21.3009 28.1717 21.3009 28.1479 21.3128L27.9575 21.4199Z"
                fill="#5D3021"/>
            <mask id="mask33_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="21" width="1"
                  height="1">
                <path
                    d="M28.4216 21.5746V21.3842C28.4216 21.4199 28.3978 21.4556 28.3502 21.4794V21.6698C28.3978 21.646 28.4216 21.6103 28.4216 21.5746Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask33_4086_29278)">
                <path
                    d="M28.3502 21.4794V21.6698C28.3978 21.646 28.4216 21.6103 28.4216 21.5746V21.3842C28.4216 21.408 28.3978 21.4437 28.3502 21.4794Z"
                    fill="#4B2517"/>
            </g>
            <mask id="mask34_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="24" y="21" width="1"
                  height="1">
                <path
                    d="M24.8159 21.4198V21.6102C24.8159 21.6459 24.8397 21.6816 24.8873 21.7054V21.515C24.8397 21.4912 24.8159 21.4555 24.8159 21.4198Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask34_4086_29278)">
                <path
                    d="M24.8159 21.4198V21.6102C24.8159 21.6459 24.8397 21.6816 24.8873 21.7054V21.515C24.8397 21.4912 24.8159 21.4555 24.8159 21.4198Z"
                    fill="#4B2517"/>
            </g>
            <path d="M24.8873 21.515V21.7173L26.3986 22.5979V22.4075L24.8873 21.515Z" fill="#4B2517"/>
            <path d="M26.7437 22.4076V22.598L28.3501 21.6698V21.4794L26.7437 22.4076Z" fill="#4B2517"/>
            <mask id="mask35_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="26" y="22" width="1"
                  height="1">
                <path
                    d="M26.3986 22.4075V22.5979C26.4938 22.6574 26.6485 22.6574 26.7437 22.5979V22.4075C26.6485 22.4551 26.4938 22.4551 26.3986 22.4075Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask35_4086_29278)">
                <path
                    d="M26.3986 22.4075V22.5979C26.4938 22.6574 26.6485 22.6574 26.7437 22.5979V22.4075C26.6485 22.4551 26.4938 22.4551 26.3986 22.4075Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M24.8873 21.3246C24.7921 21.3841 24.7921 21.4674 24.8873 21.5269L26.4105 22.4075C26.5057 22.467 26.6604 22.467 26.7556 22.4075L28.3621 21.4793C28.4573 21.4198 28.4573 21.3365 28.3621 21.277L26.8389 20.3964C26.7437 20.3369 26.589 20.3369 26.4938 20.3964L24.8873 21.3246Z"
                fill="#7F4D3D"/>
            <path
                d="M30.2424 26.7747L30.2305 25.073C30.2305 25.0849 30.2186 25.0968 30.2067 25.0968L30.2186 26.7985C30.2305 26.7985 30.2424 26.7866 30.2424 26.7747Z"
                fill="#3D221A"/>
            <mask id="mask36_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="30" y="25" width="1"
                  height="2">
                <path
                    d="M30.2424 26.7747L30.2305 25.073C30.2305 25.0849 30.2186 25.0968 30.2067 25.0968L30.2186 26.7985C30.2305 26.7985 30.2424 26.7866 30.2424 26.7747Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask36_4086_29278)">
                <path
                    d="M30.2185 25.0968L30.2304 26.7985C30.2383 26.7906 30.2423 26.7826 30.2423 26.7747L30.2304 25.073C30.2304 25.0849 30.2304 25.0968 30.2185 25.0968Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M29.7426 25.0848L29.7545 26.7865C29.7545 26.7984 29.7664 26.8103 29.7783 26.8103L29.7664 25.1086C29.7545 25.1086 29.7426 25.0967 29.7426 25.0848Z"
                fill="#4B2517"/>
            <mask id="mask37_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="29" y="25" width="1"
                  height="2">
                <path
                    d="M29.7426 25.0848L29.7545 26.7865C29.7545 26.7984 29.7664 26.8103 29.7783 26.8103L29.7664 25.1086C29.7545 25.1086 29.7426 25.0967 29.7426 25.0848Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask37_4086_29278)">
                <path
                    d="M29.7426 25.0848L29.7545 26.7865C29.7545 26.7984 29.7664 26.8103 29.7783 26.8103L29.7664 25.1086C29.7545 25.1086 29.7426 25.0967 29.7426 25.0848Z"
                    fill="#4B2517"/>
            </g>
            <path d="M29.7664 25.1086V26.8222L29.933 26.9174L29.9211 25.2038L29.7664 25.1086Z" fill="#4B2517"/>
            <path d="M30.0282 25.2039V26.9175L30.2186 26.8104L30.2067 25.0968L30.0282 25.2039Z" fill="#3D221A"/>
            <path
                d="M29.921 25.2039L29.9329 26.9055C29.9567 26.9174 30.0043 26.9174 30.0281 26.9055L30.0162 25.2039C29.9924 25.2277 29.9567 25.2277 29.921 25.2039Z"
                fill="#4B2517"/>
            <mask id="mask38_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="29" y="25" width="2"
                  height="2">
                <path
                    d="M29.921 25.2039L29.9329 26.9055C29.9567 26.9174 30.0043 26.9174 30.0281 26.9055L30.0162 25.2039C29.9924 25.2277 29.9567 25.2277 29.921 25.2039Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask38_4086_29278)">
                <path
                    d="M29.921 25.2039L29.9329 26.9055C29.9448 26.9055 29.9567 26.9174 29.9567 26.9174L29.9448 25.2158C29.9448 25.2158 29.9329 25.2158 29.921 25.2039Z"
                    fill="#4B2517"/>
                <path
                    d="M29.9567 25.2158L29.9686 26.9174C29.9924 26.9174 30.0162 26.9174 30.04 26.9055L30.0281 25.2039C30.0043 25.2158 29.9805 25.2277 29.9567 25.2158Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M29.7664 25.0611C29.7426 25.073 29.7426 25.1087 29.7664 25.1206L29.933 25.2158C29.9568 25.2277 30.0044 25.2277 30.0282 25.2158L30.2186 25.1087C30.2424 25.0968 30.2424 25.0611 30.2186 25.0492L30.052 24.954C30.0282 24.9421 29.9806 24.9421 29.9568 24.954L29.7664 25.0611Z"
                fill="#5D3021"/>
            <path
                d="M28.7549 25.9179L28.743 24.2162C28.743 24.2281 28.7311 24.24 28.7192 24.24L28.7311 25.9417C28.743 25.9417 28.7549 25.9298 28.7549 25.9179Z"
                fill="#3D221A"/>
            <mask id="mask39_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="24" width="1"
                  height="2">
                <path
                    d="M28.7549 25.9179L28.743 24.2162C28.743 24.2281 28.7311 24.24 28.7192 24.24L28.7311 25.9417C28.743 25.9417 28.7549 25.9298 28.7549 25.9179Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask39_4086_29278)">
                <path
                    d="M28.7311 24.24L28.743 25.9417C28.7509 25.9337 28.7549 25.9258 28.7549 25.9179L28.743 24.2162C28.7549 24.2281 28.743 24.24 28.7311 24.24Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M28.2551 24.228L28.267 25.9297C28.267 25.9416 28.2789 25.9535 28.2908 25.9535L28.2789 24.2518C28.267 24.2518 28.2551 24.2399 28.2551 24.228Z"
                fill="#4B2517"/>
            <mask id="mask40_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="24" width="1"
                  height="2">
                <path
                    d="M28.2551 24.228L28.267 25.9297C28.267 25.9416 28.2789 25.9535 28.2908 25.9535L28.2789 24.2518C28.267 24.2518 28.2551 24.2399 28.2551 24.228Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask40_4086_29278)">
                <path
                    d="M28.2551 24.228L28.267 25.9297C28.267 25.9416 28.2789 25.9535 28.2908 25.9535L28.2789 24.2518C28.267 24.2518 28.2551 24.2399 28.2551 24.228Z"
                    fill="#4B2517"/>
            </g>
            <path d="M28.2789 24.2518L28.2908 25.9654L28.4455 26.0606V24.347L28.2789 24.2518Z" fill="#4B2517"/>
            <path d="M28.5408 24.3471L28.5527 26.0607L28.7312 25.9536V24.24L28.5408 24.3471Z" fill="#3D221A"/>
            <path
                d="M28.4456 24.347L28.4575 26.0487C28.4813 26.0606 28.5289 26.0606 28.5527 26.0487L28.5408 24.347C28.517 24.3708 28.4694 24.3708 28.4456 24.347Z"
                fill="#4B2517"/>
            <mask id="mask41_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="24" width="1"
                  height="3">
                <path
                    d="M28.4456 24.347L28.4575 26.0487C28.4813 26.0606 28.5289 26.0606 28.5527 26.0487L28.5408 24.347C28.517 24.3708 28.4694 24.3708 28.4456 24.347Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask41_4086_29278)">
                <path
                    d="M28.4456 24.347L28.4575 26.0487C28.4694 26.0487 28.4813 26.0606 28.4813 26.0606L28.4694 24.3589C28.4614 24.3589 28.4535 24.355 28.4456 24.347Z"
                    fill="#4B2517"/>
                <path
                    d="M28.4694 24.3589L28.4813 26.0606C28.5051 26.0606 28.5289 26.0606 28.5527 26.0487L28.5408 24.347C28.5289 24.3589 28.4932 24.3708 28.4694 24.3589Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M28.279 24.2043C28.2552 24.2162 28.2552 24.2519 28.279 24.2638L28.4456 24.359C28.4694 24.3709 28.517 24.3709 28.5408 24.359L28.7312 24.2519C28.755 24.24 28.755 24.2043 28.7312 24.1924L28.5646 24.0972C28.5408 24.0853 28.4932 24.0853 28.4694 24.0972L28.279 24.2043Z"
                fill="#5D3021"/>
            <path
                d="M31.8012 25.8703L31.7893 24.1686C31.7893 24.1805 31.7774 24.1924 31.7655 24.1924L31.7774 25.8941L31.8012 25.8703Z"
                fill="#3D221A"/>
            <mask id="mask42_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="24" width="1"
                  height="2">
                <path
                    d="M31.8012 25.8703L31.7893 24.1686C31.7893 24.1805 31.7774 24.1924 31.7655 24.1924L31.7774 25.8941L31.8012 25.8703Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask42_4086_29278)">
                <path d="M31.7655 24.1923C31.7655 24.1804 31.7774 24.1804 31.7655 24.1923V24.1923Z" fill="#3D221A"/>
                <path
                    d="M31.7773 24.1804L31.7892 25.8821C31.7972 25.8742 31.8011 25.8662 31.8011 25.8583L31.7892 24.1566C31.7892 24.1685 31.7892 24.1804 31.7773 24.1804Z"
                    fill="#3D221A"/>
            </g>
            <path
                d="M31.3014 24.1686L31.3133 25.8703C31.3133 25.8822 31.3252 25.8941 31.3371 25.8941L31.3252 24.1924C31.3133 24.1924 31.3014 24.1805 31.3014 24.1686Z"
                fill="#4B2517"/>
            <mask id="mask43_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="24" width="1"
                  height="2">
                <path
                    d="M31.3014 24.1686L31.3133 25.8703C31.3133 25.8822 31.3252 25.8941 31.3371 25.8941L31.3252 24.1924C31.3133 24.1924 31.3014 24.1805 31.3014 24.1686Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask43_4086_29278)">
                <path
                    d="M31.3014 24.1686L31.3133 25.8703C31.3133 25.8822 31.3252 25.8941 31.3371 25.8941L31.3252 24.1924C31.3133 24.1924 31.3014 24.1805 31.3014 24.1686Z"
                    fill="#4B2517"/>
            </g>
            <path d="M31.3252 24.2043V25.906L31.4918 26.0012L31.4799 24.2995L31.3252 24.2043Z" fill="#4B2517"/>
            <path d="M31.587 24.2995V26.0012L31.7774 25.8941L31.7655 24.1924L31.587 24.2995Z" fill="#3D221A"/>
            <path
                d="M31.4799 24.2996L31.4918 26.0012C31.5156 26.0131 31.5632 26.0131 31.587 26.0012L31.5751 24.2996C31.5513 24.3115 31.5156 24.3115 31.4799 24.2996Z"
                fill="#4B2517"/>
            <mask id="mask44_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="24" width="1"
                  height="3">
                <path
                    d="M31.4799 24.2996L31.4918 26.0012C31.5156 26.0131 31.5632 26.0131 31.587 26.0012L31.5751 24.2996C31.5513 24.3115 31.5156 24.3115 31.4799 24.2996Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask44_4086_29278)">
                <path
                    d="M31.4799 24.2996L31.4918 26.0012C31.5037 26.0012 31.5156 26.0132 31.5156 26.0132L31.5037 24.3115C31.5037 24.2996 31.4918 24.2996 31.4799 24.2996Z"
                    fill="#4B2517"/>
                <path
                    d="M31.5155 24.2996L31.5274 26.0013C31.5512 26.0013 31.575 26.0013 31.5988 25.9894L31.5869 24.2877C31.5631 24.2996 31.5393 24.3115 31.5155 24.2996Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M31.3252 24.1448C31.3014 24.1567 31.3014 24.1924 31.3252 24.2043L31.4918 24.2995C31.5156 24.3114 31.5632 24.3114 31.587 24.2995L31.7774 24.1924C31.8012 24.1805 31.8012 24.1448 31.7774 24.1329L31.6108 24.0377C31.587 24.0258 31.5394 24.0258 31.5156 24.0377L31.3252 24.1448Z"
                fill="#5D3021"/>
            <mask id="mask45_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="31" y="24" width="1"
                  height="1">
                <path
                    d="M31.8013 24.2995V24.1091C31.8013 24.1448 31.7775 24.1805 31.7299 24.2043V24.3947C31.7775 24.3709 31.8013 24.3352 31.8013 24.2995Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask45_4086_29278)">
                <path
                    d="M31.7179 24.2043V24.3947C31.7655 24.3709 31.7893 24.3352 31.7893 24.2995V24.1091C31.8012 24.1329 31.7774 24.1805 31.7179 24.2043Z"
                    fill="#4B2517"/>
            </g>
            <mask id="mask46_4086_29278" style={{
                    maskType: 'luminance'
                  }}maskUnits="userSpaceOnUse" x="28" y="24" width="1"
                  height="1">
                <path
                    d="M28.1837 24.1448V24.3352C28.1837 24.3709 28.2075 24.4066 28.2551 24.4304V24.24C28.2075 24.2162 28.1837 24.1805 28.1837 24.1448Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask46_4086_29278)">
                <path
                    d="M28.1837 24.1448V24.3352C28.1837 24.3709 28.2075 24.4066 28.2551 24.4304V24.24C28.2075 24.2162 28.1837 24.1805 28.1837 24.1448Z"
                    fill="#4B2517"/>
            </g>
            <path d="M28.2551 24.252V24.4423L29.7783 25.3229V25.1325L28.2551 24.252Z" fill="#4B2517"/>
            <path d="M30.1115 25.1325L30.1234 25.3229L31.7298 24.3947L31.7179 24.2043L30.1115 25.1325Z" fill="#4B2517"/>
            <mask id="mask47_4086_29278"
                  style={{
                    maskType: 'luminance'
                  }}

                  maskUnits="userSpaceOnUse" x="29" y="25" width="2"
                  height="1">
                <path
                    d="M29.7783 25.1324V25.3228C29.8735 25.3823 30.0282 25.3823 30.1234 25.3228V25.1324C30.0282 25.18 29.8735 25.18 29.7783 25.1324Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask47_4086_29278)">
                <path
                    d="M29.7783 25.1324V25.3228C29.8735 25.3823 30.0282 25.3823 30.1234 25.3228V25.1324C30.0282 25.18 29.8735 25.18 29.7783 25.1324Z"
                    fill="#4B2517"/>
            </g>
            <path
                d="M28.2551 24.0496C28.1599 24.1091 28.1599 24.1924 28.2551 24.2519L29.7783 25.1325C29.8735 25.192 30.0282 25.192 30.1234 25.1325L31.7299 24.2043C31.8251 24.1448 31.8251 24.0615 31.7299 24.002L30.2067 23.1214C30.1115 23.0619 29.9568 23.0619 29.8616 23.1214L28.2551 24.0496Z"
                fill="#7F4D3D"/>
            <path d="M37.4775 20.9557V21.1699L36.7754 21.5745V21.3722L37.4775 20.9557Z" fill="#5D3021"/>
            <path d="M36.7754 21.3721V21.5744L28.6835 16.9096V16.6954L36.7754 21.3721Z" fill="#7F4D3D"/>
            <path d="M37.4775 20.9557L36.7754 21.3722L28.6835 16.6955L29.3856 16.2909L37.4775 20.9557Z" fill="#8C5C4C"/>
            <path d="M36.7278 21.4078L36.7159 21.6101L36.0138 22.0147V21.8124L36.7278 21.4078Z" fill="#5D3021"/>
            <path d="M36.0138 21.8124V22.0147L27.9219 17.3499V17.1357L36.0138 21.8124Z" fill="#7F4D3D"/>
            <path d="M36.7278 21.4079L36.0138 21.8125L27.9219 17.1358L28.6359 16.7312L36.7278 21.4079Z" fill="#8C5C4C"/>
            <path d="M35.9543 21.8363V22.0505L35.2522 22.4551V22.2528L35.9543 21.8363Z" fill="#5D3021"/>
            <path d="M35.2522 22.2527V22.455L27.1603 17.7783V17.576L35.2522 22.2527Z" fill="#7F4D3D"/>
            <path d="M35.9543 21.8363L35.2522 22.2528L27.1603 17.5761L27.8743 17.1715L35.9543 21.8363Z" fill="#8C5C4C"/>
            <path d="M35.2045 22.2766V22.4908L34.5024 22.8954V22.6931L35.2045 22.2766Z" fill="#5D3021"/>
            <path d="M34.5025 22.693V22.8953L26.4105 18.2306V18.0164L34.5025 22.693Z" fill="#7F4D3D"/>
            <path d="M35.2046 22.2766L34.5025 22.6931L26.4105 18.0164L27.1126 17.6118L35.2046 22.2766Z" fill="#8C5C4C"/>
            <path d="M34.4311 22.7288V22.9311L33.729 23.3357V23.1334L34.4311 22.7288Z" fill="#5D3021"/>
            <path d="M33.729 23.1334V23.3357L25.6371 18.6709V18.4686L33.729 23.1334Z" fill="#7F4D3D"/>
            <path d="M34.4311 22.7288L33.729 23.1334L25.6371 18.4686L26.3392 18.0521L34.4311 22.7288Z" fill="#8C5C4C"/>
            <path d="M33.6814 23.1691V23.3714L32.9674 23.776V23.5737L33.6814 23.1691Z" fill="#5D3021"/>
            <path d="M32.9674 23.5737V23.776L24.8755 19.1112L24.8874 18.9089L32.9674 23.5737Z" fill="#7F4D3D"/>
            <path d="M33.6814 23.1691L32.9674 23.5737L24.8873 18.9089L25.5894 18.4924L33.6814 23.1691Z" fill="#8C5C4C"/>
            <path d="M24.8279 19.4443V21.7291L24.9945 21.8243V19.5514L24.8279 19.4443Z" fill="#5D3021"/>
            <path d="M24.9945 19.5514V21.8243L25.1016 21.7648L25.0897 19.4919L24.9945 19.5514Z" fill="#4B2517"/>
            <path d="M24.8279 19.4444L24.9945 19.5515L25.0897 19.492L24.9231 19.3849L24.8279 19.4444Z" fill="#936758"/>
            <path d="M24.9945 20.8842V21.0746L26.363 21.8719V21.6815L24.9945 20.8842Z" fill="#5D3021"/>
            <path d="M26.363 21.6815V21.8719L26.4225 21.8361V21.6458L26.363 21.6815Z" fill="#936758"/>
            <path d="M24.9945 20.8842L26.363 21.6815L26.4225 21.6458L25.0421 20.8485L24.9945 20.8842Z" fill="#936758"/>
            <path d="M24.9945 20.432V20.6224L26.363 21.4197V21.2293L24.9945 20.432Z" fill="#5D3021"/>
            <path d="M26.363 21.2293V21.4197L26.4225 21.384V21.1936L26.363 21.2293Z" fill="#936758"/>
            <path d="M24.9945 20.4321L26.363 21.2294L26.4225 21.1937L25.0421 20.3964L24.9945 20.4321Z" fill="#936758"/>
            <path d="M26.2797 20.325L26.2916 22.5978L26.4582 22.7049L26.4463 20.4201L26.2797 20.325Z" fill="#5D3021"/>
            <path d="M26.4463 20.4202L26.4582 22.705L26.5534 22.6455V20.3607L26.4463 20.4202Z" fill="#4B2517"/>
            <path d="M26.2797 20.325L26.4463 20.4202L26.5534 20.3607L26.3868 20.2655L26.2797 20.325Z" fill="#936758"/>
            <path d="M24.6613 18.9564V19.8132L26.6485 20.9675V20.1107L24.6613 18.9564Z" fill="#5D3021"/>
            <path d="M26.6486 20.1108V20.9676L26.7557 20.9081V20.0513L26.6486 20.1108Z" fill="#4B2517"/>
            <path d="M24.6613 18.9565L26.6485 20.1108L26.7556 20.0513L24.7565 18.897L24.6613 18.9565Z" fill="#936758"/>
            <path d="M28.1837 22.1694L28.1956 24.4542L28.3622 24.5494L28.3503 22.2765L28.1837 22.1694Z" fill="#5D3021"/>
            <path d="M28.3503 22.2765L28.3622 24.5494L28.4574 24.4899V22.217L28.3503 22.2765Z" fill="#4B2517"/>
            <path d="M28.1837 22.1695L28.3503 22.2766L28.4574 22.2171L28.2908 22.11L28.1837 22.1695Z" fill="#936758"/>
            <path d="M28.3503 23.6093V23.7996L29.7307 24.5969V24.4065L28.3503 23.6093Z" fill="#5D3021"/>
            <path d="M29.7307 24.4065V24.5969L29.7783 24.5612V24.3708L29.7307 24.4065Z" fill="#936758"/>
            <path d="M28.3503 23.6093L29.7307 24.4066L29.7783 24.3709L28.4098 23.5736L28.3503 23.6093Z" fill="#936758"/>
            <path d="M28.3503 23.1571V23.3475L29.7307 24.1448V23.9544L28.3503 23.1571Z" fill="#5D3021"/>
            <path d="M29.7307 23.9544V24.1448L29.7783 24.1091V23.9187L29.7307 23.9544Z" fill="#936758"/>
            <path d="M28.3503 23.1572L29.7307 23.9545L29.7783 23.9187L28.4098 23.1215L28.3503 23.1572Z" fill="#936758"/>
            <path d="M29.6473 23.05V25.3229L29.8139 25.43V23.1452L29.6473 23.05Z" fill="#5D3021"/>
            <path d="M29.814 23.1453V25.4301L29.9211 25.3706L29.9092 23.0858L29.814 23.1453Z" fill="#4B2517"/>
            <path d="M29.6473 23.0501L29.8139 23.1453L29.9091 23.0858L29.7425 22.9906L29.6473 23.0501Z" fill="#936758"/>
            <path d="M28.0171 21.6815V22.5383L30.0163 23.6926V22.8358L28.0171 21.6815Z" fill="#5D3021"/>
            <path d="M30.0162 22.8359V23.6927L30.1114 23.6332V22.7764L30.0162 22.8359Z" fill="#4B2517"/>
            <path d="M28.0171 21.6816L30.0163 22.8359L30.1115 22.7764L28.1242 21.6221L28.0171 21.6816Z" fill="#936758"/>
        </svg>
    )
}