import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import React from "react";
import {useBoostsStore} from "../../store/boosts-store";
import {Boost, boostHandler, useAreas} from "../../api/requests/area";
import {useUserStore} from "../../store/user-store";
import {getBoostIcon} from "../utils/getBoostIcon";
import {ActionModal} from "../action-modal";
import {useAreasStore} from "../../store/areas-store";
import {OnboardingModal} from "./onboarding";
import {WBloxItem} from "../w-blox";


export const BoostView = () => {
    const [selectedBoost, setSelectedBoost] = React.useState<Boost | null>(null)
    const {boosts, setBoosts} = useBoostsStore();
    const {user, setUser} = useUserStore();
    const {setAreas} = useAreasStore()

    const {mutate} = useAreas()
    if (!user) {
        return null
    }


    const onBoost = async (boost: string) => {
        const resp = await boostHandler({name: boost})
        if (resp) {
            setUser((prevUser) => {
                return {
                    ...prevUser,
                    // @ts-ignore
                    balance: prevUser?.balance - boosts?.find((item) => item?.name === boost)?.next_level_price || 0,
                    block_level: resp?.find((item) => item?.name === 'block')?.level || 0
                }
            })
            mutate().then((data) => {
                setAreas(data as any)
            })
            setBoosts(() => resp)
        }
    }
    return (
        <Wrapper>
            <Container>
                <Col>
                    <StaricCol>
                        <BorderedTitle title={'boost'}/>
                        <SubTitle style={
                            {
                                marginTop: 20,
                                 marginRight: 'auto'
                            }
                        }>
                            UP your bloxx
                        </SubTitle>
                        {boosts?.filter((item) => item.name === 'block').map((channel, index) => (
                            <BoostItem
                                icon={getBoostIcon(channel.name)}
                                onClick={() => setSelectedBoost(channel)}
                                level={channel.level} key={index} channel={channel.name}
                                value={channel.next_level_price}/>
                        ))}
                    </StaricCol>
                    <SubTitle style={{
                        marginTop: 20,
                        marginBottom: 20,
                        marginRight: 'auto'
                    }}>
                        UP YOUR UPGRADES
                    </SubTitle>
                    <List >
                        {boosts?.filter((item) => item.name !== 'block').map((channel, index) => (
                            <BoostItem
                                icon={getBoostIcon(channel.name)}
                                onClick={() => setSelectedBoost(channel)}
                                level={channel.level} key={index} channel={channel.name}
                                value={channel.next_level_price}/>
                        ))}
                    </List>
                </Col>
            </Container>
            <BottomNav/>
            <ActionModal
                onClick={async () => {
                    if (selectedBoost && user.balance >= selectedBoost.next_level_price) {
                        await onBoost(selectedBoost.name)
                        setSelectedBoost(null)
                    }
                }}
                variant={'primary'}
                text={
                    user.balance >= (selectedBoost?.next_level_price ?? 0)
                        ? selectedBoost?.next_level_price + ' BLOXX'
                        : 'Insufficient funds'
                }
                subText={'Please confirm that you want to upgrade ' + selectedBoost?.name}
                title={selectedBoost?.name || ''}
                open={selectedBoost !== null}
                onClose={() => setSelectedBoost(null)}
            />
            <AbsoluteContainer>
                <WBloxItem value={user?.balance}/>
            </AbsoluteContainer>
            <OnboardingModal
                hamster={2}
                text={'Look, here we can improve our areas. For example, install an elevator or a swimming pool. This will attract more residents, and therefore rent!'}
                next={'/tower-info/?onboarding=true'}/>
        </Wrapper>

    )
}


const StaricCol = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    align-items: center;
`

const AbsoluteContainer = styled.div`
    position: absolute;
    scale: 0.8;
    left: 16px;
    top: calc(14px + var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: calc(var(--tg-safe-area-inset-top, 0px) + var(--tg-content-safe-area-inset-top, 0px));
    box-sizing: border-box;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding-top: 54px;
    box-sizing: border-box;
`;


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;

`


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    overflow: auto; /* Ensure container can scroll */
    padding-bottom: 260px;
    height: 100%;

`


const SubTitle = styled.div`
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: left;
`


interface EarnItemProps {
    channel: string;
    value: number
    level: number
    onClick: () => void
    icon?: React.ReactNode

}

export const BoostItem = ({channel, value, onClick, icon}: EarnItemProps) => {
    return (
        <ThreeDContainer onClick={onClick}>
            <ItemRow>
                <IconContainer>
                    {icon}
                </IconContainer>
                <BoostCol>
                    <ChannelText>
                        {channel}
                    </ChannelText>
                    <ValueText>
                        {value} wBLOXX
                    </ValueText>
                </BoostCol>
                <UpIcon/>
            </ItemRow>
        </ThreeDContainer>
    )
}

const IconContainer = styled.div`
    border-radius: 6px;
    border: 2px solid #20150E;
    background: #FFFBD6;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    height: 36px;
`


const ThreeDContainer = styled.div`
    display: flex;
    height: 56px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #20150E;
    background: #494D5B;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 0, 0.50);
`


const ChannelText = styled.div`
    color: #FFE600;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    display: flex;
    gap: 4px;

`


const ValueText = styled.div`
    color: #00C3FF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    //text-transform: uppercase;
`

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const BoostCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`


const UpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <g clip-path="url(#clip0_6001_1990)">
            <path
                d="M29.6038 35.9999H7.01891C3.50947 35.9999 0.622681 33.1534 0.622681 29.693V7.42319C0.622681 3.96272 3.50947 1.11621 7.01891 1.11621H29.6038C33.1132 1.11621 36 3.96272 36 7.42319V29.693C36 33.1534 33.1132 35.9999 29.6038 35.9999Z"
                fill="#333333"/>
            <path
                d="M28.9811 34.8837H6.39623C2.88679 34.8837 0 32.0372 0 28.5767V6.30698C0 2.84651 2.88679 0 6.39623 0H28.9811C32.4906 0 35.3774 2.84651 35.3774 6.30698V28.5767C35.3774 32.0372 32.4906 34.8837 28.9811 34.8837Z"
                fill="#E0B500"/>
            <path
                d="M28.4151 34.0464H6.90569C3.56607 34.0464 0.79248 31.3673 0.79248 28.0185V6.80916C0.79248 3.51613 3.50946 0.78125 6.90569 0.78125H28.4151C31.7547 0.78125 34.5283 3.46032 34.5283 6.80916V28.0185C34.5283 31.3673 31.7547 34.0464 28.4151 34.0464Z"
                fill="#E7FF00"/>
            <path
                d="M33.7925 7.25567V27.572C33.7925 30.7533 31.1887 33.3208 27.9623 33.3208H7.35851C4.13209 33.3208 1.52832 30.7533 1.52832 27.572V7.25567C1.52832 4.07428 4.13209 1.50684 7.35851 1.50684H27.9623C31.1887 1.50684 33.7925 4.13009 33.7925 7.25567Z"
                fill="black"/>
            <path
                d="M6.67924 29.4699L6.0566 28.8559L6.67924 28.242V28.1861L6.45283 27.9629H6.39622L5.77358 28.5768L5.15094 27.9629H5.09433L4.86792 28.1861V28.242L5.49056 28.8559L4.86792 29.4699V29.5257L5.09433 29.7489H5.15094L5.77358 29.135L6.39622 29.7489H6.45283L6.67924 29.4699C6.67924 29.5257 6.67924 29.5257 6.67924 29.4699Z"
                fill="#F9FF00"/>
            <path
                d="M31.5849 29.4699L30.9622 28.8559L31.5849 28.242V28.1861L31.3585 27.9629H31.3019L30.6792 28.5768L30.0566 27.9629H30L29.7736 28.1861V28.242L30.3962 28.8559L29.7736 29.4699V29.5257L30 29.7489H30.0566L30.6792 29.135L31.3019 29.7489H31.3585L31.5849 29.4699C31.5849 29.5257 31.5849 29.5257 31.5849 29.4699Z"
                fill="#F9FF00"/>
            <path
                d="M6.67924 6.4186L6.0566 5.80464L6.67924 5.19069V5.13488L6.45283 4.91162H6.39622L5.77358 5.52557L5.15094 4.91162H5.09433L4.86792 5.13488V5.19069L5.49056 5.80464L4.86792 6.4186V6.47441L5.09433 6.69767H5.15094L5.77358 6.08371L6.39622 6.69767H6.45283L6.67924 6.4186Z"
                fill="#F9FF00"/>
            <path
                d="M31.5849 6.4186L30.9622 5.80464L31.5849 5.19069V5.13488L31.3585 4.91162H31.3019L30.6792 5.52557L30.0566 4.91162H30L29.7736 5.13488V5.19069L30.3962 5.80464L29.7736 6.4186V6.47441L30 6.69767H30.0566L30.6792 6.08371L31.3019 6.69767H31.3585L31.5849 6.4186Z"
                fill="#F9FF00"/>
            <path
                d="M18.1132 26.1766C17.3207 26.1766 16.6981 25.5626 16.6981 24.7812V12.1673L13.2452 14.1208C12.566 14.5115 11.7169 14.2882 11.3207 13.6184C10.9245 12.9487 11.1509 12.1115 11.8301 11.7208L17.3773 8.59518C17.8301 8.37192 18.3396 8.37192 18.7924 8.59518C19.2452 8.81843 19.4716 9.32076 19.4716 9.82308V24.837C19.5282 25.5626 18.9056 26.1766 18.1132 26.1766Z"
                fill="#F9FF00"/>
            <path
                d="M18.1132 26.1767C17.3208 26.1767 16.6981 25.5628 16.6981 24.7814V9.76744C16.6981 9.26512 16.9811 8.81861 17.3774 8.53954C17.8302 8.31628 18.3396 8.26047 18.7925 8.53954L24.3396 11.6651C25.0189 12.0558 25.2453 12.893 24.8491 13.5628C24.4528 14.2326 23.6038 14.4558 22.9245 14.0651L19.4717 12.1116V24.7256C19.5283 25.5628 18.9057 26.1767 18.1132 26.1767Z"
                fill="#F9FF00"/>
        </g>
        <defs>
            <clipPath id="clip0_6001_1990">
                <rect width="36" height="36" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

