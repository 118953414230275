import {IconProps} from "../../model";


interface Props  extends IconProps {
    levelColor: string;
}

export const PoolIcon = (props: Props) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="44" height="34" viewBox="0 0 44 34" fill="none">
            <g opacity="0.17">
                <path
                    d="M19.7831 11.6864L17.6211 10.4301L18.6436 9.83117L23.3912 7.50852L24.9542 7.46469V7.15792L25.5094 7.14332L26.3128 7.61077L28.2556 6.52979L30.9727 6.44214L33.6021 7.96136L34.391 10.0795L31.0603 11.9931H29.0591L25.4655 14.0675H23.9171L23.9317 14.0528L23.9171 14.0675L21.7551 12.8112L22.7777 12.2269L26.006 10.6346L25.524 10.3571V9.26146L21.3315 11.6864H19.7831L19.7977 11.6718L19.7831 11.6864Z"
                    fill="black"/>
                <path
                    d="M26.6194 15.6158L27.6419 15.0315L32.3895 12.6942L33.9525 12.665V12.3436H34.5076L35.3111 12.8111L37.2539 11.7301L39.971 11.6425L42.6004 13.1617L43.3892 15.2652L40.0586 17.1935H38.0574L34.4638 19.2678H32.9154L32.93 19.2532L32.9154 19.2678L30.7534 18.0115L31.776 17.4126L35.0043 15.8349L34.5222 15.5574V14.4618L30.3298 16.8721H28.7814H28.7959H28.7814L26.6194 15.6158Z"
                    fill="black"/>
            </g>
            <path
                d="M22.1786 7.53769L22.6899 6.2522L22.8214 6.32524L22.2955 7.61073V7.62534L22.1641 7.5523L22.1786 7.53769Z"
                fill="#C2B2B2"/>
            <path d="M21.1123 8.15129L22.164 7.55237L22.2955 7.62541L21.2437 8.22433H21.2291L21.0977 8.15129H21.1123Z"
                  fill="#C2B2B2"/>
            <path
                d="M20.5426 7.62541L21.1123 8.3558C21.1123 8.37041 21.1269 8.38502 21.1415 8.38502L21.01 8.31198C20.9954 8.31198 20.9954 8.29737 20.9808 8.28276L20.4111 7.55237L20.5426 7.62541Z"
                fill="#C2B2B2"/>
            <path
                d="M18.7751 9.49521L19.2864 8.20972L19.4179 8.28276L18.9066 9.56825L18.892 9.58286L18.7751 9.50982V9.49521Z"
                fill="#C2B2B2"/>
            <path
                d="M17.7233 10.1233L18.7751 9.50977L18.892 9.58281L17.8402 10.1963V10.1817L17.7087 10.1087L17.7233 10.1233Z"
                fill="#C2B2B2"/>
            <path
                d="M17.1681 9.59745L17.7232 10.3132C17.7232 10.3278 17.7378 10.3425 17.7524 10.3425L17.6209 10.2694C17.6063 10.2694 17.6063 10.2548 17.5917 10.2402L17.0366 9.52441L17.1681 9.59745Z"
                fill="#C2B2B2"/>
            <path d="M22.9531 6.09147L22.8216 6.01843L17.0515 9.36364L17.1684 9.43668L22.9531 6.09147Z" fill="#FFFAF5"/>
            <path
                d="M22.953 6.25216L22.4125 7.59608C22.3979 7.66912 22.3394 7.72755 22.2956 7.75677L21.2439 8.3703C21.1854 8.39952 21.1416 8.39952 21.127 8.35569L20.5573 7.6253L19.5786 8.195L19.0235 9.56815C19.0089 9.64118 18.9504 9.69962 18.9066 9.72883L17.8548 10.3424C17.7964 10.3716 17.7526 10.3716 17.738 10.3278L17.1829 9.61197V9.45128L22.9676 6.10608L22.953 6.25216ZM21.2292 8.22422L22.2956 7.6253L22.3102 7.61069L22.8215 6.3252L20.7034 7.55226L21.2292 8.22422ZM17.8256 10.1817L18.892 9.58275L18.9066 9.56815L19.4179 8.28265L17.3144 9.49511L17.8256 10.1817Z"
                fill="#C2B2B2"/>
            <path d="M17.1684 9.43669L17.0515 9.36365V9.52434L17.1684 9.59737V9.43669Z" fill="#E5D7D6"/>
            <path d="M24.8811 7.50843L24.9688 7.4646V7.33313L24.8811 7.39156V7.50843Z" fill="#C2B2B2"/>
            <path d="M22.8362 6.32532L24.8813 7.50856V7.39169L22.8362 6.19385V6.32532Z" fill="#C2B2B2"/>
            <path d="M24.8813 7.39169L24.9689 7.33326L22.9238 6.15002L22.8362 6.19385L24.8813 7.39169Z" fill="#C2B2B2"/>
            <path d="M23.0846 8.15128L21.0833 6.98265L22.5879 4.90833L24.5891 6.07696L23.0846 8.15128Z" fill="#FAF0EF"/>
            <path d="M19.2865 10.3424L17.2852 9.18834L21.0832 6.98254L23.0845 8.15117L19.2865 10.3424Z" fill="#FFFAF5"/>
            <path d="M19.2865 10.5907L17.2852 9.42208V9.18835L19.2865 10.3424V10.5907Z" fill="#E5D7D6"/>
            <path
                d="M24.589 6.0769L24.7059 6.19377L23.1575 8.35574L19.2864 10.5907V10.3424L23.0844 8.15123L24.589 6.0769Z"
                fill="#C2B2B2"/>
            <path d="M19.2424 10.766L19.3301 10.7222V10.5907L19.2424 10.6345V10.766Z" fill="#C2B2B2"/>
            <path d="M17.1975 9.58276L19.2426 10.766V10.6345L17.1975 9.45129V9.58276Z" fill="#E5D7D6"/>
            <path d="M19.2426 10.6347L19.3302 10.5908L17.2851 9.40759L17.1975 9.45141L19.2426 10.6347Z" fill="#FFFAF5"/>
            <path d="M24.3405 8.794L24.8518 7.4939L24.9833 7.56694L24.4574 8.86704L24.3259 8.794H24.3405Z"
                  fill="#C2B2B2"/>
            <path d="M23.2741 9.40748L24.3259 8.79395L24.4574 8.86698L23.4056 9.48052H23.391L23.2595 9.40748H23.2741Z"
                  fill="#C2B2B2"/>
            <path
                d="M22.7191 8.88151L23.2888 9.61191C23.2888 9.62651 23.3034 9.64112 23.318 9.64112L23.1866 9.56808C23.172 9.56808 23.172 9.55347 23.1574 9.53887L22.5876 8.80847L22.7191 8.88151Z"
                fill="#C2B2B2"/>
            <path d="M20.937 10.7513L21.4629 9.46582L21.5798 9.53886L21.0685 10.8244H21.0539L20.937 10.7513Z"
                  fill="#C2B2B2"/>
            <path d="M19.8852 11.3649L20.937 10.7513L21.0538 10.8244L20.0021 11.4379L19.8706 11.3649H19.8852Z"
                  fill="#C2B2B2"/>
            <path
                d="M19.3302 10.839L19.8853 11.5548C19.8853 11.5694 19.8999 11.584 19.9145 11.584L19.783 11.511C19.7684 11.511 19.7684 11.4964 19.7538 11.4818L19.1987 10.766L19.3302 10.839Z"
                fill="#C2B2B2"/>
            <path d="M25.115 7.34782L24.9981 7.27478L19.2134 10.6054L19.3302 10.6784L25.115 7.34782Z" fill="#FFFAF5"/>
            <path
                d="M25.1148 7.50851L24.5744 8.85243C24.5597 8.92547 24.5013 8.9839 24.4575 9.01312L23.4057 9.62665C23.3473 9.65586 23.3035 9.65586 23.2889 9.61204L22.7192 8.88165L21.7404 9.45135L21.1853 10.8245C21.1707 10.8975 21.1123 10.956 21.0685 10.9852L20.0167 11.5987C19.9583 11.6279 19.9144 11.6279 19.8998 11.5841L19.3447 10.8683V10.7076L25.1295 7.36243L25.1148 7.50851ZM23.3911 9.46596L24.4575 8.86704L24.4721 8.85243L24.9834 7.56694L22.8652 8.794L23.3911 9.46596ZM19.9875 11.4234L21.0538 10.8245L21.0685 10.8099L21.5797 9.52439L19.4762 10.7368L19.9875 11.4234Z"
                fill="#C2B2B2"/>
            <path d="M19.3302 10.6784L19.2134 10.6053V10.766L19.3302 10.8391V10.6784Z" fill="#E5D7D6"/>
            <path d="M24.998 7.27478L25.1149 7.34782L19.5493 10.5616H19.2864L24.998 7.27478Z" fill="#C2B2B2"/>
            <path
                d="M24.4574 9.83118C24.3552 9.77275 24.1653 9.77275 24.063 9.83118C23.9608 9.88961 23.9608 9.99186 24.063 10.0649C24.1653 10.1233 24.3552 10.1233 24.4574 10.0649C24.5743 9.99186 24.5743 9.88961 24.4574 9.83118Z"
                fill="#FFFAF5"/>
            <path
                d="M24.2677 2.76086C24.1946 2.76086 24.1362 2.81929 24.1362 2.89233V9.94794C24.1362 10.021 24.3846 10.021 24.3846 9.94794V2.89233C24.3846 2.81929 24.3261 2.76086 24.2677 2.76086Z"
                fill="#E5D7D6"/>
            <path d="M26.2543 0L29.0882 1.63608L24.2676 0.511276L26.2543 0Z" fill="#E5D7D6"/>
            <path d="M19.4326 3.94408L22.2665 5.56556L24.2678 0.51123L19.4326 3.94408Z" fill="#FFFAF5"/>
            <path d="M29.0882 3.94408L27.5836 4.80595L26.2543 5.56556L24.2676 0.51123L29.0882 3.94408Z" fill="#FAF0EF"/>
            <path d="M22.2665 0L19.4326 1.63608L24.2678 0.511276L22.2665 0Z" fill="#FFFAF5"/>
            <path d="M29.0882 1.63604V3.94408L24.2676 0.51123L29.0882 1.63604Z" fill={props.levelColor}/>
            <path d="M19.4326 1.63604V3.94408L24.2678 0.51123L19.4326 1.63604Z" fill={props.levelColor}/>
            <path d="M26.2543 5.56556H22.2664L24.2676 0.51123L26.2543 5.56556Z" fill={props.levelColor}/>
            <path d="M26.2543 0H22.2664L24.2676 0.511276L26.2543 0Z" fill={props.levelColor}/>
            <path
                d="M24.2677 0.642827C24.3887 0.642827 24.4868 0.583962 24.4868 0.511353C24.4868 0.438743 24.3887 0.379883 24.2677 0.379883C24.1467 0.379883 24.0486 0.438743 24.0486 0.511353C24.0486 0.583962 24.1467 0.642827 24.2677 0.642827Z"
                fill="#FFFAF5"/>
            <path
                d="M24.2677 0.0876465C24.2385 0.0876465 24.2093 0.116861 24.2093 0.146077L24.1509 0.525883C24.1509 0.598922 24.3992 0.598922 24.3992 0.525883L24.3408 0.146077C24.3262 0.116861 24.297 0.0876465 24.2677 0.0876465Z"
                fill="#E5D7D6"/>
            <path
                d="M26.2981 9.9188L26.824 8.6333L26.9408 8.70634L26.4296 9.99184L26.4149 10.0064L26.2981 9.9334V9.9188Z"
                fill="#C2B2B2"/>
            <path d="M25.2463 10.5469L26.2981 9.93335L26.4149 10.0064L25.3631 10.6199L25.2317 10.5469H25.2463Z"
                  fill="#C2B2B2"/>
            <path
                d="M24.6766 10.021L25.2463 10.7514C25.2463 10.766 25.261 10.7806 25.2756 10.7806L25.1441 10.7076C25.1295 10.7076 25.1295 10.693 25.1149 10.6784L24.5452 9.948L24.6766 10.021Z"
                fill="#C2B2B2"/>
            <path
                d="M22.9091 11.8763L23.4204 10.5908L23.5519 10.6639L23.0406 11.9494L23.026 11.964L22.8945 11.8909L22.9091 11.8763Z"
                fill="#C2B2B2"/>
            <path d="M21.8428 12.5044L22.8945 11.8909L23.026 11.9639L21.9742 12.5774H21.9596L21.8428 12.5044Z"
                  fill="#C2B2B2"/>
            <path
                d="M21.3024 11.9786L21.8575 12.6943C21.8575 12.709 21.8721 12.7236 21.8867 12.7236L21.7552 12.6505C21.7406 12.6505 21.7406 12.6359 21.726 12.6213L21.1709 11.9055L21.3024 11.9786Z"
                fill="#C2B2B2"/>
            <path d="M27.0871 8.48722L26.9556 8.41418L21.1709 11.7448L21.3024 11.8178L27.0871 8.48722Z" fill="#FFFAF5"/>
            <path
                d="M27.087 8.64791L26.5465 9.99184C26.5319 10.0649 26.4735 10.1233 26.4297 10.1525L25.3779 10.7661C25.3195 10.7953 25.2756 10.7953 25.261 10.7514L24.6913 10.0211L23.7126 10.5908L23.1575 11.9639C23.1429 12.0369 23.0845 12.0954 23.0406 12.1246L21.9889 12.7381C21.9304 12.7673 21.8866 12.7673 21.872 12.7235L21.3169 12.0077V11.847L27.1016 8.50183L27.087 8.64791ZM25.3487 10.6054L26.415 10.0064L26.4297 9.99184L26.9409 8.70634L24.8228 9.9334L25.3487 10.6054ZM21.9596 12.5628L23.026 11.9639L23.0406 11.9493L23.5519 10.6638L21.4484 11.8763L21.9596 12.5628Z"
                fill="#C2B2B2"/>
            <path d="M21.3024 11.8179L21.1709 11.7449V11.9056L21.3024 11.9786V11.8179Z" fill="#E5D7D6"/>
            <path d="M29.0151 9.90414L29.1028 9.84571V9.71423L29.0151 9.77267V9.90414Z" fill="#C2B2B2"/>
            <path d="M26.97 8.72095L29.0151 9.90419V9.77272L26.97 8.58948V8.72095Z" fill="#C2B2B2"/>
            <path d="M29.0151 9.77268L29.1027 9.71424L27.043 8.53101L26.97 8.58944L29.0151 9.77268Z" fill="#C2B2B2"/>
            <path d="M27.2183 10.5324L25.217 9.37835L26.7217 7.28943L28.7229 8.45806L27.2183 10.5324Z" fill="#FAF0EF"/>
            <path d="M23.4202 12.7382L21.4189 11.5696L25.217 9.37842L27.2183 10.5324L23.4202 12.7382Z" fill="#FFFAF5"/>
            <path d="M23.4202 12.9718L21.4189 11.8032V11.5695L23.4202 12.7381V12.9718Z" fill="#E5D7D6"/>
            <path
                d="M28.7231 8.45801L28.8399 8.58948L27.2915 10.7368L23.4204 12.9718V12.7381L27.2185 10.5323L28.7231 8.45801Z"
                fill="#C2B2B2"/>
            <path d="M23.3767 13.1472L23.4643 13.1034V12.9719L23.3767 13.0304V13.1472Z" fill="#C2B2B2"/>
            <path d="M21.3313 11.9639L23.3764 13.1471V13.0303L21.3313 11.847V11.9639Z" fill="#E5D7D6"/>
            <path d="M23.3764 13.0304L23.464 12.9719L21.4043 11.7887L21.3313 11.8471L23.3764 13.0304Z" fill="#FFFAF5"/>
            <path d="M28.46 11.175L28.9859 9.88953L29.1027 9.96257L28.5915 11.2481V11.2627L28.46 11.1896V11.175Z"
                  fill="#C2B2B2"/>
            <path d="M27.4081 11.7886L28.4599 11.1897L28.5914 11.2627L27.525 11.8617L27.3936 11.7886H27.4081Z"
                  fill="#C2B2B2"/>
            <path
                d="M26.8385 11.2627L27.4082 11.9931C27.4082 12.0077 27.4228 12.0223 27.4374 12.0223L27.306 11.9493C27.2914 11.9493 27.2914 11.9347 27.2768 11.9201L26.707 11.1897L26.8385 11.2627Z"
                fill="#C2B2B2"/>
            <path
                d="M25.071 13.1325L25.5823 11.847L25.7138 11.9201L25.2025 13.2056L25.1879 13.2202L25.071 13.1471V13.1325Z"
                fill="#C2B2B2"/>
            <path d="M24.0046 13.746L25.071 13.1471L25.1879 13.2201L24.1361 13.8191L24.0046 13.746Z" fill="#C2B2B2"/>
            <path
                d="M23.4642 13.2348L24.0193 13.9506C24.0193 13.9652 24.0339 13.9798 24.0485 13.9798L23.9171 13.9067C23.9025 13.9067 23.9025 13.8921 23.8879 13.8775L23.3328 13.1617L23.4642 13.2348Z"
                fill="#C2B2B2"/>
            <path d="M29.249 9.72892L29.1175 9.65588L23.3328 13.0011L23.4642 13.0741L29.249 9.72892Z" fill="#FFFAF5"/>
            <path
                d="M29.2489 9.88961L28.7084 11.2335C28.6938 11.3066 28.6353 11.365 28.5915 11.3942L27.5398 12.0078C27.4813 12.037 27.4375 12.037 27.4229 11.9931L26.8532 11.2627L25.8744 11.8325L25.3193 13.2056C25.3047 13.2786 25.2463 13.3371 25.2025 13.3663L24.1507 13.9798C24.0923 14.009 24.0485 14.009 24.0339 13.9652L23.4788 13.2494V13.0887L29.2635 9.74353L29.2489 9.88961ZM27.5251 11.8617L28.5915 11.2627L28.6061 11.2481L29.1174 9.96265L26.9992 11.1897L27.5251 11.8617ZM24.1215 13.8191L25.1879 13.2202L25.2025 13.2056L25.7138 11.9201L23.6102 13.1326L24.1215 13.8191Z"
                fill="#C2B2B2"/>
            <path d="M23.4642 13.074L23.3328 13.001V13.1617L23.4642 13.2347V13.074Z" fill="#E5D7D6"/>
            <path d="M29.1172 9.65588L29.2487 9.72892L23.6685 12.9427L23.4202 12.9573L29.1172 9.65588Z" fill="#C2B2B2"/>
            <path
                d="M24.706 9.65578L24.9105 9.53892L25.115 9.42205L25.2173 9.36362L25.2757 9.27598L25.4072 9.10068L25.524 8.94L25.6409 8.7647L25.7578 8.60402L25.8892 8.42872L26.0061 8.26803L26.1376 8.09274L26.2544 7.93205L26.3713 7.75676L26.4881 7.59607L26.6196 7.42078L26.7073 7.28931L28.7086 8.44333L28.8254 8.5748L27.3062 10.6929L26.5612 11.1312V10.9121L25.2173 10.1378V9.67039H24.6914L24.706 9.65578Z"
                fill="#C2B2B2"/>
            <path d="M31.1626 12.738L31.6885 11.4379L31.8054 11.5109L31.2941 12.811L31.1626 12.738Z" fill="#C2B2B2"/>
            <path d="M30.1105 13.3516L31.1623 12.738L31.2938 12.8111L30.2274 13.4246L30.0959 13.3516H30.1105Z"
                  fill="#C2B2B2"/>
            <path
                d="M29.5409 12.8257L30.1106 13.5561C30.1106 13.5707 30.1252 13.5853 30.1398 13.5853L30.0084 13.5123C29.9938 13.5123 29.9938 13.4977 29.9792 13.4831L29.4094 12.7527L29.5409 12.8257Z"
                fill="#C2B2B2"/>
            <path
                d="M27.7734 14.6955L28.2847 13.41L28.4162 13.4831L27.9049 14.7686L27.8903 14.7832L27.7734 14.7101V14.6955Z"
                fill="#C2B2B2"/>
            <path d="M26.707 15.309L27.7734 14.7101L27.8903 14.7831L26.8385 15.382L26.707 15.309Z" fill="#C2B2B2"/>
            <path
                d="M26.1664 14.7831L26.7215 15.4989C26.7215 15.5135 26.7361 15.5281 26.7507 15.5281L26.6192 15.4551C26.6046 15.4551 26.6046 15.4405 26.59 15.4259L26.0349 14.7101L26.1664 14.7831Z"
                fill="#C2B2B2"/>
            <path d="M31.9516 11.2919L31.8201 11.2189L26.0354 14.5641L26.1669 14.6371L31.9516 11.2919Z" fill="#FFFAF5"/>
            <path
                d="M31.9513 11.4526L31.4108 12.7965C31.3962 12.8696 31.3377 12.928 31.2939 12.9572L30.2422 13.5707C30.1837 13.6 30.1399 13.6 30.1253 13.5561L29.5556 12.8257L28.5768 13.3954L28.0217 14.7686C27.9925 14.8416 27.9487 14.9001 27.9049 14.9293L26.8531 15.5428C26.7947 15.572 26.7509 15.572 26.7363 15.5282L26.1812 14.8124V14.6517L31.9659 11.3065L31.9513 11.4526ZM30.2275 13.4101L31.2939 12.8111L31.3085 12.7965L31.8198 11.511L29.7016 12.7381L30.2275 13.4101ZM26.8239 15.3821L27.8903 14.7832L27.9049 14.7686L28.4162 13.4831L26.3126 14.6955L26.8239 15.3821Z"
                fill="#C2B2B2"/>
            <path d="M26.1664 14.637L26.0349 14.564V14.71L26.1664 14.7831V14.637Z" fill="#E5D7D6"/>
            <path d="M33.8794 12.7089L33.9671 12.665V12.5336L33.8794 12.5774V12.7089Z" fill="#C2B2B2"/>
            <path d="M31.8342 11.5256L33.8793 12.7089V12.5774L31.8342 11.3942V11.5256Z" fill="#C2B2B2"/>
            <path d="M33.8793 12.5774L33.967 12.5336L31.9219 11.3503L31.8342 11.3942L33.8793 12.5774Z" fill="#C2B2B2"/>
            <path d="M32.0828 13.3516L30.0815 12.1829L31.5862 10.094L33.5874 11.2626L32.0828 13.3516Z" fill="#FAF0EF"/>
            <path d="M28.2847 15.5428L26.2834 14.3742L30.0815 12.183L32.0828 13.3516L28.2847 15.5428Z" fill="#FFFAF5"/>
            <path d="M28.2847 15.7765L26.2834 14.6225V14.3741L28.2847 15.5428V15.7765Z" fill="#E5D7D6"/>
            <path
                d="M33.5873 11.2627L33.7042 11.3942L32.1558 13.5415L28.2847 15.7765V15.5428L32.0827 13.3516L33.5873 11.2627Z"
                fill="#C2B2B2"/>
            <path d="M28.241 15.9664L28.3286 15.908V15.7911L28.241 15.835V15.9664Z" fill="#C2B2B2"/>
            <path d="M26.1958 14.7832L28.2409 15.9664V15.835L26.1958 14.6517V14.7832Z" fill="#E5D7D6"/>
            <path d="M28.2409 15.835L28.3286 15.7911L26.2835 14.6079L26.1958 14.6517L28.2409 15.835Z" fill="#FFFAF5"/>
            <path
                d="M33.3391 13.9797L33.8504 12.6942L33.9818 12.7673L33.456 14.0527V14.0674L33.3245 13.9943L33.3391 13.9797Z"
                fill="#C2B2B2"/>
            <path
                d="M32.2727 14.6079L33.3244 13.9944L33.4559 14.0674L32.4041 14.681L32.3895 14.6663L32.2581 14.5933L32.2727 14.6079Z"
                fill="#C2B2B2"/>
            <path
                d="M31.703 14.0821L32.2727 14.8125C32.2727 14.8271 32.2873 14.8417 32.3019 14.8417L32.1705 14.7686C32.1559 14.7686 32.1559 14.754 32.1413 14.7394L31.5715 14.009L31.703 14.0821Z"
                fill="#C2B2B2"/>
            <path
                d="M29.9355 15.9372L30.4468 14.6517L30.5783 14.7248L30.067 16.0103L30.0524 16.0249L29.9355 15.9518V15.9372Z"
                fill="#C2B2B2"/>
            <path d="M28.8838 16.5653L29.9355 15.9518L30.0524 16.0248L29.0006 16.6384L28.8691 16.5653H28.8838Z"
                  fill="#C2B2B2"/>
            <path
                d="M28.3287 16.0395L28.8838 16.7553C28.8838 16.7699 28.8984 16.7845 28.913 16.7845L28.7816 16.7114C28.767 16.7114 28.7669 16.6968 28.7523 16.6822L28.1973 15.9664L28.3287 16.0395Z"
                fill="#C2B2B2"/>
            <path d="M34.1133 12.5336L33.9818 12.4606L28.2117 15.8058L28.3285 15.8788L34.1133 12.5336Z" fill="#FFFAF5"/>
            <path
                d="M34.1134 12.7089L33.5729 14.0529C33.5582 14.1259 33.4998 14.1843 33.456 14.2136L32.4042 14.8271C32.3458 14.8563 32.302 14.8563 32.2874 14.8125L31.7177 14.0821L30.7389 14.6518L30.1838 16.0249C30.1692 16.098 30.1108 16.1564 30.067 16.1856L29.0152 16.7992C28.9568 16.8284 28.9129 16.8284 28.8983 16.7845L28.3433 16.0688V15.9081L34.128 12.5629L34.1134 12.7089ZM32.3896 14.6664L33.456 14.0675L33.4706 14.0529L33.9819 12.7674L31.8637 13.9944L32.3896 14.6664ZM28.986 16.6239L30.0524 16.0249L30.067 16.0103L30.5782 14.7248L28.4747 15.9373L28.986 16.6239Z"
                fill="#C2B2B2"/>
            <path d="M28.3285 15.8788L28.2117 15.8058V15.9665L28.3285 16.0395V15.8788Z" fill="#E5D7D6"/>
            <path d="M33.9817 12.4604L34.1132 12.5335L28.533 15.7618H28.2847L33.9817 12.4604Z" fill="#C2B2B2"/>
            <path
                d="M33.456 15.0168C33.3537 14.9584 33.1638 14.9584 33.0616 15.0168C32.9593 15.0753 32.9593 15.1775 33.0616 15.2506C33.1638 15.309 33.3537 15.309 33.456 15.2506C33.5729 15.1921 33.5729 15.0899 33.456 15.0168Z"
                fill="#FFFAF5"/>
            <path
                d="M33.2516 7.94666C33.1785 7.94666 33.1201 8.00509 33.1201 8.07813V15.1337C33.1201 15.2068 33.3684 15.2068 33.3684 15.1337V8.07813C33.3831 8.00509 33.3246 7.94666 33.2516 7.94666Z"
                fill="#E5D7D6"/>
            <path d="M35.2527 5.18579L38.0867 6.82188L33.2515 5.69707L35.2527 5.18579Z" fill="#E5D7D6"/>
            <path d="M28.4309 9.12987L31.2649 10.766L33.2515 5.69702L28.4309 9.12987Z" fill="#FFFAF5"/>
            <path d="M38.0867 9.12987L36.5821 9.99174L35.2527 10.766L33.2515 5.69702L38.0867 9.12987Z" fill="#FAF0EF"/>
            <path d="M31.2649 5.18579L28.4309 6.82188L33.2515 5.69707L31.2649 5.18579Z" fill="#FFFAF5"/>
            <path d="M38.0867 6.82183V9.12987L33.2515 5.69702L38.0867 6.82183Z" fill={props.levelColor}/>
            <path d="M28.4309 6.82183V9.12987L33.2515 5.69702L28.4309 6.82183Z" fill={props.levelColor}/>
            <path d="M35.2528 10.766H31.2649L33.2515 5.69702L35.2528 10.766Z" fill={props.levelColor}/>
            <path d="M35.2528 5.18579H31.2649L33.2515 5.69707L35.2528 5.18579Z" fill={props.levelColor}/>
            <path
                d="M33.2516 5.8285C33.3726 5.8285 33.4707 5.76963 33.4707 5.69702C33.4707 5.62441 33.3726 5.56555 33.2516 5.56555C33.1306 5.56555 33.0325 5.62441 33.0325 5.69702C33.0325 5.76963 33.1306 5.8285 33.2516 5.8285Z"
                fill="#FFFAF5"/>
            <path
                d="M33.2514 5.28796C33.2222 5.28796 33.193 5.31718 33.193 5.34639L33.1345 5.7262C33.1345 5.79924 33.3829 5.79924 33.3829 5.7262L33.3244 5.34639C33.3098 5.31718 33.2952 5.28796 33.2514 5.28796Z"
                fill="#E5D7D6"/>
            <path
                d="M35.2966 15.1192L35.8225 13.8337L35.9394 13.9068L35.4281 15.1923L35.4135 15.2069L35.2966 15.1338V15.1192Z"
                fill="#C2B2B2"/>
            <path d="M34.2448 15.7327L35.2966 15.1338L35.4135 15.2068L34.3617 15.8058L34.2302 15.7327H34.2448Z"
                  fill="#C2B2B2"/>
            <path
                d="M33.6752 15.2068L34.2449 15.9372C34.2449 15.9518 34.2595 15.9664 34.2741 15.9664L34.1426 15.8934C34.128 15.8934 34.128 15.8788 34.1134 15.8642L33.5437 15.1338L33.6752 15.2068Z"
                fill="#C2B2B2"/>
            <path
                d="M31.9077 17.0766L32.419 15.7911L32.5504 15.8642L32.0246 17.1497V17.1643L31.8931 17.0912L31.9077 17.0766Z"
                fill="#C2B2B2"/>
            <path d="M30.8413 17.6901L31.8931 17.0912L32.0246 17.1642L30.9728 17.7632H30.9582L30.8413 17.6901Z"
                  fill="#C2B2B2"/>
            <path
                d="M30.3006 17.1789L30.8558 17.8947C30.8558 17.9093 30.8704 17.9239 30.885 17.9239L30.7535 17.8508C30.7389 17.8508 30.7389 17.8362 30.7243 17.8216L30.1692 17.1058L30.3006 17.1789Z"
                fill="#C2B2B2"/>
            <path d="M36.0854 13.673L35.9539 13.6L30.1692 16.9452L30.3006 17.0182L36.0854 13.673Z" fill="#FFFAF5"/>
            <path
                d="M36.0855 13.8337L35.545 15.1776C35.5304 15.2507 35.472 15.3091 35.4282 15.3383L34.3764 15.9518C34.318 15.9811 34.2742 15.9811 34.2596 15.9372L33.6898 15.2068L32.7111 15.7765L32.156 17.1497C32.1268 17.2227 32.083 17.2812 32.0391 17.3104L30.9874 17.9239C30.9289 17.9531 30.8851 17.9531 30.8705 17.9093L30.3154 17.1935V17.0328L36.1001 13.6876L36.0855 13.8337ZM34.3472 15.8058L35.4136 15.2068L35.4282 15.1922L35.9395 13.9067L33.8213 15.1338L34.3472 15.8058ZM30.9582 17.7632L32.0245 17.1643L32.0391 17.1497L32.5504 15.8642L30.4469 17.0767L30.9582 17.7632Z"
                fill="#C2B2B2"/>
            <path d="M30.3006 17.0181L30.1692 16.9451V17.1058L30.3006 17.1788V17.0181Z" fill="#E5D7D6"/>
            <path d="M38.0137 15.09L38.1013 15.0461V14.9147L38.0137 14.9585V15.09Z" fill="#C2B2B2"/>
            <path d="M35.9688 13.9066L38.0139 15.0899V14.9584L35.9688 13.7751V13.9066Z" fill="#C2B2B2"/>
            <path d="M38.0139 14.9584L38.1015 14.9146L36.0418 13.7313L35.9688 13.7751L38.0139 14.9584Z" fill="#C2B2B2"/>
            <path d="M36.2168 15.7326L34.2156 14.5639L35.7202 12.4896L37.7215 13.6437L36.2168 15.7326Z" fill="#FAF0EF"/>
            <path d="M32.419 17.9238L30.4177 16.7698L34.2158 14.564L36.217 15.7326L32.419 17.9238Z" fill="#FFFAF5"/>
            <path d="M32.419 18.1576L30.4177 17.0036V16.7699L32.419 17.9239V18.1576Z" fill="#E5D7D6"/>
            <path
                d="M37.7216 13.6437L37.8385 13.7751L36.29 15.9225L32.4189 18.1575V17.9238L36.217 15.7326L37.7216 13.6437Z"
                fill="#C2B2B2"/>
            <path d="M32.375 18.3475L32.4627 18.3037V18.1722L32.375 18.2161V18.3475Z" fill="#C2B2B2"/>
            <path d="M30.3301 17.1642L32.3752 18.3474V18.216L30.3301 17.0327V17.1642Z" fill="#E5D7D6"/>
            <path d="M32.3749 18.216L32.4626 18.1721L30.4029 16.9889L30.3298 17.0327L32.3749 18.216Z" fill="#FFFAF5"/>
            <path
                d="M37.4587 16.3608L37.9846 15.0753L38.1015 15.1484L37.5902 16.4338L37.5756 16.4485L37.4587 16.3754V16.3608Z"
                fill="#C2B2B2"/>
            <path d="M36.407 16.9889L37.4587 16.3754L37.5756 16.4484L36.5238 17.0619L36.3923 16.9889H36.407Z"
                  fill="#C2B2B2"/>
            <path
                d="M35.837 16.4631L36.4068 17.1934C36.4068 17.2081 36.4214 17.2227 36.436 17.2227L36.3045 17.1496C36.2899 17.1496 36.2899 17.135 36.2753 17.1204L35.7056 16.39L35.837 16.4631Z"
                fill="#C2B2B2"/>
            <path d="M34.0696 18.3328L34.5808 17.0327L34.7123 17.1058L34.201 18.4059H34.1864L34.0549 18.3328H34.0696Z"
                  fill="#C2B2B2"/>
            <path d="M33.0032 18.9464L34.0549 18.3329L34.1864 18.4059L33.1346 19.0195H33.12L33.0032 18.9464Z"
                  fill="#C2B2B2"/>
            <path
                d="M32.4628 18.4205L33.0179 19.1362C33.0179 19.1508 33.0325 19.1655 33.0471 19.1655L32.9156 19.0924C32.901 19.0924 32.901 19.0778 32.8864 19.0632L32.3313 18.3474L32.4628 18.4205Z"
                fill="#C2B2B2"/>
            <path d="M38.2475 14.9292L38.116 14.8562L32.3313 18.1868L32.4628 18.2598L38.2475 14.9292Z" fill="#FFFAF5"/>
            <path
                d="M38.2474 15.0899L37.7069 16.4338C37.6923 16.5069 37.6339 16.5653 37.5901 16.5945L36.5383 17.2081C36.4799 17.2373 36.436 17.2373 36.4214 17.1935L35.8517 16.4631L34.873 17.0328L34.3179 18.4059C34.3033 18.479 34.2449 18.5374 34.201 18.5666L33.1493 19.1801C33.0908 19.2093 33.047 19.2093 33.0324 19.1655L32.4773 18.4497V18.2891L38.262 14.9438L38.2474 15.0899ZM36.5091 17.0474L37.5755 16.4485L37.5901 16.4338L38.1013 15.1484L35.9832 16.3754L36.5091 17.0474ZM33.1201 19.0048L34.1864 18.4059L34.201 18.3913L34.7123 17.1058L32.6088 18.3183L33.1201 19.0048Z"
                fill="#C2B2B2"/>
            <path d="M32.4628 18.2598L32.3313 18.1868V18.3475L32.4628 18.4205V18.2598Z" fill="#E5D7D6"/>
            <path d="M38.116 14.8562L38.2475 14.9292L32.6673 18.143H32.4189L38.116 14.8562Z" fill="#C2B2B2"/>
            <path
                d="M33.7043 14.8563L33.9088 14.7394L34.1133 14.6226L34.201 14.5641L34.274 14.4765L34.4055 14.3012L34.5223 14.1405L34.6392 13.9652L34.7561 13.7899L34.8875 13.6292L35.0044 13.4539L35.1213 13.2933L35.2381 13.118L35.3696 12.9427L35.4865 12.782L35.6179 12.6067L35.7056 12.4752L37.7069 13.6439L37.8237 13.7753L36.3045 15.8935L35.5595 16.3171V16.098L34.201 15.3238V14.8563H33.6897H33.7043Z"
                fill="#C2B2B2"/>
            <path
                d="M20.0752 33.1745C20.382 33.1745 20.7033 33.1745 21.0101 33.1599C24.1508 33.0284 26.9701 32.2104 28.9714 30.8811C30.9289 29.5664 31.9514 27.8134 31.8346 25.9436C31.7031 23.6648 29.9063 21.4882 26.8971 19.9544C24.5014 18.7419 21.5068 18.0115 18.4537 17.9239C16.3064 17.8509 14.2321 17.325 12.596 16.4047C12.5229 16.3609 12.4499 16.3316 12.3769 16.2878C10.843 15.4844 8.92939 15.0461 6.95732 15.0461C6.78203 15.0461 6.59213 15.0461 6.41684 15.0608C4.54703 15.1484 2.86714 15.6305 1.6839 16.4485C0.529875 17.2373 -0.0544407 18.3037 0.00399086 19.4285C0.0770303 20.7724 1.08496 22.0579 2.76487 22.9636C2.91095 23.0367 3.0424 23.1097 3.18848 23.1827C4.75152 23.9277 5.83254 24.9941 6.1393 26.1335C6.65058 28.0179 8.30124 29.7563 10.7846 31.0564C13.414 32.4295 16.7154 33.1745 20.0752 33.1745Z"
                fill="#B7DCE5"/>
            <path d="M31.8492 26.2796V25.5199L31.4255 25.2716V26.4841L31.8492 26.2796Z" fill="#B7DCE5"/>
            <path d="M0.0185547 18.4205V19.2532L0.310712 19.706L0.529831 18.961L0.0185547 18.4205Z" fill="#B7DCE5"/>
            <path d="M31.5423 25.4324H30.958V25.9729H31.5423V25.4324Z" fill="#B7DCE5"/>
            <path d="M0.880214 18.4937H0.295898V19.0341H0.880214V18.4937Z" fill="#B7DCE5"/>
            <path
                d="M20.0752 32.4149C20.382 32.4149 20.7033 32.4149 21.0101 32.4003C24.1508 32.2688 26.9701 31.4508 28.9714 30.1214C30.9289 28.8067 31.9514 27.0538 31.8346 25.184C31.7031 22.9052 29.9063 20.7286 26.8971 19.1947C24.5014 17.9823 21.5068 17.2519 18.4537 17.1642C16.3064 17.0912 14.2321 16.5653 12.596 15.645C12.5229 15.6012 12.4499 15.572 12.3769 15.5282C10.843 14.7247 8.92939 14.2865 6.95732 14.2865C6.78203 14.2865 6.59213 14.2865 6.41684 14.3011C4.54703 14.3888 2.86714 14.8708 1.6839 15.6889C0.529875 16.4777 -0.0544407 17.5441 0.00399086 18.6689C0.0770303 20.0128 1.08496 21.2983 2.76487 22.204C2.91095 22.277 3.0424 22.35 3.18848 22.4231C4.75152 23.1681 5.83254 24.2345 6.1393 25.3739C6.65058 27.2583 8.30124 28.9966 10.7846 30.2967C13.414 31.6699 16.7154 32.4149 20.0752 32.4149Z"
                fill="#DCF4FF"/>
            <path
                d="M26.6486 19.9981C24.2383 18.7711 21.3167 18.1137 18.4535 18.0261C16.1893 17.953 14.0127 17.3833 12.3328 16.4338C12.2744 16.4046 12.2014 16.3608 12.1283 16.3316C9.06069 14.7247 4.4738 14.7685 2.03428 16.463C1.08477 17.1204 0.617313 17.9384 0.602705 18.7711C0.50045 17.8362 0.953287 16.9013 2.01966 16.1709C4.45918 14.491 9.03146 14.4471 12.1137 16.0394C12.1868 16.0686 12.2452 16.1124 12.3182 16.1417C14.0127 17.0766 16.1893 17.6609 18.4389 17.7339C21.3021 17.8216 24.2236 18.4789 26.6339 19.706C29.6578 21.2398 31.2354 23.358 31.25 25.4177C31.104 23.4602 29.5263 21.4735 26.6486 19.9981Z"
                fill="#B7DCE5"/>
            <path
                d="M26.6489 21.2399C24.2386 20.0129 21.317 19.3555 18.4539 19.2679C16.1896 19.1948 14.0131 18.6251 12.3332 17.6756C12.2747 17.6464 12.2017 17.6026 12.1287 17.5733C9.06101 15.9665 4.47412 16.0103 2.03461 17.7048C1.34804 18.1723 0.924408 18.7274 0.719898 19.3117C0.661466 19.1364 0.617635 18.9611 0.603027 18.7858C0.617635 17.9532 1.08509 17.1351 2.03461 16.4778C4.47412 14.7979 9.04641 14.754 12.1287 16.3463C12.2017 16.3755 12.2601 16.4193 12.3332 16.4485C14.0277 17.3834 16.2042 17.9678 18.4539 18.0408C21.317 18.1284 24.2386 18.7858 26.6489 20.0129C29.5266 21.4737 31.1043 23.4749 31.2504 25.4324C31.2504 25.6807 31.2212 25.9291 31.1773 26.1774C30.8414 24.366 29.2929 22.5839 26.6489 21.2399Z"
                fill="#7CCBED"/>
            <path
                d="M28.65 29.6394C24.3553 32.5172 16.4086 32.5756 11.0621 29.7855C8.63721 28.5146 7.1618 26.8931 6.70896 25.2278C6.35837 23.9569 5.23358 22.7445 3.43681 21.8972C3.30534 21.8388 3.17384 21.7658 3.04237 21.6927C1.8007 21.0208 1.01188 20.1735 0.719727 19.297C0.909629 18.7127 1.34787 18.1576 2.03444 17.6902C4.47395 16.0103 9.04624 15.9664 12.1285 17.5587C12.2015 17.5879 12.26 17.6317 12.333 17.6609C14.0275 18.5959 16.2041 19.1802 18.4537 19.2532C21.3168 19.3408 24.2384 19.9982 26.6487 21.2253C29.2927 22.5692 30.8266 24.366 31.1918 26.1627C30.9581 27.4336 30.1254 28.6461 28.65 29.6394Z"
                fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M12.961 26.1336C13.4723 25.6077 14.1881 25.3302 14.86 25.0964C15.7657 24.7897 17.0366 24.702 18.0007 24.7312C19.3739 24.7605 20.7324 24.7605 22.4269 23.7525C24.6473 22.4232 27.2037 21.9558 28.6937 24.1177C29.3657 25.0964 29.1027 26.528 28.4308 27.5214C27.788 28.4563 26.7362 29.0406 25.6991 29.4496C22.7921 30.6036 19.5199 30.8666 16.4523 30.3115C15.269 30.0923 13.7936 29.8294 12.8734 28.9675C12.0261 28.1641 12.1722 26.937 12.961 26.1336Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M18.848 20.3926C20.338 20.4072 22.0617 20.2319 22.7045 20.5387C23.3472 20.8455 22.8651 21.6927 21.4482 21.9411C20.0312 22.1894 18.4828 21.9703 17.3872 21.5466C16.2916 21.123 16.8905 20.378 18.848 20.3926Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M4.91222 17.4272C5.52576 17.2227 7.11803 17.062 7.48323 17.4272C7.84842 17.7924 6.84047 18.2452 5.97861 18.3767C5.11674 18.5228 4.38636 19.0195 4.38636 19.7499C4.38636 20.4803 3.49527 20.787 2.88174 20.1589C2.2536 19.5307 2.76487 18.1284 4.91222 17.4272Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M7.55618 18.8588C8.53491 19.0779 8.28657 19.4723 7.73147 19.8375C7.17637 20.2173 6.43136 20.6264 5.64253 20.6264C5.08743 20.6264 4.67841 20.1297 5.27734 19.5162C5.73018 19.0487 6.63588 18.6543 7.55618 18.8588Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M11.0622 26.1481C11.8072 26.1919 11.778 26.747 11.6903 27.6527C11.6027 28.5584 10.8869 28.6898 10.2149 27.7988C9.54294 26.9077 10.2003 26.1043 11.0622 26.1481Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M22.6607 22.2477C23.4495 22.3354 23.0259 23.0366 21.7258 23.5917C20.4257 24.1468 18.8481 24.1906 18.629 23.6647C18.4098 23.1388 19.8414 23.431 20.6887 23.1388C21.5359 22.8321 22.1494 22.1893 22.6607 22.2477Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M24.9541 21.4152C26.0059 21.1961 26.8385 21.8096 26.4587 22.0287C26.152 22.204 25.5385 22.0433 24.7642 22.2332C24.0046 22.4231 24.2383 21.5759 24.9541 21.4152Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M4.29856 21.0792C4.89748 20.7724 5.64249 21.196 6.38749 21.0061C7.1325 20.8162 7.80445 20.3488 8.09661 20.4949C8.38877 20.6409 8.16966 21.1668 7.38084 21.532C6.59201 21.9118 5.39415 22.0433 4.7368 21.8242C3.90415 21.532 3.86032 21.2983 4.29856 21.0792Z"
                  fill="#61B4E2"/>
            <path opacity="0.5"
                  d="M9.61587 18.2161C10.2294 18.0847 10.9598 19.0488 10.507 19.7207C10.0541 20.3781 9.35295 20.1298 9.38217 19.5308C9.42599 18.9173 8.78323 18.3914 9.61587 18.2161Z"
                  fill="#61B4E2"/>
        </svg>
    )
}