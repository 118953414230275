import React, {useEffect, useMemo} from 'react';
import './App.css';
import {MainView} from "./components/views/main";
import MatterGame from "./components/views/game/game";
import {EarnView} from "./components/views/earn";
import {
    createBrowserRouter,
    Route, Router, RouterProvider,
    Routes,
} from "react-router-dom";
import {BoostView} from "./components/views/boost";
import {InviteView} from "./components/views/invite";
import {initNavigator} from '@telegram-apps/sdk-react';
import {useIntegration} from "@telegram-apps/react-router-integration";
import {postEvent} from '@telegram-apps/sdk';

import {
    authHandler,
    useBuyingPrice,
    useFriends,
    useTaskGroups,
} from "./api/requests/user-info";
import {LoadingView} from "./components/views/loading";
import {useUserStore} from "./store/user-store";
import {useAreas, useBoosts} from "./api/requests/area";
import {useAreasStore} from "./store/areas-store";
import {useChannels, useLinks} from "./api/requests/channels";
import {useChannelsStore} from "./store/channels-store";
import {useLinksStore} from "./store/links-store";
import {useLocalImagePreload} from "./hook/useImagePreload";
import {useBoostsStore} from "./store/boosts-store";
import {useFriendsStore} from "./store/friends-store";
import {BackButtonProvider} from "./components/back-button-provider";
import {useTaskStore} from "./store/task-store";
import {TaskView} from "./components/views/task";
import {useInvoiceStore} from "./store/star-store";
import {TowerInfo} from "./components/tower-info";


declare global {
    interface Window {
        Telegram: any;
    }
}

function App() {
    const {imagesPreloaded} = useLocalImagePreload();

    const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
    const [location, reactNavigator] = useIntegration(navigator);
    const {user, setUser} = useUserStore();
    const {setChannels} = useChannelsStore();
    const {setLinks} = useLinksStore();
    const {setAreas} = useAreasStore();
    const {setBoosts} = useBoostsStore();
    const {setFriends} = useFriendsStore()
    const {setTasks} = useTaskStore();
    const {setPrice} = useInvoiceStore();
    const {data: areas, isLoading} = useAreas(!user);
    const {data: channels} = useChannels(!user)
    const {data: links} = useLinks(!user);
    const {data: boosts} = useBoosts(!user);
    const {data: friends} = useFriends(!user);
    const {data: tasks} = useTaskGroups(!user);
    const {data: buyingPrice, isLoading: isBuyingPriceLoading} = useBuyingPrice(!user);


    useEffect(() => {
        if (buyingPrice) {
            setPrice(() => buyingPrice.price);
        }
    }, [buyingPrice]);


    useEffect(() => {
        if (channels) {
            setChannels(channels);
        }
    }, [channels]);

    useEffect(() => {
        if (areas) {
            setAreas(() => areas);
        }
    }, [areas]);

    useEffect(() => {
        if (links) {
            setLinks(links);
        }
    }, [links]);

    useEffect(() => {
        if (boosts) {
            setBoosts(() => boosts);
        }
    }, [boosts]);

    useEffect(() => {
        if (friends) {
            setFriends(friends);
        }
    }, [friends]);


    useEffect(() => {
        if (tasks) {
            setTasks(tasks);
        }
    }, [tasks]);

    useEffect(() => {
        try {
            postEvent('web_app_expand')

            postEvent('web_app_setup_swipe_behavior', {allow_vertical_swipe: false});
            postEvent('web_app_setup_closing_behavior', {need_confirmation: true});
            postEvent('web_app_setup_back_button', {is_visible: false});
            (window as any).Telegram.WebApp.requestFullscreen();
        } catch {
        }
    }, []);

    useEffect(() => {
        if (!user) {
            authHandler().then(res => {
                setUser(() => res);
            });
        }
    }, []);


    useEffect(() => {
        try {
            navigator.attach();
            return () => navigator.detach();
        } catch {
        }
    }, [navigator]);


    const routes = [
        {
            path: "/",
            element: <MainView/>
        },
        {
            path: "/earn",
            element: <EarnView/>
        },
        {
            path: "/boost",
            element: <BoostView/>
        },
        {
            path: "/invite",
            element: <InviteView/>
        },
        {
            path: '/game',
            element: <MatterGame/>
        },
        {
            path: '/task/:id',
            element: <TaskView/>
        },
        {
            path: '/tower-info',
            element: <TowerInfo/>
        }
    ];

    const router = createBrowserRouter(routes);


    if (process.env.REACT_APP_API_KEY) {
        return <RouterProvider router={router}/>
    }

    return (
        <>
            {(!user || isLoading || isBuyingPriceLoading || !imagesPreloaded) ?
                <LoadingView/> :
                <Router navigator={reactNavigator} location={location}>
                    <Routes>
                        {routes.map(({path, element}) => (
                            <Route key={path} path={path} element={<BackButtonProvider>{element}</BackButtonProvider>}/>
                        ))}
                    </Routes>
                </Router>
            }
        </>
    )

}


export default App;
