import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import styled from "styled-components";

interface Props {
    progress?: number;
}

export const ActionScale = forwardRef(({progress = 100}: Props, ref) => {
    const [currentProgress, setCurrentProgress] = useState(progress);

    useEffect(() => {
        if (currentProgress > 0) {
            const interval = setInterval(() => {
                setCurrentProgress((prev) => Math.max(prev - 1, 0));
            }, 100);

            return () => clearInterval(interval);
        }
    }, [currentProgress]);

    useImperativeHandle(ref, () => ({
        resetProgress: () => {
            setCurrentProgress(100);
        },
    }));


    if (currentProgress <= 0) {
        return null;
    }

    return (
        <Absolute>
            <Relative>
                <X>
                    12x
                </X>
                <Rectangle
                    style={{
                        position: 'absolute',
                        top: -17,
                        left: -14,
                        zIndex: 1,
                        scale: 0.8,
                        height:180,
                    }}
                />
                <Container>
                    <Container2>
                        <Progress progress={currentProgress}/>
                    </Container2>
                </Container>
            </Relative>
        </Absolute>
    );
});


const X = styled.div`
    color: #FFE600;
    text-align: center;
    text-shadow: 0px 1px 0px #5B5B5B;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
    position: absolute;
    top: -24px;
    left: -4px;
`


const Absolute = styled.div`
    position: absolute;
    top: 40%;
    right: 10px;
`;

const Relative = styled.div`
    position: relative;
`;

const Container = styled.div`
    border: 1px solid #e87d18;
    background: #666;
    width: 16px;
    height: 173px;
    border-radius: 50px;
    flex-shrink: 0;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container2 = styled.div`
    border: 1px solid #000;
    background: #666;
    width: 12px;
    top: 0;
    left: 0;
    height: 169px;
    border-radius: 50px;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
`;

const Progress = styled.div<{ progress: number }>`
    background: #FFE600;
    width: 100%;
    height: ${(props) => props.progress}%;
    border-radius: 50px;
    transition: height 0.5s;
`;


const Rectangle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
         xmlns="http://www.w3.org/2000/svg" width="47" height="268" viewBox="0 0 47 268" fill="none">


        <path
            d="M21.1794 56.2898L1.37 21.6165C0.320495 19.8032 1.63234 17.5034 3.68762 17.5034H43.3064C45.4054 17.5034 46.6736 19.7589 45.6241 21.6165L25.8147 56.2898C24.8089 58.1473 22.1851 58.1473 21.1794 56.2898Z"
            fill="#FFE600"/>
        <path
            d="M22.5352 53.6802L4.16889 21.5278C3.7316 20.7759 4.25638 19.8472 5.13097 19.8472H41.8199C42.6945 19.8472 43.2192 20.7759 42.782 21.5278L24.4156 53.6802C24.0221 54.4321 22.9725 54.4321 22.5352 53.6802Z"
            stroke="#1F140F" stroke-width="1.2285" stroke-miterlimit="10"/>
        <path
            d="M21.1794 56.2898L1.37 21.6165C0.320495 19.8032 1.63234 17.5034 3.68762 17.5034H43.3064C45.4054 17.5034 46.6736 19.7589 45.6241 21.6165L25.8147 56.2898C24.8089 58.1473 22.1851 58.1473 21.1794 56.2898Z"
            stroke="#FFA300" stroke-width="1.65229" stroke-miterlimit="10"/>

        <defs>
            <filter id="filter0_d_4072_21022" x="8.67969" y="3.95508" width="28.9863" height="9.04492"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4072_21022"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4072_21022" result="shape"/>
            </filter>
        </defs>
    </svg>
)