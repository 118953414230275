import styled from "styled-components";

export const CheckedIcon = (props: { style?: React.CSSProperties }) => {
    return (

        <Image src={'/images/checked.png'} style={props.style}/>
    );
}

const Image = styled.img`
`