import styled from "styled-components";


interface ModalWrapperProps {
    children: React.ReactNode;
    hamster?: number;
}

export const ModalWrapper = ({children, hamster}: ModalWrapperProps) => {
    return (
        <Wrapper>
            {hamster && <Image src={`/images/hamster/${hamster}.png`}/>}
             <Container isHamster={hamster}>
                <InnerContainer>
                    {children}
                </InnerContainer>
            </Container>
        </Wrapper>
    )
}


const Image = styled.img`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 234.48px;
    height: 399.995px;
    object-fit: contain;
    z-index: 0;

`

const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000000000;
`


const Container = styled.div<{ isHamster?: number }>`
    position: absolute;
    top: ${props => props.isHamster ? '60%' : '50%'};
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border-top: 6px solid #FFA300;
    border-right: 2px solid #FFA300;
    border-bottom: 6px solid #FFA300;
    border-left: 2px solid #FFA300;
    width: 340px;
    align-items: flex-start;
    gap: 10px;
`

const InnerContainer = styled.div`
    border-radius: 16px;
    border: 4px solid #FFE600;
    background: #0F0F0F;
    box-sizing: border-box;
    width: 100%;

`
