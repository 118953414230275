import React, {useEffect, useRef, useState} from 'react';
import Matter, {Bodies, Body, Bounds, Constraint, Engine, Events, Render, Runner, World, Composite} from 'matter-js';
import {useWindowSize} from "../../hooks/useWindowSize";
import styled from "styled-components";
import {Life} from "../../life";
import {Scale} from "../../scale";
import {useUserStore} from "../../../store/user-store";
import {ActionModal} from "../../action-modal";
import {useNavigate} from "react-router-dom";
import {buildHandler} from "../../../api/requests/area";
import {useAreasStore} from "../../../store/areas-store";
import {PauseModal} from "../../pause-modal";
import {usePositionStore} from "../../../store/position-store";
import {UWinModal} from "../../u-win-modal";
import {ActionScale} from "../../action-scale";

const loadImage = (url: string) => {
    return new Promise<string>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img.src);
        img.onerror = reject;
        img.src = url;
    });
};


interface IBlockImage {
    total: number;
    current: number;
    blox_level: number;
}

const getBlockImage = ({total, current, blox_level}: IBlockImage) => {

    let lvl = blox_level

    if (blox_level > 10) {
        lvl = 10
    }

    if (current < 1 && current < total) {
        return `/images/first-box/${lvl}.png`
    } else if (current + 1 === total) {
        return `/images/last-box/${lvl}.png`
    } else {
        return `/images/box/${lvl}.png`
    }

}


const getPersonImage = (type: number = 1) => {
    return `/images/person/${type}.png`

}

const MatterGame: React.FC = () => {
    const scene = useRef<HTMLDivElement>(null);
    const engine = useRef<Matter.Engine>(Engine.create());
    const runner = useRef<Matter.Runner>(Runner.create());
    const render = useRef<Matter.Render | null>(null);
    const [isRunning, setIsRunning] = useState<boolean>(true);
    const [life, setLife] = useState<number>(3);


    const box = useRef<Matter.Body[]>([]);
    const ground = useRef<Matter.Body>();
    const underGround = useRef<Matter.Body>();
    const magnet = useRef<Matter.Body>();

    const hangPoint = useRef<Matter.Body>();
    const intervalID = useRef<NodeJS.Timeout | null>(null);
    const constraint = useRef<Matter.Constraint | null>(null);
    const magnetConstraint = useRef<Matter.Constraint | null>(null);
    const isDropping = useRef<boolean>(false);
    const oscillationIntervalID = useRef<NodeJS.Timeout | null>(null);

    const {width: wi, height: he} = useWindowSize();

    const width = wi + 80;
    const height = he + 40;
    const ellipseParams = useRef<{ a: number, b: number }>({a: 80, b: 40});
    const magnetPosition = useRef<{ x: number, y: number } | null>(null);
    const hangPointPosition = useRef<{ x: number, y: number } | null>(null);
    const [_, setGroundY] = useState(-6);
    const [gameOver, setGameOver] = useState<boolean>(false);
    const [backgroundPositionY, setBackgroundPositionY] = useState(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const stopButtonRef = useRef<HTMLDivElement>(null);
    const LINE_HEIGHT = he - (300)
    const {user} = useUserStore();
    const total = 10 + (((user?.block_level || 0) - 1) * 3)
    const actionScaleRef = useRef<{ resetProgress: () => void }>(null);

    const isFitstBloackOnGround = useRef<boolean>(false);
    const isDonotMoveCamera = useRef<boolean>(false);


    const {setAreas} = useAreasStore();

    const comboCount = useRef(0)
    const highQualityCount = useRef(0)
    const mediumQualityCount = useRef(0)
    const lowQualityCount = useRef(0)


    const bConunt = comboCount?.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current

    const navigate = useNavigate()
    // get y and x from query params
    const {position} = usePositionStore()
    const x = position?.pos_x || 0
    const y = position?.pos_y || 0

    const handleStopGame = (e: any) => {
        e.stopPropagation();
        Runner.stop(runner.current);
        if (render.current) {
            Render.stop(render.current);
        }
        if (intervalID.current) {
            clearInterval(intervalID.current);
        }
    };

    //
    useEffect(() => {
        if (!position) {
            navigate('/')
        }
    }, []);

    useEffect(() => {
        if (comboCount.current || highQualityCount.current) {
            actionScaleRef.current?.resetProgress();
        }
    }, [comboCount.current, highQualityCount.current]);

    const handleStartGame = (e: any) => {
        e.stopPropagation();
        Runner.run(runner.current, engine.current);
        if (render.current) {
            Render.run(render.current);
        }
        setIsRunning(prevState => !prevState);
    }

    useEffect(() => {
        const engineInstance = engine.current;

        // Initialize the render only after the images are fully loaded and bodies are created
        const initializeRender = async () => {
            // Ensure all images and bodies are loaded and created
            await createSwing(width / 2);
            await createGround();
            const initialY = LINE_HEIGHT - 120;
            await createBlock(width / 2, initialY);

            render.current = Render.create({
                element: scene.current!,
                engine: engineInstance,
                isFixed: false,
                delta: 1000 / 30,
                fps: 30,
                options: {
                    width: width,
                    height: height,
                    wireframes: false,

                    hasBounds: true,
                    background: 'transparent',
                    //@ts-ignore
                    pixelRatio: /android/i.test(navigator.userAgent || navigator.vendor || window.opera) ? 0.4 : 'auto',
                    bounds: {
                        min: {x: 0, y: 0},
                        max: {x: width, y: height},
                    }

                },
            });

            Runner.run(runner.current, engineInstance);
            Render.run(render.current);


            startEllipticalMotion();
        };

        initializeRender().then()

        const handleDropBlock = (e: Event) => {
            if (e.target !== stopButtonRef.current) {
                dropBlock();
            }
        };

        document.body.addEventListener('click', handleDropBlock);

        Events.on(engine.current, 'beforeUpdate', () => {
            if (magnet.current) {
                magnetPosition.current = {x: magnet.current.position.x, y: magnet.current.position.y};
            }
            if (hangPoint.current) {
                hangPointPosition.current = {x: hangPoint.current.position.x, y: hangPoint.current.position.y};
            }
        });

        setTimeout(() => {
            setIsLoading(false);
        }, 4000);


        return () => {
            document.body.removeEventListener('click', handleDropBlock);
            if (render.current) {
                Render.stop(render.current);
                World.clear(engineInstance.world, false);
                Engine.clear(engineInstance);
                render.current.canvas.remove();
                render.current.textures = {};
                Events.off(engine.current, 'afterUpdate', checkIfResting);

            }
            if (oscillationIntervalID.current) {
                clearInterval(oscillationIntervalID.current);
            }
            if (intervalID.current) {
                clearInterval(intervalID.current);
            }
        };
    }, [width, height]);


    const createUmbrellaPerson = async (targetX: number, targetY: number, direction: 'left' | 'right', index: number) => {
        try {
            const personImage = getPersonImage(index + 1);

            const startX = direction === 'left' ? -50 : width + 50;  // Based on direction
            const startY = targetY - 200;

            const umbrellaPerson = Bodies.rectangle(startX, startY, 40, 40, {
                isStatic: false,
                friction: 0.01,
                density: 0.001,
                render: {
                    fillStyle: 'red',
                    sprite: {
                        texture: personImage,
                        xScale: 0.20,
                        yScale: 0.20,
                    },
                },
                collisionFilter: {
                    group: -1,
                    mask: 0x000
                }
            });

            World.add(engine.current.world, [umbrellaPerson]);

            const moveUmbrellaPerson = () => {
                const dx = targetX - umbrellaPerson.position.x;
                const dy = targetY - umbrellaPerson.position.y;

                const newX = umbrellaPerson.position.x + dx / 15;
                const newY = umbrellaPerson.position.y + dy / 15;

                Body.setPosition(umbrellaPerson, {x: newX, y: newY});

                if (Math.abs(dx) < 1 && Math.abs(dy) < 1) {
                    clearInterval(moveInterval);

                    Body.set(umbrellaPerson, {
                        collisionFilter: {
                            category: 0x0002,
                            mask: 0xFFFF
                        }
                    });
                }
            };

            const moveInterval = setInterval(moveUmbrellaPerson, 1000 / 60);

            const checkIfTouchingBlock = () => {
                if (umbrellaPerson.position.y - 80 > targetY) {
                    setTimeout(() => {
                        World.remove(engine.current.world, umbrellaPerson);
                        clearInterval(moveInterval);
                    }, 150);
                }
            };

            setInterval(checkIfTouchingBlock, 1000 / 60);

        } catch (error) {
            console.log("Error creating umbrella person:", error);
        }
    };


    const moveGroundHorizontally = (deltaX: number) => {
        if (ground.current) {
            Body.translate(ground.current, {x: deltaX, y: 0});
            if (groundConstraint.current) {
                const attachedBlock = groundConstraint.current.bodyB;
                if (attachedBlock) {
                    Body.translate(attachedBlock, {x: deltaX, y: 0});
                }
            }
        }
    };


    const maxOffset = useRef(5);

    useEffect(() => {
        if (bConunt > 5 && ground?.current) {
            let direction = 1;
            const amplitude = 0.2;

            const initialX = ground.current?.position.x || width / 2; // Initial x position of the ground

            const interval = setInterval(() => {
                //@ts-ignore
                const newX = ground.current?.position.x + direction * amplitude;

                if (newX > initialX + (maxOffset.current < 5 ? 0 : maxOffset.current)) {
                    direction = -1;
                } else if (newX < initialX - (maxOffset.current < 5 ? 0 : maxOffset.current)) {
                    direction = 1;
                }
                moveGroundHorizontally(direction * amplitude);
            }, 1000 / 60);
            return () => clearInterval(interval);
        }
    }, [width, box.current.length]);


    const createGround = async () => {
        try {
            // const floorTexture = await loadImage('/images/floor.png');

            underGround.current = Bodies.rectangle(wi / 2, ((he) * 0.9), 100, 80, {
                isStatic: true,
                render: {
                    fillStyle: 'transparent',
                },
            });

            ground.current = Bodies.rectangle(wi / 2, ((he) * 0.9) - 66, 140, 20, {
                isStatic: true,
                render: {
                    fillStyle: 'transparent',
                }
            });

            World.add(engine.current.world, [ground.current, underGround.current]);
        } catch (error) {
            console.log("Error loading image:", error);
        }
    }


    const createBlock = async (initialX: number, initialY: number) => {
        console.log("Create block")

        if (constraint.current) {
            return;
        }

        if (comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current === total) {
            return;
        }
        try {
            const blockTexture = await loadImage(
                getBlockImage({
                    total,
                    current: comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current,
                    blox_level: user?.block_level || 1
                })
            );

            const boxCurrent = Bodies.rectangle(
                initialX,
                initialY + 20.1,
                80,
                (comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current) + 1 === total ? 96 : 96,
                {
                    angle: 0,
                    density: 0.001,
                    friction: 0.5,
                    frictionAir: 0.0,
                    restitution: 0,
                    mass: 0,

                    inertia: Infinity,
                    isStatic: false,
                    render: {
                        fillStyle: "red",
                        sprite: {
                            texture: blockTexture,
                            xScale: 0.8,
                            yScale: 0.8,
                        },
                    },
                });


            box.current.push(boxCurrent);
            if (!constraint.current) {
                constraint.current = Constraint.create({
                    bodyA: magnet.current,
                    bodyB: box.current[box.current.length - 1],
                    length: 0.1,
                    pointB: {
                        x: 0,
                        y: (comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current) + 1 === total ? -70 : -60
                    },
                    render: {
                        visible: true,
                        lineWidth: 2,
                        strokeStyle: '#000',
                    }
                });
            }

            World.add(engine.current.world, [boxCurrent as unknown as Constraint, constraint.current]);


            if (render.current && hangPoint.current && box.current.length === 1) {
                Bounds.shift(render.current.bounds, {
                    x: hangPoint.current.position.x - width / 2,
                    y: hangPoint.current.position.y,
                });
            }

            Events.on(engine.current, 'afterUpdate', checkIfResting);

        } catch (error) {
        }
    };

    const startEllipticalMotion = () => {
        const {a, b} = ellipseParams.current;
        let angle = 1;
        const angularSpeed = isRunning ? 0.03 : 0;

        const moveHangPoint = () => {
            if (hangPoint.current && render.current) {
                angle += angularSpeed;
                const centerOfEllipse = {
                    x: (render.current.bounds.min.x + wi / 2) + (a * Math.cos(angle)),
                    y: (render.current.bounds.min.y + LINE_HEIGHT - (LINE_HEIGHT * 1.5) + (b * Math.sin(angle))),
                };
                const x = centerOfEllipse.x + a * Math.cos(angle);
                const y = centerOfEllipse.y + b * Math.sin(angle);
                Body.setPosition(hangPoint.current, {x, y});
                if (magnet.current) {
                    Body.setPosition(magnet.current, {x: hangPoint.current.position.x, y: magnet.current.position.y});
                }

            }
        };

        if (oscillationIntervalID.current) {
            clearInterval(oscillationIntervalID.current);
        }

        oscillationIntervalID.current = setInterval(moveHangPoint, 1000 / 60); // 60 FPS
    };

    const createSwing = async (initialX: number) => {
        try {
            const hangPointTexture = await loadImage("/images/hang-point.png");
            const magnetTexture = await loadImage("/images/tail.png");

            hangPoint.current = Bodies.circle(initialX, LINE_HEIGHT - (LINE_HEIGHT * 1.5), 10, {
                isStatic: true,
                render: {
                    fillStyle: "transparent",
                    sprite: {
                        texture: hangPointTexture,
                        xScale: 0.33,
                        yScale: 0.33,
                    }
                }
            });

            magnet.current = Bodies.rectangle(
                hangPointPosition.current?.x ? hangPointPosition.current.x : initialX,
                hangPointPosition?.current?.y ? hangPointPosition.current.y + LINE_HEIGHT : LINE_HEIGHT - (LINE_HEIGHT * 1.5), 80, 20, {
                    isStatic: false,


                    mass: 0.5, // Reduced mass
                    inertia: Infinity, // Less resistance to rotation
                    density: 0.006, // Increased density
                    frictionAir: 0.05, // Slightly increased air friction

                    render: {
                        fillStyle: "#000",
                        sprite: {
                            texture: magnetTexture,
                            xScale: 0.4,
                            yScale: 0.4
                        }
                    },
                    // density: 0.004,
                    // frictionAir: 0.03,
                });

            magnetConstraint.current = Constraint.create({
                bodyA: hangPoint.current,
                bodyB: magnet.current,
                stiffness: 1,
                type: 'pin',
                length: LINE_HEIGHT,
                render: {
                    visible: true,
                    lineWidth: 2,
                    strokeStyle: '#000',
                }
            });

            World.add(engine.current.world, [
                hangPoint.current as unknown as Constraint,
                magnetConstraint.current,
                magnet.current as unknown as Constraint,
            ]);
        } catch (error) {
            console.log("Error loading image:", error);
        }
    };


    const smoothMoveCamera = (dy: number, duration: number, onComplete: () => void) => {
        console.log("Smooth move camera")
        const frames = 60;
        const dyPerFrame = dy / frames;
        let currentFrame = 0;

        const moveStep = () => {
            if (currentFrame < frames) {
                setBackgroundPositionY((prevPosition) => prevPosition - dyPerFrame);

                setGroundY(prevGroundY => prevGroundY - dyPerFrame); // Update the ground Y position

                Bounds.translate(render.current!.bounds, {x: 0, y: -dyPerFrame});
                currentFrame++;
                requestAnimationFrame(moveStep);
            } else {
                onComplete();
            }
        };

        requestAnimationFrame(moveStep);
    };

    const moveCameraUp = (dy: number) => {
        console.log("Move camera up")
        smoothMoveCamera(dy, 1000, () => {
        });

        const initialX = magnetPosition.current ? magnetPosition.current.x : width / 2;
        const initialY = magnetPosition.current ? magnetPosition.current.y - 20 : 180;
        createBlock(initialX, initialY).then();

    };


    useEffect(() => {
        if (total === bConunt) {
            setGameOver(true);
        }
    }, [bConunt, total]);


    useEffect(() => {
        if (gameOver) {
            handleStopGame(new Event(''));
            buildHandler({
                pos_x: Number(x),
                pos_y: Number(y),
                combo_count: comboCount.current,
                high_quality_count: highQualityCount.current,
                medium_quality_count: mediumQualityCount.current,
                low_quality_count: lowQualityCount.current
            }).then((res) => {
                //@ts-ignore
                setAreas((prevAreas) => {
                    return prevAreas?.map((area) => {
                        if (area.pos_x === Number(x) && area.pos_y === Number(y)) {
                            return res;
                        }
                        return area;
                    });
                })

            }).catch(() => {

            })
        }
    }, [gameOver]);


    const dropBlock = () => {
        if (intervalID.current) {
            clearInterval(intervalID.current);
        }
        if (constraint.current) {
            World.remove(engine.current.world, constraint.current);
            constraint.current = null;
            isDropping.current = true;
        }
        if (box.current.length > 0) {
            const lastBox = box.current[box.current.length - 1];
            Body.setVelocity(lastBox, {x: 0, y: 5});
            Body.setAngularVelocity(lastBox, 0);
            Body.setInertia(lastBox, 10000);
        }
    };

    const isOverlappingEnough = (lastBox: Matter.Body, otherBox: Matter.Body, threshold: number) => {
        const lastBoxBounds = lastBox.bounds;
        const otherBoxBounds = otherBox.bounds;

        const overlapMinX = Math.max(lastBoxBounds.min.x, otherBoxBounds.min.x);
        const overlapMaxX = Math.min(lastBoxBounds.max.x, otherBoxBounds.max.x);

        const overlapWidth = overlapMaxX - overlapMinX;
        const overlapPercentage = overlapWidth / (lastBoxBounds.max.x - lastBoxBounds.min.x);
        console.log("Overlap percentage", overlapPercentage)
        return overlapPercentage >= threshold;
    };


    const isOverlappingEnough2 = (lastBox: Matter.Body, otherBox: Matter.Body) => {
        const lastBoxBounds = lastBox.bounds;
        const otherBoxBounds = otherBox.bounds;

        const overlapMinX = Math.max(lastBoxBounds.min.x, otherBoxBounds.min.x);
        const overlapMaxX = Math.min(lastBoxBounds.max.x, otherBoxBounds.max.x);


        const overlapWidth = overlapMaxX - overlapMinX;
        return overlapWidth / (lastBoxBounds.max.x - lastBoxBounds.min.x);
    };


    const groundConstraint = useRef<Matter.Constraint | null>(null);


    const checkIfResting = () => {
            if (box.current.length > 0) {

                const lastBox = box.current[box.current.length - 1];

                const velocity = lastBox.velocity;
                const speed = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);
                if (box.current.length > 1) {
                    const prevBox = box.current[box.current.length - 2];
                    if (isTouching(lastBox, prevBox)) {
                        if (isOverlappingEnough(lastBox, prevBox, 0.49)) {
                            Body.setAngle(lastBox, 0);
                            Body.setAngularVelocity(lastBox, 0);

                        }
                        if (Math.abs(lastBox.angle) > Math.PI / 6) {
                            Body.setAngle(lastBox, lastBox.angle > 0 ? Math.PI / 6 : -Math.PI / 6);
                        }

                    }

                }


                if (isDropping.current && speed < 0.1 && (box.current.length >= 1)) {
                    Events.off(engine.current, 'afterUpdate', checkIfResting);
                    isDropping.current = false;
                    if (box.current.length > 1) {
                        const lastBox = box.current[box.current.length - 1];
                        const prevBox = box.current[box.current.length - 2];
                        if (isOverlappingEnough(lastBox, prevBox, 0.2)) {

                            const overlapTo = isOverlappingEnough2(lastBox, prevBox)
                            if (overlapTo >= 0.95) {
                                comboCount.current += 1;
                                maxOffset.current -= 2;
                                Body.setPosition(lastBox, {x: prevBox.position.x, y: lastBox.position.y});
                                for (let i = 0; i < 5; i++) { // 5 umbrellas for combos
                                    setTimeout(() => {
                                        const direction = Math.random() > 0.5 ? 'left' : 'right';
                                        createUmbrellaPerson(lastBox.position.x, lastBox.position.y - 100, direction, i);
                                    }, i * 200)
                                }
                            } else if (overlapTo > 0.8) {
                                highQualityCount.current += 1;
                                for (let i = 0; i < 3; i++) { // 3 umbrellas for high quality
                                    setTimeout(() => {
                                        const direction = Math.random() > 0.5 ? 'left' : 'right';
                                        createUmbrellaPerson(lastBox.position.x, lastBox.position.y - 100, direction, i);
                                    }, i * 200)
                                }
                            } else if (overlapTo >= 0.5) {
                                maxOffset.current += 1;

                                mediumQualityCount.current += 1;

                                const direction = Math.random() > 0.5 ? 'left' : 'right';
                                setTimeout(() => {
                                    createUmbrellaPerson(lastBox.position.x, lastBox.position.y - 100, direction, 0);
                                }, 100)

                            } else if (overlapTo > 0.1) {
                                maxOffset.current += 2;
                                lowQualityCount.current += 1;
                            }
                            if (overlapTo > 0.1) {
                                const lastBoxBounds = lastBox.bounds;
                                const prevBoxBounds = prevBox.bounds;
                                const overlapMinX = Math.max(lastBoxBounds.min.x, prevBoxBounds.min.x);
                                const overlapMaxX = Math.min(lastBoxBounds.max.x, prevBoxBounds.max.x);
                                const overlapCenterX = (overlapMinX + overlapMaxX) / 2;
                                const blockHeight = 96;
                                const pointALocalX = overlapCenterX - lastBox.position.x;
                                const pointALocalY = blockHeight / 2;
                                const pointBLocalX = overlapCenterX - prevBox.position.x;
                                const pointBLocalY = -blockHeight / 2;
                                const weldConstraint = Constraint.create({
                                    bodyA: lastBox,
                                    pointA: {x: pointALocalX, y: pointALocalY},
                                    bodyB: prevBox,
                                    pointB: {x: pointBLocalX, y: pointBLocalY},
                                    stiffness: 0.9,
                                    length: 0,
                                    //@ts-ignores
                                    render: {
                                        visible: false,
                                    }
                                });
                                World.add(engine.current.world, weldConstraint);

                            }


                            window.Telegram.WebApp.HapticFeedback.impactOccurred('light')
                        }

                    }


                    if (isTouching(lastBox, ground.current!!) && isFitstBloackOnGround.current) {

                        handleLifeLost(lastBox);
                        isDonotMoveCamera.current = true;
                    }


                    if (isTouching(lastBox, ground.current!!) && !comboCount.current) {
                        comboCount.current += 1;
                        isFitstBloackOnGround.current = true;
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('light')
                        if (!groundConstraint.current && box.current.length === 1) {
                            groundConstraint.current = Constraint.create({
                                bodyA: ground.current,
                                bodyB: lastBox,
                                pointA: {x: 0, y: -10},
                                pointB: {x: 0, y: 48},
                                stiffness: 0.9,
                                length: 0.1,
                                //@ts-ignores
                                render: {
                                    visible: false,
                                }
                            });
                            World.add(engine.current.world, groundConstraint.current);
                        }
                    }


                    box.current.forEach(b => {
                        Body.setVelocity(b, {x: 0, y: 0});
                        Body.setInertia(b, Infinity);
                        Body.setAngle(b, 0);
                        Body.setAngularVelocity(b, 0);
                    });


                    if (!isDonotMoveCamera.current) {
                        if (box.current.length === 3) {
                            moveCameraUp(100);
                        } else {
                            moveCameraUp(100);
                        }
                    } else {
                        moveCameraUp(0);
                        isDonotMoveCamera.current = false;
                    }

                } else if (lastBox.position.y > height && box.current.length > 0) {

                    if (box.current.length === 1) {
                        setLife(prevLife => {
                            const newLife = prevLife - 1;
                            return newLife;
                        });
                    } else {
                        handleLifeLost(lastBox);
                    }
                    moveCameraUp(10);
                    Events.off(engine.current, 'afterUpdate', checkIfResting);
                }

            }
        }
    ;

    const handleLifeLost = (lastBox: Matter.Body) => {

        setLife(prevLife => {
            const newLife = prevLife - 1;
            return newLife;
        });
        World.remove(engine.current.world, lastBox);
        box.current.pop();
    };


    useEffect(() => {
        if (life === 0) {
            handleStopGame(new Event(''));
        }
    }, [life]);

    const isTouching = (lastBox: Matter.Body, prevBox: Matter.Body) => {
        const lastBoxBounds = lastBox.bounds;
        const prevBoxBounds = prevBox.bounds;
        const blockHeight = lastBoxBounds.max.y - lastBoxBounds.min.y;

        const verticalTouch = (prevBox.position.y >= lastBox.position.y - blockHeight) && (prevBox.position.y <= lastBox.position.y + blockHeight);

        const horizontalOverlap =
            lastBoxBounds.max.x > prevBoxBounds.min.x &&
            lastBoxBounds.min.x < prevBoxBounds.max.x;

        return horizontalOverlap && verticalTouch;
    };


    const [sequence, setSequence] = useState(0);

    const airplanes = [
        {id: 1, type: 1, delay: 5, speed: 3, direction: 'right-to-left'},
        {id: 2, type: 2, delay: 8, speed: 3, direction: 'left-to-right'},
        {id: 3, type: 3, delay: 14, speed: 3, direction: 'left-to-right'},
        {id: 4, type: 4, delay: 18, speed: 3, direction: 'right-to-left'},
        {id: 5, type: 5, delay: 24, speed: 3, direction: 'left-to-right'},
        {id: 6, type: 6, delay: 30, speed: 3, direction: 'right-to-left'},
        {id: 7, type: 1, delay: 33, speed: 3, direction: 'right-to-left'},
        {id: 8, type: 2, delay: 38, speed: 3, direction: 'left-to-right'},
        {id: 9, type: 3, delay: 42, speed: 3, direction: 'left-to-right'},
    ];

    const handleAnimationEnd = () => {
        setSequence(prevSequence => prevSequence + 1);
    };


    return (
        <GameContainer backgroundPositionY={backgroundPositionY}>
            {isLoading &&
                <Loader>
                    loading
                </Loader>
            }
            <AirplaneContainer>
                {airplanes.map((airplane, index) => (
                    <Airplane
                        key={`${airplane.id}_${sequence}`}
                        type={airplane.type}
                        delay={airplane.delay}
                        speed={airplane.speed}
                        direction={airplane.direction}
                        top={20}
                        onAnimationEnd={
                            index === airplanes.length - 1 ? handleAnimationEnd : undefined
                        }
                    />
                ))}
            </AirplaneContainer>
            <Relative>
                <div ref={scene}/>
                <Life full={life}/>
                <Scale total={total} current={bConunt}/>
                {comboCount.current + highQualityCount.current + mediumQualityCount.current + lowQualityCount.current > 1 &&
                    <ActionScale ref={actionScaleRef}/>
                }
                <StopButton ref={stopButtonRef} onTouchStart={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isRunning) {
                        handleStopGame(e)
                        setIsRunning(prevState => !prevState);

                    } else {
                        handleStartGame(e)
                    }
                }}>
                    <StopButtonInner>
                        <StopButtonInner2>
                            {"Pause"}
                        </StopButtonInner2>
                    </StopButtonInner>
                </StopButton>
            </Relative>
            <ActionModal
                onClick={() => {
                    navigate('/')
                }}
                text={'Leave'}
                title={'Game Over'}
                open={life === 0}
                onClose={() => {
                    navigate('/')
                }}
            />

            <UWinModal
                onClose={() => {
                    navigate('/')
                }}

                combo={comboCount.current}
                high={highQualityCount.current}
                middle={mediumQualityCount.current}
                low={lowQualityCount.current}
                open={gameOver}/>
            {/*@ts-ignore*/}
            <PauseModal open={!isRunning} onPause={handleStartGame} onLeave={() => {
                navigate('/')
            }}/>
        </GameContainer>
    );
};

export default MatterGame;


const AirplaneContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none; /* Airplanes won't interfere with gameplay interactions */

`;

const Airplane = styled.div.attrs(props => ({
    onAnimationEnd: props.onAnimationEnd,
}))<{ type: number, delay: number, speed: number, direction: string, top: number }>`
    position: absolute;
    top: ${({top}) => top}%;
        // left: ${({direction}) => (direction === 'left-to-right' ? '-150px' : '120vw')};
        // right: ${({direction}) => (direction === 'right-to-left' ? '-150px' : '120vw')};
    left: -150px;
    right: -150px;
    width: 100px;
    height: 50px;
    background-image: url(${({type}) => `/images/airplanes/${type}.png`});
    background-size: 75px;
    background-repeat: no-repeat;
    animation: ${({direction}) => (direction === 'left-to-right' ? 'flyLTR' : 'flyRTL')} linear ${({speed}) => speed}s ${({delay}) => delay}s forwards;

    @keyframes flyLTR {
        from {
            left: -200px;
        }
        to {
            left: calc(100vw + 200px);
        }
    }

    @keyframes flyRTL {
        from {
            left: 100vw;
        }
        to {
            left: calc(-100vw - 200px);
        }
    }

`;


const GameContainer = styled.div<{ backgroundPositionY: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-image: url('/images/main-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center calc(100% - ${({backgroundPositionY}) => backgroundPositionY}px);
`;


const Loader = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    background: black;
    width: 100%;
    height: 101%;
    color: white;
`;

const Relative = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;


const StopButton = styled.div`
    position: absolute;
    //top: 20px;
    right: 10px;
    display: flex;
    width: 108px;
    height: 32px;
    padding: 1px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    box-sizing: border-box;
    top: calc(20px + var(--tg-safe-area-inset-top, 0) + var(--tg-content-safe-area-inset-top, 0));
`;


const StopButtonInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 6px;
    border: 2px solid #B25628;
    box-sizing: border-box;
`

const StopButtonInner2 = styled.div`
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid #1F140F;
    background: #B25628;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 17px */
    text-transform: uppercase;
    padding-bottom: 5px;
`
